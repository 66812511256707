import { saveLocationAndForceLogin } from "../../axios";
import { clearApiCache } from "../clearApiCache";

export function checkForAuthError(obj) {
  if (obj.error) {
    if (
      (obj.error as any).status === 401 ||
      (obj.error as any).originalStatus === 401
    ) {
      clearApiCache();
      saveLocationAndForceLogin();
    }
  }
}
