import { Table, Flex, Box } from "@radix-ui/themes";
import cn from "classnames";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRD } from "srd";

import { THealthOverviewResponse } from "../../../server/src/healthOverview/types";
import {
  healthOverviewStateSelector,
  getHealthOverview,
} from "../app/healthOverviewPageReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as layout from "../app/layoutReducer";
import { Error } from "../components/Error";
import { PageLoading } from "../components/PageLoading/PageLoading";
import PageLoaderPositions from "../components/PageLoading/enums/PageLoaderPositions";

import styles from "./HealthOverviewPage.module.scss";

export const HealthOverviewPage: React.FunctionComponent = (): JSX.Element => {
  const { healthOverview } = useAppSelector(healthOverviewStateSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      layout.setBreadcrumbs([layout.plainBreadcrumb("Cluster Health Overview")])
    );

    dispatch(getHealthOverview());
  }, []);

  return (
    <div className={styles.healthOverviewPage}>
      {SRD.match(
        {
          notAsked: () => <></>,
          loading: () => (
            <Flex direction="row" justify="center" align="center" grow="1">
              <PageLoading
                position={PageLoaderPositions.CENTER}
                size={30}
                label={
                  <label className={styles.loaderLabel}>
                    Loading cluster health ..
                  </label>
                }
              />
            </Flex>
          ),
          failure: () => (
            <Box>
              <Error>Failed to get cluster health.</Error>
            </Box>
          ),
          success: (healthOverviewData: Array<THealthOverviewResponse>) => (
            <Table.Root variant="surface">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>Cluster name</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Not reporting</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Broken IDEs %</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Total IDEs</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Broken IDEs</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Avg no logs</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {healthOverviewData.map((data) => (
                  <Table.Row
                    className={cn(styles.tableRow, {
                      [styles.backgroundWarning]:
                        data.brokenIdesPercentage > 0 &&
                        data.brokenIdesPercentage <= 20,
                      [styles.backgroundDanger]: data.brokenIdesPercentage > 20,
                    })}
                    key={data.clusterName}
                  >
                    <Table.Cell
                      className={cn(styles.tableRow, {
                        [styles.textWarning]:
                          data.brokenIdesPercentage > 0 &&
                          data.brokenIdesPercentage <= 20,
                        [styles.textDanger]: data.brokenIdesPercentage > 20,
                      })}
                    >
                      <Link
                        className={styles.clusterLink}
                        to={`instances?cluster=${data.clusterName}`}
                      >
                        {data.clusterName}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>{data.notReporting}</Table.Cell>

                    <Table.Cell
                      className={cn(styles.tableRow, {
                        [styles.textWarning]:
                          data.brokenIdesPercentage > 0 &&
                          data.brokenIdesPercentage <= 20,
                        [styles.textDanger]: data.brokenIdesPercentage > 20,
                      })}
                    >
                      {data.brokenIdesPercentage}
                    </Table.Cell>

                    <Table.Cell>{data.totalIdes}</Table.Cell>

                    <Table.Cell>{data.brokenIdes}</Table.Cell>

                    <Table.Cell>{data.avgNoLogs}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          ),
        },
        healthOverview
      )}
    </div>
  );
};
