import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Heading, Card, Flex, Text, Strong } from "@radix-ui/themes";
import { SRD } from "srd";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  clusterDetailsStateSelector,
  gotClusterId,
  fetch,
  fetchClusters,
  showEditModal,
  closeModal,
  saveCluster,
  deleteCluster,
  showDeleteModal,
} from "../app/clusterDetailsPageReducer";
import styles from "./ClusterDetailsPage.module.scss";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { Error } from "../components/Error";
import { Copyable } from "../components/Copyable/Copyable";
import { YesNo } from "../components/YesNo";
import CloudProvider from "../components/CloudProvider";
import { TCluster } from "../../../server/src/api/ide/ideApi";
import { setBreadcrumbs } from "../app/layoutReducer";
import Button from "../components/Button/Button";
import { Modal } from "../components/Modal/Modal";
import { AddEditClusterForm } from "../AddEditClusterForm/AddEditClusterForm";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { TCloudProvider } from "../../../server/src/api/ide/ideApi";
import { DESC } from "../consts";
import { instanceColsSelector } from "../app/userPreferencesReducer";
import { toggleInstanceColumn } from "../app/userPreferencesReducer";
import { InstanceSearch } from "../components/InstanceSearch/InstanceSearch";

export function ClusterDetailsPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(clusterDetailsStateSelector);
  const instanceCols = useAppSelector(instanceColsSelector);

  const params = useParams();

  const clusterId = params.clusterId || "";

  useEffect(() => {
    dispatch(gotClusterId(clusterId));
    dispatch(fetch());
  }, []);

  useEffect(() => {
    const fqdn = SRD.unwrap(
      "",
      (cluster) => {
        return cluster.fqdn;
      },
      state.cluster
    );

    dispatch(
      setBreadcrumbs([
        { type: "plain", text: "Cluster" },
        { type: "plain", text: fqdn },
      ])
    );
  }, [state.cluster]);

  function clusterInfo(cluster: TCluster) {
    const Item = (
      props: React.PropsWithChildren<{
        label: string;
        copyText?: string | null;
      }>
    ) => {
      let copyIcon = <></>;

      const copyText = props.copyText || "";

      if (copyText) {
        copyIcon = <Copyable copyText={copyText} />;
      }

      return (
        <div className={styles.item}>
          <div className={styles.label}>{props.label}</div>
          <div className={styles.itemValue}>
            {props.children} {copyIcon}
          </div>
        </div>
      );
    };

    return (
      <div data-testid="cluster-info">
        <div className={styles.top}>
          <Flex justify="end" gap="2">
            <Button
              onClick={() => {
                dispatch(fetchClusters());
                dispatch(showEditModal());
              }}
              size="1"
            >
              Edit cluster
            </Button>
            <Button
              onClick={() => {
                dispatch(showDeleteModal());
              }}
              size="1"
              color="red"
            >
              Delete cluster
            </Button>
          </Flex>
        </div>
        <Card className={styles.card}>
          <div className={styles.titleRow}>
            <Heading size="4">Cluster Info</Heading>
          </div>
          <div className={styles.items}>
            <Item label="Region">{cluster.region}</Item>
            <Item label="Region code">{cluster.region_code}</Item>
            <Item label="FQDN" copyText={cluster.fqdn}>
              {cluster.fqdn}
            </Item>
            <Item label="Cloud flare DNS enabled">
              <YesNo value={cluster.enable_cloudflare_dns || false} />
            </Item>
            <Item label="Cloud provider">
              <CloudProvider value={cluster.cloud_provider as TCloudProvider} />
            </Item>
          </div>
        </Card>
      </div>
    );
  }

  const cluster = SRD.withDefault(null, state.cluster);

  return (
    <div className={styles.clusterDetailsPage}>
      {SRD.match(
        {
          notAsked: () => <div></div>,
          loading: () => <PageLoading />,
          failure: (msg) => (
            <div className={styles.error}>
              <Error>{msg}</Error>
            </div>
          ),

          success: (data) => (
            <>
              {clusterInfo(data)}
              <InstanceSearch
                showRegions={false}
                activeColumns={instanceCols}
                toggleColumn={(col) => dispatch(toggleInstanceColumn(col))}
                fixedFilters={{
                  cluster_id: {
                    op: "eq",
                    value: state.clusterId as number,
                  },
                }}
                defaultStatus={["completed", "in_progress"]}
                defaultSort={{
                  created_at: DESC,
                }}
              />
            </>
          ),
        },
        state.cluster
      )}

      {state.modal.modalType === "editCluster" && cluster && (
        <Modal open={true} close={() => dispatch(closeModal())}>
          {SRD.match(
            {
              notAsked: () => <div></div>,
              loading: () => <PageLoading />,
              failure: (msg) => (
                <div className={styles.error}>
                  <Error>{msg}</Error>
                </div>
              ),

              success: (clustersOverview) => (
                <AddEditClusterForm
                  clustersList={clustersOverview.data || []}
                  submitText="Save cluster"
                  heading="Edit cluster"
                  request={state.editClusterRequest}
                  onClose={() => dispatch(closeModal())}
                  onSubmit={(values) => {
                    if (cluster.id) {
                      dispatch(
                        saveCluster({
                          id: cluster.id,
                          cluster: values,
                        })
                      );
                    }
                  }}
                  initialValues={{
                    region: cluster.region,
                    region_code: cluster.region_code,
                    name: cluster.name,
                    cloud_provider: cluster.cloud_provider as any,
                    enable_cloudflare_dns: cluster.enable_cloudflare_dns,
                  }}
                />
              ),
            },
            state.clustersOverview
          )}
        </Modal>
      )}
      {state.modal.modalType === "deleteCluster" && cluster && (
        <ConfirmModal
          onCancel={() => dispatch(closeModal())}
          onSubmit={() => dispatch(deleteCluster({ id: cluster.id as number }))}
          heading="Delete cluster"
          confirmButtonRed
          safeConfirmText="delete"
          message={
            <Text>
              Are you sure you want to delete <Strong>{cluster?.fqdn}</Strong>?
            </Text>
          }
        />
      )}
    </div>
  );
}
