// CODEGEN DO NOT EDIT

import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const CountryCodeBase = z
  .object({
    id: z.number().int(),
    country_code: z.union([z.string(), z.null()]),
    region: z.union([z.string(), z.null()]),
    country: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PaginationResponse = z.object({
  page: z.number().int().gte(1).optional().default(1),
  page_size: z.number().int().gte(1).lte(500).optional().default(50),
  total_results: z.number().int(),
});
const GetCountryCodesResponse = z
  .object({
    data: z.union([z.array(CountryCodeBase), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const ValidationErrorModel = z
  .object({ detail: z.array(z.string()) })
  .passthrough();
const ClusterRead = z
  .object({
    region: z.string(),
    region_code: z.string(),
    cloud_provider: z.string(),
    name: z.string().optional().default("ide"),
    enable_cloudflare_dns: z.boolean().optional(),
    id: z.number().int(),
  })
  .passthrough();
const ApiUserRead = z
  .object({
    name: z.string(),
    default_cloud_provider: z
      .union([z.string(), z.null()])
      .optional()
      .default("aws"),
    enable_vpn: z.union([z.boolean(), z.null()]).optional(),
    afk_worker: z.union([z.boolean(), z.null()]).optional(),
    enable_slack_alerts: z.union([z.boolean(), z.null()]).optional(),
    enable_failed_creation_alerts: z.union([z.boolean(), z.null()]).optional(),
    use_liveness_face_auth_api_user_level: z
      .union([z.boolean(), z.null()])
      .optional(),
    allow_delete_endpoint: z.union([z.boolean(), z.null()]).optional(),
    enable_tesseract: z.union([z.boolean(), z.null()]).optional(),
    id: z.number().int(),
  })
  .passthrough();
const CapacityPartnerRead = z
  .object({
    email: z.string(),
    email_alias: z.union([z.string(), z.null()]),
    country: z.union([z.string(), z.null()]).optional(),
    afk_worker: z.union([z.boolean(), z.null()]).optional(),
    face_auth: z.union([z.boolean(), z.null()]).optional().default(true),
    id: z.number().int(),
  })
  .passthrough();
const ExternalPipelineProvider = z.enum(["gitlab", "tesseract"]);
const PipelineStatus = z.enum([
  "scheduled",
  "running",
  "paused",
  "completed",
  "failed",
  "canceled",
]);
const PipelineRead = z
  .object({
    name: z.string(),
    pipeline_type: z.union([z.string(), z.null()]).optional(),
    external_pipeline_id: z.string(),
    external_pipeline_provider: ExternalPipelineProvider,
    status: PipelineStatus.optional().default("scheduled"),
    id: z.number().int(),
    started_at: z.union([z.string(), z.null()]),
    finished_at: z.union([z.string(), z.null()]),
    execution_time: z.union([z.number(), z.null()]),
    url: z.string(),
  })
  .passthrough();
const MobileEmulatorAppRead = z
  .object({
    platform: z.number().int(),
    file_path: z.union([z.string(), z.null()]).optional(),
    timeout: z.union([z.number(), z.null()]).optional(),
    id: z.number().int(),
  })
  .passthrough();
const InstanceRead = z
  .object({
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    instance_name: z.string().optional(),
    provision_status: z.string().optional().default("uninitialized"),
    project_id: z.string(),
    access_token: z.string().optional(),
    platform: z.string(),
    region: z.string(),
    archive_id: z.union([z.string(), z.null()]).optional(),
    completed_manual_triggers: z.string().optional(),
    cloud_provider: z.string(),
    delete_reason: z.union([z.string(), z.null()]).optional(),
    spin_up_time: z.union([z.number(), z.null()]).optional(),
    vpn_port: z.union([z.number(), z.null()]),
    terminated: z.boolean().optional(),
    webcam: z.union([z.boolean(), z.null()]).optional(),
    skip_emulators: z.union([z.boolean(), z.null()]),
    is_deallocated: z.boolean().optional(),
    fail_creation_alert_sent: z.union([z.boolean(), z.null()]),
    id: z.number().int(),
    cluster: z.union([ClusterRead, z.null()]),
    api_user: z.union([ApiUserRead, z.null()]),
    capacity_partner: z.union([CapacityPartnerRead, z.null()]),
    pipeline: z.union([PipelineRead, z.null()]),
    mobile_emulator_app_android: z.union([MobileEmulatorAppRead, z.null()]),
    mobile_emulator_app_ios: z.union([MobileEmulatorAppRead, z.null()]),
    ide_direct_url: z.string(),
    ide_url: z.string(),
  })
  .passthrough();
const InstanceListResponse = z
  .object({
    data: z.union([z.array(InstanceRead), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const PaginationRequest = z
  .object({
    page: z.number().int().gte(1).default(1),
    page_size: z.number().int().gte(1).lte(500).default(50),
  })
  .partial();
const StringFilterComparision = z
  .object({
    op: z.enum(["eq", "ne", "contains", "not_contains"]),
    value: z.string(),
  })
  .passthrough();
const StringFilterContains = z
  .object({ op: z.enum(["in", "not_in"]), value: z.array(z.string()) })
  .passthrough();
const NumberFilterComparision = z
  .object({
    op: z.enum(["eq", "ne", "lt", "gt", "le", "ge"]),
    value: z.union([z.number(), z.number()]),
  })
  .passthrough();
const NumberFilterInclusion = z
  .object({
    op: z.enum(["in", "not_in"]),
    value: z.array(z.union([z.number(), z.number()])),
  })
  .passthrough();
const NumberFilterInclusionRange = z
  .object({
    op: z.enum(["between", "not_between"]),
    value: z
      .array(z.union([z.number(), z.number()]))
      .min(2)
      .max(2),
  })
  .passthrough();
const DateTimeFilterComparision = z
  .object({
    op: z.enum(["eq", "ne", "lt", "gt", "le", "ge"]),
    value: z.string().datetime({ offset: true }),
  })
  .passthrough();
const DateTimeFilterInclusion = z
  .object({
    op: z.enum(["between", "not_between"]),
    value: z.array(z.string()).min(2).max(2),
  })
  .passthrough();
const BoolFilter = z
  .object({ op: z.enum(["eq", "ne"]), value: z.boolean() })
  .passthrough();
const InstanceFilterFields = z
  .object({
    instance_name: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    project_id: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    platform: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    cluster_id: z.union([
      z.discriminatedUnion("op", [
        NumberFilterComparision,
        NumberFilterInclusion,
        NumberFilterInclusionRange,
      ]),
      z.null(),
    ]),
    region: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    cloud_provider: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    created_at: z.union([
      z.discriminatedUnion("op", [
        DateTimeFilterComparision,
        DateTimeFilterInclusion,
      ]),
      z.null(),
    ]),
    provision_status: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    is_deallocated: z.union([BoolFilter, z.null()]),
    terminated: z.union([BoolFilter, z.null()]),
    capacity_partner__email: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    api_user__name: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
  })
  .partial();
const InstanceFilterSort = z
  .object({
    created_at: z.union([z.enum(["asc", "desc"]), z.null()]),
    instance_name: z.union([z.enum(["asc", "desc"]), z.null()]),
    provision_status: z.union([z.enum(["asc", "desc"]), z.null()]),
    platform: z.union([z.enum(["asc", "desc"]), z.null()]),
    region: z.union([z.enum(["asc", "desc"]), z.null()]),
    capacity_partner__email: z.union([z.enum(["asc", "desc"]), z.null()]),
    api_user__name: z.union([z.enum(["asc", "desc"]), z.null()]),
    cluster__name: z.union([z.enum(["asc", "desc"]), z.null()]),
  })
  .partial();
const InstanceFilterRequest = z.object({
  pagination: PaginationRequest,
  search: z.union([z.string(), z.null()]).optional(),
  filters: z.union([InstanceFilterFields, z.null()]).optional(),
  sort: z
    .union([InstanceFilterSort, z.null()])
    .optional()
    .default({ created_at: "desc" }),
});
const CapacityPartnerDetail = z
  .object({
    email: z.string(),
    email_alias: z.union([z.string(), z.null()]),
    country: z.union([z.string(), z.null()]).optional(),
    afk_worker: z.union([z.boolean(), z.null()]).optional(),
    face_auth: z.union([z.boolean(), z.null()]).optional().default(true),
    id: z.number().int(),
    rsa_public: z.string(),
    rsa_private: z.string(),
  })
  .passthrough();
const MobileEmulatorAppDetail = z
  .object({
    platform: z.number().int(),
    file_path: z.union([z.string(), z.null()]).optional(),
    timeout: z.union([z.number(), z.null()]).optional(),
    id: z.number().int(),
    public_key: z.string(),
  })
  .passthrough();
const InstanceDetail = z
  .object({
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    instance_name: z.string().optional(),
    provision_status: z.string().optional().default("uninitialized"),
    project_id: z.string(),
    access_token: z.string().optional(),
    platform: z.string(),
    region: z.string(),
    archive_id: z.union([z.string(), z.null()]).optional(),
    completed_manual_triggers: z.string().optional(),
    cloud_provider: z.string(),
    delete_reason: z.union([z.string(), z.null()]).optional(),
    spin_up_time: z.union([z.number(), z.null()]).optional(),
    vpn_port: z.union([z.number(), z.null()]),
    terminated: z.boolean().optional(),
    webcam: z.union([z.boolean(), z.null()]).optional(),
    skip_emulators: z.union([z.boolean(), z.null()]),
    is_deallocated: z.boolean().optional(),
    fail_creation_alert_sent: z.union([z.boolean(), z.null()]),
    id: z.number().int(),
    cluster: z.union([ClusterRead, z.null()]),
    api_user: z.union([ApiUserRead, z.null()]),
    capacity_partner: CapacityPartnerDetail,
    pipeline: z.union([PipelineRead, z.null()]),
    mobile_emulator_app_android: z.union([MobileEmulatorAppDetail, z.null()]),
    mobile_emulator_app_ios: z.union([MobileEmulatorAppDetail, z.null()]),
    ingress_fqdn: z.string(),
    encrypted_ide_password: z.string(),
    ide_direct_url: z.string(),
    ide_url: z.string(),
  })
  .passthrough();
const NotFoundErrorModel = z.object({ detail: z.string() }).passthrough();
const TesseractPipelineUpdateRequest = z
  .object({ pipeline_id: z.union([z.string(), z.number()]) })
  .passthrough();
const ClusterListModel = z
  .object({
    region: z.string(),
    region_code: z.string(),
    cloud_provider: z.string(),
    name: z.string().optional().default("ide"),
    enable_cloudflare_dns: z.boolean().optional(),
    id: z.number().int(),
    instance_count: z.number().int(),
    fqdn: z.string(),
  })
  .passthrough();
const ClusterListResponse = z
  .object({
    data: z.union([z.array(ClusterListModel), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const CloudProvider = z.enum(["az", "aws"]);
const ClusterCreate = z
  .object({
    region: z.string(),
    region_code: z.string(),
    cloud_provider: CloudProvider,
    name: z.string().optional().default("ide"),
    enable_cloudflare_dns: z.boolean().optional(),
  })
  .passthrough();
const Cluster = z
  .object({
    region: z.string(),
    region_code: z.string(),
    cloud_provider: z.string(),
    name: z.string().optional().default("ide"),
    enable_cloudflare_dns: z.boolean().optional(),
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    id: z.union([z.number(), z.null()]).optional(),
    fqdn: z.string(),
  })
  .passthrough();
const ClusterEdit = z
  .object({
    region: z.union([z.string(), z.null()]),
    region_code: z.union([z.string(), z.null()]),
    cloud_provider: z.union([CloudProvider, z.null()]),
    name: z.union([z.string(), z.null()]),
    enable_cloudflare_dns: z.union([z.boolean(), z.null()]),
  })
  .partial()
  .passthrough();
const Pipeline = z
  .object({
    name: z.string(),
    pipeline_type: z.union([z.string(), z.null()]).optional(),
    external_pipeline_id: z.string(),
    external_pipeline_provider: ExternalPipelineProvider,
    status: PipelineStatus.optional().default("scheduled"),
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    id: z.union([z.number(), z.null()]).optional(),
    started_at: z.union([z.string(), z.null()]).optional(),
    finished_at: z.union([z.string(), z.null()]).optional(),
    execution_time: z.union([z.number(), z.null()]),
    url: z.string(),
    instance_id: z.union([z.number(), z.null()]),
    instance_name: z.union([z.string(), z.null()]),
  })
  .passthrough();
const PipelineCreate = z
  .object({
    name: z.string(),
    pipeline_type: z.union([z.string(), z.null()]).optional(),
    external_pipeline_id: z.string(),
    external_pipeline_provider: ExternalPipelineProvider,
    status: PipelineStatus.optional().default("scheduled"),
    instance_name: z.string(),
  })
  .passthrough();
const PipelineFilterFields = z
  .object({
    name: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    pipeline_type: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    external_pipeline_id: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    external_pipeline_provider: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    status: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    created_at: z.union([
      z.discriminatedUnion("op", [
        DateTimeFilterComparision,
        DateTimeFilterInclusion,
      ]),
      z.null(),
    ]),
    started_at: z.union([
      z.discriminatedUnion("op", [
        DateTimeFilterComparision,
        DateTimeFilterInclusion,
      ]),
      z.null(),
    ]),
    finished_at: z.union([
      z.discriminatedUnion("op", [
        DateTimeFilterComparision,
        DateTimeFilterInclusion,
      ]),
      z.null(),
    ]),
    instance__instance_name: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
  })
  .partial();
const PipelineFilterSort = z
  .object({
    created_at: z.union([z.enum(["asc", "desc"]), z.null()]),
    started_at: z.union([z.enum(["asc", "desc"]), z.null()]),
    finished_at: z.union([z.enum(["asc", "desc"]), z.null()]),
    name: z.union([z.enum(["asc", "desc"]), z.null()]),
    status: z.union([z.enum(["asc", "desc"]), z.null()]),
  })
  .partial();
const PipelineFilterRequest = z.object({
  pagination: PaginationRequest,
  filters: z.union([PipelineFilterFields, z.null()]).optional(),
  sort: z
    .union([PipelineFilterSort, z.null()])
    .optional()
    .default({ created_at: "desc" }),
});
const PipelineListResponse = z
  .object({
    data: z.union([z.array(Pipeline), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const InstanceBase = z
  .object({
    instance_name: z.string().optional(),
    provision_status: z.string().optional().default("uninitialized"),
    project_id: z.string(),
    access_token: z.string().optional(),
    platform: z.string(),
    region: z.string(),
    archive_id: z.union([z.string(), z.null()]).optional(),
    completed_manual_triggers: z.string().optional(),
    cloud_provider: z.string(),
    delete_reason: z.union([z.string(), z.null()]).optional(),
    spin_up_time: z.union([z.number(), z.null()]).optional(),
    vpn_port: z.union([z.number(), z.null()]),
    terminated: z.boolean().optional(),
    webcam: z.union([z.boolean(), z.null()]).optional(),
    skip_emulators: z.union([z.boolean(), z.null()]),
    is_deallocated: z.boolean().optional(),
    fail_creation_alert_sent: z.union([z.boolean(), z.null()]),
  })
  .passthrough();
const GitlabPipelineResponseModel = z
  .object({
    external_id: z.number().int(),
    status: z.string(),
    total_jobs: z.union([z.number(), z.null()]).optional(),
    percentage: z.union([z.number(), z.null()]).optional(),
    execution_time: z.union([z.number(), z.null()]).optional(),
    pipeline_action: z.union([z.string(), z.null()]).optional(),
    id: z.number().int(),
    instance: z.union([InstanceBase, z.null()]),
    url: z.string(),
  })
  .passthrough();
const GetGitlabPipelinesResponse = z
  .object({
    data: z.union([z.array(GitlabPipelineResponseModel), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const GitlabPipelineFilterFields = z
  .object({
    instance_id: z.union([
      z.discriminatedUnion("op", [
        NumberFilterComparision,
        NumberFilterInclusion,
        NumberFilterInclusionRange,
      ]),
      z.null(),
    ]),
    external_id: z.union([
      z.discriminatedUnion("op", [
        NumberFilterComparision,
        NumberFilterInclusion,
        NumberFilterInclusionRange,
      ]),
      z.null(),
    ]),
    status: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    created_at: z.union([
      z.discriminatedUnion("op", [
        DateTimeFilterComparision,
        DateTimeFilterInclusion,
      ]),
      z.null(),
    ]),
    execution_time: z.union([
      z.discriminatedUnion("op", [
        NumberFilterComparision,
        NumberFilterInclusion,
        NumberFilterInclusionRange,
      ]),
      z.null(),
    ]),
    instance__instance_name: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    pipeline_action: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
  })
  .partial();
const GitlabPipelineFilterSort = z
  .object({
    created_at: z.union([z.enum(["asc", "desc"]), z.null()]),
    external_id: z.union([z.enum(["asc", "desc"]), z.null()]),
    pipeline_action: z.union([z.enum(["asc", "desc"]), z.null()]),
    execution_time: z.union([z.enum(["asc", "desc"]), z.null()]),
    status: z.union([z.enum(["asc", "desc"]), z.null()]),
  })
  .partial();
const GitlabPipelineFilterRequest = z.object({
  pagination: PaginationRequest,
  filters: z.union([GitlabPipelineFilterFields, z.null()]).optional(),
  sort: z
    .union([GitlabPipelineFilterSort, z.null()])
    .optional()
    .default({ created_at: "desc" }),
});
const GitlabPipeline = z
  .object({
    instance_id: z.number().int(),
    external_id: z.number().int(),
    status: z.string(),
    total_jobs: z.union([z.number(), z.null()]).optional(),
    percentage: z.union([z.number(), z.null()]).optional(),
    execution_time: z.union([z.number(), z.null()]).optional(),
    pipeline_action: z.union([z.string(), z.null()]).optional(),
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    id: z.union([z.number(), z.null()]).optional(),
    url: z.string(),
  })
  .passthrough();
const AdminUserRead = z
  .object({
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    email: z.string(),
    id: z.number().int(),
  })
  .passthrough();
const AdminUserListResponse = z
  .object({
    data: z.union([z.array(AdminUserRead), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const AdminUserCreate = z
  .object({ email: z.string().email(), password: z.string().min(6).max(18) })
  .passthrough();
const AdminUser = z
  .object({
    reset_password_token: z.union([z.string(), z.null()]).optional(),
    reset_password_sent_at: z.union([z.string(), z.null()]).optional(),
    remember_created_at: z.union([z.string(), z.null()]).optional(),
    confirmation_token: z.union([z.string(), z.null()]).optional(),
    confirmed_at: z.union([z.string(), z.null()]).optional(),
    confirmation_sent_at: z.union([z.string(), z.null()]).optional(),
    deleted_at: z.union([z.string(), z.null()]).optional(),
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    email: z.string(),
    id: z.union([z.number(), z.null()]).optional(),
  })
  .passthrough();
const AdminUserFilterFields = z
  .object({
    email: z.union([
      z.discriminatedUnion("op", [
        StringFilterComparision,
        StringFilterContains,
      ]),
      z.null(),
    ]),
    created_at: z.union([
      z.discriminatedUnion("op", [
        DateTimeFilterComparision,
        DateTimeFilterInclusion,
      ]),
      z.null(),
    ]),
  })
  .partial();
const AdminUserFilterSort = z
  .object({
    created_at: z.union([z.enum(["asc", "desc"]), z.null()]),
    email: z.union([z.enum(["asc", "desc"]), z.null()]),
  })
  .partial();
const AdminUserFilterRequest = z.object({
  pagination: PaginationRequest,
  filters: z.union([AdminUserFilterFields, z.null()]).optional(),
  sort: z
    .union([AdminUserFilterSort, z.null()])
    .optional()
    .default({ created_at: "desc" }),
});
const GetApiUserResponse = z
  .object({
    data: z.union([z.array(ApiUserRead), z.null()]),
    pagination: PaginationResponse,
  })
  .passthrough();
const ApiUserUpdate = z
  .object({
    name: z.union([z.string(), z.null()]),
    default_cloud_provider: z.union([z.string(), z.null()]).default("aws"),
    enable_vpn: z.union([z.boolean(), z.null()]),
    afk_worker: z.union([z.boolean(), z.null()]),
    enable_slack_alerts: z.union([z.boolean(), z.null()]),
    enable_failed_creation_alerts: z.union([z.boolean(), z.null()]),
    use_liveness_face_auth_api_user_level: z.union([z.boolean(), z.null()]),
    allow_delete_endpoint: z.union([z.boolean(), z.null()]),
    enable_tesseract: z.union([z.boolean(), z.null()]),
    clusters: z.union([z.array(z.string()), z.null()]),
    token: z.union([z.string(), z.null()]),
    time_from_afk: z.union([z.number(), z.null()]).default(7200),
  })
  .partial()
  .passthrough();
const ApiUser = z
  .object({
    name: z.string(),
    default_cloud_provider: z
      .union([z.string(), z.null()])
      .optional()
      .default("aws"),
    enable_vpn: z.union([z.boolean(), z.null()]).optional(),
    afk_worker: z.union([z.boolean(), z.null()]).optional(),
    enable_slack_alerts: z.union([z.boolean(), z.null()]).optional(),
    enable_failed_creation_alerts: z.union([z.boolean(), z.null()]).optional(),
    use_liveness_face_auth_api_user_level: z
      .union([z.boolean(), z.null()])
      .optional(),
    allow_delete_endpoint: z.union([z.boolean(), z.null()]).optional(),
    enable_tesseract: z.union([z.boolean(), z.null()]).optional(),
    clusters: z.array(z.string()),
    token: z.string(),
    time_from_afk: z.union([z.number(), z.null()]).optional().default(7200),
    created_at: z.string().datetime({ offset: true }).optional(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    id: z.union([z.number(), z.null()]).optional(),
  })
  .passthrough();
const InstanceDeploymentCreateRequest = z
  .object({
    email: z.string(),
    platform: z.string(),
    region: z.union([z.string(), z.null()]).optional(),
    project_id: z.union([z.string(), z.number()]),
    skip_emulators: z.union([z.boolean(), z.null()]).optional(),
    skip_emulator: z.union([z.boolean(), z.null()]).optional(),
    skip_callback: z.union([z.boolean(), z.null()]).optional(),
    country_code: z.union([z.string(), z.null()]).optional(),
    android_app_location: z.union([z.string(), z.null()]).optional(),
    ios_app_location: z.union([z.string(), z.null()]).optional(),
    cloud_provider: z.union([z.string(), z.null()]).optional(),
    webcam: z.union([z.boolean(), z.null()]).optional(),
  })
  .passthrough();
const DeploymentCreateRequest = z
  .object({ ide: InstanceDeploymentCreateRequest })
  .passthrough();
const DeploymentDeallocateRequet = z
  .object({
    delete_reason: z.string(),
    deallocate_expert: z
      .union([z.boolean(), z.null()])
      .optional()
      .default(true),
  })
  .passthrough();
const GitlabJobParams = z
  .object({
    id: z.number().int(),
    name: z.string(),
    stage: z.string(),
    pipeline: z.number().int(),
  })
  .passthrough();
const GitlabPipelineParams = z
  .object({
    id: z.number().int(),
    status: z.string(),
    action: z.string(),
    notify_user: z.string(),
    skip_callback: z.string(),
  })
  .passthrough();
const DeploymentUpdateRequest = z
  .object({
    job: z.union([GitlabJobParams, z.null()]),
    pipeline: z.union([GitlabPipelineParams, z.null()]),
  })
  .partial()
  .passthrough();
const DeploymentEmulatorCreateRequet = z
  .object({
    android_app_location: z.union([z.string(), z.null()]),
    ios_app_location: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough();
const DeploymentEmulatorUpdateRequest = z
  .object({
    project_id: z.string(),
    email: z.string(),
    platform: z.string(),
    app_location: z.string(),
  })
  .passthrough();
const Body_instance_face_auth_v1_instances__instance_name__face_auth_post = z
  .object({ target_image: z.instanceof(File) })
  .passthrough();
const WidgetEventCreateRequest = z
  .object({
    widget_id: z.number().int(),
    rating: z.number().int(),
    question: z.string(),
    answer: z.union([z.string(), z.null()]),
    event_name: z.string(),
  })
  .passthrough();
const TrackerEventCreateRequest = z
  .object({
    event_name: z.string(),
    tracker_story_id: z.union([z.string(), z.number()]),
    afk: z.union([z.boolean(), z.null()]).optional(),
    timestamp: z.string(),
  })
  .passthrough();

export const schemas = {
  CountryCodeBase,
  PaginationResponse,
  GetCountryCodesResponse,
  ValidationErrorModel,
  ClusterRead,
  ApiUserRead,
  CapacityPartnerRead,
  ExternalPipelineProvider,
  PipelineStatus,
  PipelineRead,
  MobileEmulatorAppRead,
  InstanceRead,
  InstanceListResponse,
  PaginationRequest,
  StringFilterComparision,
  StringFilterContains,
  NumberFilterComparision,
  NumberFilterInclusion,
  NumberFilterInclusionRange,
  DateTimeFilterComparision,
  DateTimeFilterInclusion,
  BoolFilter,
  InstanceFilterFields,
  InstanceFilterSort,
  InstanceFilterRequest,
  CapacityPartnerDetail,
  MobileEmulatorAppDetail,
  InstanceDetail,
  NotFoundErrorModel,
  TesseractPipelineUpdateRequest,
  ClusterListModel,
  ClusterListResponse,
  CloudProvider,
  ClusterCreate,
  Cluster,
  ClusterEdit,
  Pipeline,
  PipelineCreate,
  PipelineFilterFields,
  PipelineFilterSort,
  PipelineFilterRequest,
  PipelineListResponse,
  InstanceBase,
  GitlabPipelineResponseModel,
  GetGitlabPipelinesResponse,
  GitlabPipelineFilterFields,
  GitlabPipelineFilterSort,
  GitlabPipelineFilterRequest,
  GitlabPipeline,
  AdminUserRead,
  AdminUserListResponse,
  AdminUserCreate,
  AdminUser,
  AdminUserFilterFields,
  AdminUserFilterSort,
  AdminUserFilterRequest,
  GetApiUserResponse,
  ApiUserUpdate,
  ApiUser,
  InstanceDeploymentCreateRequest,
  DeploymentCreateRequest,
  DeploymentDeallocateRequet,
  GitlabJobParams,
  GitlabPipelineParams,
  DeploymentUpdateRequest,
  DeploymentEmulatorCreateRequet,
  DeploymentEmulatorUpdateRequest,
  Body_instance_face_auth_v1_instances__instance_name__face_auth_post,
  WidgetEventCreateRequest,
  TrackerEventCreateRequest,
};

export type TCountryCodeBase = z.infer<typeof CountryCodeBase>;
export type TPaginationResponse = z.infer<typeof PaginationResponse>;
export type TGetCountryCodesResponse = z.infer<typeof GetCountryCodesResponse>;
export type TValidationErrorModel = z.infer<typeof ValidationErrorModel>;
export type TClusterRead = z.infer<typeof ClusterRead>;
export type TApiUserRead = z.infer<typeof ApiUserRead>;
export type TCapacityPartnerRead = z.infer<typeof CapacityPartnerRead>;
export type TExternalPipelineProvider = z.infer<
  typeof ExternalPipelineProvider
>;
export type TPipelineStatus = z.infer<typeof PipelineStatus>;
export type TPipelineRead = z.infer<typeof PipelineRead>;
export type TMobileEmulatorAppRead = z.infer<typeof MobileEmulatorAppRead>;
export type TInstanceRead = z.infer<typeof InstanceRead>;
export type TInstanceListResponse = z.infer<typeof InstanceListResponse>;
export type TPaginationRequest = z.infer<typeof PaginationRequest>;
export type TStringFilterComparision = z.infer<typeof StringFilterComparision>;
export type TStringFilterContains = z.infer<typeof StringFilterContains>;
export type TNumberFilterComparision = z.infer<typeof NumberFilterComparision>;
export type TNumberFilterInclusion = z.infer<typeof NumberFilterInclusion>;
export type TNumberFilterInclusionRange = z.infer<
  typeof NumberFilterInclusionRange
>;
export type TDateTimeFilterComparision = z.infer<
  typeof DateTimeFilterComparision
>;
export type TDateTimeFilterInclusion = z.infer<typeof DateTimeFilterInclusion>;
export type TBoolFilter = z.infer<typeof BoolFilter>;
export type TInstanceFilterFields = z.infer<typeof InstanceFilterFields>;
export type TInstanceFilterSort = z.infer<typeof InstanceFilterSort>;
export type TInstanceFilterRequest = z.infer<typeof InstanceFilterRequest>;
export type TCapacityPartnerDetail = z.infer<typeof CapacityPartnerDetail>;
export type TMobileEmulatorAppDetail = z.infer<typeof MobileEmulatorAppDetail>;
export type TInstanceDetail = z.infer<typeof InstanceDetail>;
export type TNotFoundErrorModel = z.infer<typeof NotFoundErrorModel>;
export type TTesseractPipelineUpdateRequest = z.infer<
  typeof TesseractPipelineUpdateRequest
>;
export type TClusterListModel = z.infer<typeof ClusterListModel>;
export type TClusterListResponse = z.infer<typeof ClusterListResponse>;
export type TCloudProvider = z.infer<typeof CloudProvider>;
export type TClusterCreate = z.infer<typeof ClusterCreate>;
export type TCluster = z.infer<typeof Cluster>;
export type TClusterEdit = z.infer<typeof ClusterEdit>;
export type TPipeline = z.infer<typeof Pipeline>;
export type TPipelineCreate = z.infer<typeof PipelineCreate>;
export type TPipelineFilterFields = z.infer<typeof PipelineFilterFields>;
export type TPipelineFilterSort = z.infer<typeof PipelineFilterSort>;
export type TPipelineFilterRequest = z.infer<typeof PipelineFilterRequest>;
export type TPipelineListResponse = z.infer<typeof PipelineListResponse>;
export type TInstanceBase = z.infer<typeof InstanceBase>;
export type TGitlabPipelineResponseModel = z.infer<
  typeof GitlabPipelineResponseModel
>;
export type TGetGitlabPipelinesResponse = z.infer<
  typeof GetGitlabPipelinesResponse
>;
export type TGitlabPipelineFilterFields = z.infer<
  typeof GitlabPipelineFilterFields
>;
export type TGitlabPipelineFilterSort = z.infer<
  typeof GitlabPipelineFilterSort
>;
export type TGitlabPipelineFilterRequest = z.infer<
  typeof GitlabPipelineFilterRequest
>;
export type TGitlabPipeline = z.infer<typeof GitlabPipeline>;
export type TAdminUserRead = z.infer<typeof AdminUserRead>;
export type TAdminUserListResponse = z.infer<typeof AdminUserListResponse>;
export type TAdminUserCreate = z.infer<typeof AdminUserCreate>;
export type TAdminUser = z.infer<typeof AdminUser>;
export type TAdminUserFilterFields = z.infer<typeof AdminUserFilterFields>;
export type TAdminUserFilterSort = z.infer<typeof AdminUserFilterSort>;
export type TAdminUserFilterRequest = z.infer<typeof AdminUserFilterRequest>;
export type TGetApiUserResponse = z.infer<typeof GetApiUserResponse>;
export type TApiUserUpdate = z.infer<typeof ApiUserUpdate>;
export type TApiUser = z.infer<typeof ApiUser>;
export type TInstanceDeploymentCreateRequest = z.infer<
  typeof InstanceDeploymentCreateRequest
>;
export type TDeploymentCreateRequest = z.infer<typeof DeploymentCreateRequest>;
export type TDeploymentDeallocateRequet = z.infer<
  typeof DeploymentDeallocateRequet
>;
export type TGitlabJobParams = z.infer<typeof GitlabJobParams>;
export type TGitlabPipelineParams = z.infer<typeof GitlabPipelineParams>;
export type TDeploymentUpdateRequest = z.infer<typeof DeploymentUpdateRequest>;
export type TDeploymentEmulatorCreateRequet = z.infer<
  typeof DeploymentEmulatorCreateRequet
>;
export type TDeploymentEmulatorUpdateRequest = z.infer<
  typeof DeploymentEmulatorUpdateRequest
>;
export type TBody_instance_face_auth_v1_instances__instance_name__face_auth_post =
  z.infer<
    typeof Body_instance_face_auth_v1_instances__instance_name__face_auth_post
  >;
export type TWidgetEventCreateRequest = z.infer<
  typeof WidgetEventCreateRequest
>;
export type TTrackerEventCreateRequest = z.infer<
  typeof TrackerEventCreateRequest
>;

const endpoints = makeApi([
  {
    method: "get",
    path: "/",
    alias: "root__get",
    requestFormat: "json",
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/admin_users",
    alias: "list_admin_users_v1_admin_users_get",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(1).lte(500).optional().default(50),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z
          .enum(["created_at", "email"])
          .optional()
          .default("created_at"),
      },
      {
        name: "order",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("desc"),
      },
    ],
    response: AdminUserListResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/admin_users",
    alias: "create_admin_user_v1_admin_users_post",
    description: `- The email should be valid, and allowed domains are:
  - &#x60;builder.ai&#x60;
  - &#x60;x.builder.ai&#x60;
  - &#x60;builderai.msdc.co&#x60;
- The password length must be between 6 and 18 characters, inclusive.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AdminUserCreate,
      },
    ],
    response: AdminUser,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/admin_users/:admin_user_id",
    alias: "get_admin_user_by_id_v1_admin_users__admin_user_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "admin_user_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: AdminUser,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/admin_users/:admin_user_id",
    alias: "edit_admin_user_v1_admin_users__admin_user_id__put",
    description: `- The email should be valid, and allowed domains are:
  - &#x60;builder.ai&#x60;
  - &#x60;x.builder.ai&#x60;
  - &#x60;builderai.msdc.co&#x60;
- The password length must be between 6 and 18 characters, inclusive.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AdminUserCreate,
      },
      {
        name: "admin_user_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: AdminUser,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/admin_users/:admin_user_id",
    alias: "delete_admin_user_v1_admin_users__admin_user_id__delete",
    requestFormat: "json",
    parameters: [
      {
        name: "admin_user_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/admin_users/search",
    alias: "search_admin_users_endpoint_v1_admin_users_search_post",
    description: `Return list of admin user records based on filters and search`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AdminUserFilterRequest,
      },
    ],
    response: AdminUserListResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/admin_users/session",
    alias: "create_admin_users_session_v1_admin_users_session_post",
    description: `Find or Create Admin User Session and return the JWT token`,
    requestFormat: "json",
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/admin_users/session/validate",
    alias: "validate_admin_users_session_v1_admin_users_session_validate_post",
    requestFormat: "json",
    parameters: [
      {
        name: "authorization",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/api_users",
    alias: "get_api_users_v1_api_users_get",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(1).lte(500).optional().default(50),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z.enum(["created_at", "name"]).optional().default("created_at"),
      },
      {
        name: "order",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("desc"),
      },
    ],
    response: GetApiUserResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/api_users/:api_user_id",
    alias: "edit_api_user_v1_api_users__api_user_id__put",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ApiUserUpdate,
      },
      {
        name: "api_user_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: ApiUser,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/clusters",
    alias: "list_clusters_v1_clusters_get",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(1).lte(500).optional().default(50),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z
          .enum([
            "created_at",
            "name",
            "region",
            "region_code",
            "cloud_provider",
          ])
          .optional()
          .default("created_at"),
      },
      {
        name: "order",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("desc"),
      },
    ],
    response: ClusterListResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/clusters",
    alias: "create_cluster_v1_clusters_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClusterCreate,
      },
    ],
    response: Cluster,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/clusters/:cluster_id",
    alias: "get_cluster_info_v1_clusters__cluster_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "cluster_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: Cluster,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/clusters/:cluster_id",
    alias: "edit_cluster_v1_clusters__cluster_id__put",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClusterEdit,
      },
      {
        name: "cluster_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: Cluster,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/clusters/:cluster_id",
    alias: "delete_cluster_v1_clusters__cluster_id__delete",
    requestFormat: "json",
    parameters: [
      {
        name: "cluster_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/country_codes",
    alias: "get_country_codes_v1_country_codes_get",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(1).lte(500).optional().default(50),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z
          .enum(["created_at", "country_code", "region"])
          .optional()
          .default("created_at"),
      },
      {
        name: "order",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("desc"),
      },
    ],
    response: GetCountryCodesResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/gitlab_pipelines",
    alias: "get_gitlab_pipelines_v1_gitlab_pipelines_get",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(1).lte(500).optional().default(50),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z
          .enum([
            "created_at",
            "external_id",
            "pipeline_action",
            "execution_time",
            "status",
          ])
          .optional()
          .default("created_at"),
      },
      {
        name: "order",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("desc"),
      },
    ],
    response: GetGitlabPipelinesResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/gitlab_pipelines/:id",
    alias: "get_gitlab_pipeline_by_id_v1_gitlab_pipelines__id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: GitlabPipeline,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/gitlab_pipelines/search",
    alias: "search_gitlab_pipelines_endpoint_v1_gitlab_pipelines_search_post",
    description: `Return list of pipeline records based on filters and search`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: GitlabPipelineFilterRequest,
      },
    ],
    response: GetGitlabPipelinesResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/instances",
    alias: "list_instances_v1_instances_get",
    description: `Return list of instance records.`,
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(1).lte(500).optional().default(50),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z
          .enum([
            "created_at",
            "instance_name",
            "provision_status",
            "platform",
            "region",
            "capacity_partner__email",
            "api_user__name",
            "cluster__name",
          ])
          .optional()
          .default("created_at"),
      },
      {
        name: "order",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("desc"),
      },
    ],
    response: InstanceListResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/instances/:instance_name",
    alias: "get_instance_by_name_v1_instances__instance_name__get",
    description: `Return instance record.`,
    requestFormat: "json",
    parameters: [
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: InstanceDetail,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "patch",
    path: "/v1/instances/:instance_name",
    alias: "update_ide_pipeline_complete_v1_instances__instance_name__patch",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: TesseractPipelineUpdateRequest,
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/instances/:instance_name/deallocate",
    alias: "deallocate_ide_v1_instances__instance_name__deallocate_put",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeploymentDeallocateRequet,
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/instances/:instance_name/details",
    alias: "get_ide_details_v1_instances__instance_name__details_get",
    requestFormat: "json",
    parameters: [
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/instances/:instance_name/emulator",
    alias: "deploy_emulator_apps_v1_instances__instance_name__emulator_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeploymentEmulatorCreateRequet,
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/instances/:instance_name/emulator",
    alias:
      "get_default_emulator_apps_v1_instances__instance_name__emulator_get",
    requestFormat: "json",
    parameters: [
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/instances/:instance_name/face_auth",
    alias: "instance_face_auth_v1_instances__instance_name__face_auth_post",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ target_image: z.instanceof(File) }).passthrough(),
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/instances/:instance_name/guacamole_connection",
    alias:
      "reset_ide_guacamole_connection_v1_instances__instance_name__guacamole_connection_put",
    requestFormat: "json",
    parameters: [
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/instances/:instance_name/guacamole_connection",
    alias:
      "delete_ide_guacamole_connection_v1_instances__instance_name__guacamole_connection_delete",
    requestFormat: "json",
    parameters: [
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/instances/:instance_name/tracker_event",
    alias:
      "create_tracker_events_v1_instances__instance_name__tracker_event_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: TrackerEventCreateRequest,
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "patch",
    path: "/v1/instances/:instance_name/update",
    alias: "update_ide_v1_instances__instance_name__update_patch",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeploymentUpdateRequest,
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/instances/:instance_name/widget",
    alias: "show_widget_v1_instances__instance_name__widget_get",
    requestFormat: "json",
    parameters: [
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/instances/:instance_name/widget",
    alias: "create_widget_events_v1_instances__instance_name__widget_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: WidgetEventCreateRequest,
      },
      {
        name: "instance_name",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/instances/deploy",
    alias: "deploy_ide_v1_instances_deploy_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeploymentCreateRequest,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/instances/details/:access_token",
    alias: "get_ide_details_with_token_v1_instances_details__access_token__get",
    requestFormat: "json",
    parameters: [
      {
        name: "access_token",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/instances/emulator",
    alias: "update_emulator_apps_v1_instances_emulator_put",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeploymentEmulatorUpdateRequest,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/instances/search",
    alias: "search_instances_v1_instances_search_post",
    description: `- Return list of instance records based on filters and search.
- &#x60;search&#x60; parameter perform free text search on &#x60;instance name&#x60; and &#x60;capacity partner email&#x60;.
- All filter conditions is grouped as AND condition including free text search filter.
- If both free text search and filter conditions are provided then both combined under AND condition.
- All filters are optional and should be provided only if you want to filter by the given values.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: InstanceFilterRequest,
      },
    ],
    response: InstanceListResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/pipelines/",
    alias: "create_pipeline_v1_pipelines__post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PipelineCreate,
      },
    ],
    response: Pipeline,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/pipelines/:external_pipeline_provider/:external_pipeline_id",
    alias:
      "get_pipeline_by_external_id_v1_pipelines__external_pipeline_provider___external_pipeline_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "external_pipeline_provider",
        type: "Path",
        schema: z.enum(["gitlab", "tesseract"]),
      },
      {
        name: "external_pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: Pipeline,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/pipelines/:id",
    alias: "get_pipeline_by_id_v1_pipelines__id__get",
    description: `Return pipeline record by given id.`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: Pipeline,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "put",
    path: "/v1/pipelines/:id/status/:status",
    alias: "update_pipeline_status_v1_pipelines__id__status__status__put",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "status",
        type: "Path",
        schema: z.enum([
          "scheduled",
          "running",
          "paused",
          "completed",
          "failed",
          "canceled",
        ]),
      },
    ],
    response: Pipeline,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
  {
    method: "post",
    path: "/v1/pipelines/search",
    alias: "search_pipelines_v1_pipelines_search_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PipelineFilterRequest,
      },
    ],
    response: PipelineListResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorModel,
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
