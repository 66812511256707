import React from "react";
import { Card, Flex } from "@radix-ui/themes";

import { TActivityApiInstance } from "../../../../../server/src/health/types";
import cpuIcon from "../../../../assets/cpu-light.svg";
import diskIcon from "../../../../assets/disk-light.svg";
import macAddressIcon from "../../../../assets/mac-address-light.svg";
import memoryIcon from "../../../../assets/memory-light.svg";
import {
  healthStateSelector,
  THealthState,
} from "../../../app/healthPageReducer";
import { useAppSelector } from "../../../app/hooks";
import { Highlighted } from "../Highlighted/Highlighted";
import { MemoryUsage } from "../MemoryUsage/MemoryUsage";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";

export const IdeDetails: React.FunctionComponent = ():
  | JSX.Element
  | null
  | false => {
  const healthState = useAppSelector<THealthState>(healthStateSelector);
  const { selectedTimestamp, activityApiData } = healthState;

  let instance: TActivityApiInstance | null = null;
  let ubuntuVersion: string | null = null;

  if (activityApiData.length && selectedTimestamp) {
    instance = activityApiData.find(
      (item) => new Date(item.timestamp).toISOString() === selectedTimestamp
    )!.instance;

    ubuntuVersion = activityApiData.find(
      (item) => new Date(item.timestamp).toISOString() === selectedTimestamp
    )!.ubuntu.version;
  }

  const getIdeDetailsView = (): JSX.Element => {
    const {
      cpu,
      disk,
      total_memory,
      used_memory,
      available_memory,
      mac_address,
      polluted_ide,
    } = instance!;

    return (
      <Flex direction={"row"} wrap={"wrap"} justify={"between"} gap={"6"}>
        <Highlighted
          icon={cpuIcon}
          iconMessage="CPU Icon"
          label="CPU"
          value={`${cpu} GHz`}
        />

        <Highlighted
          icon={diskIcon}
          iconMessage="Disk Icon"
          label="Disk"
          value={`${disk}%`}
        />

        <Highlighted
          icon={memoryIcon}
          iconMessage="Memory Icon"
          label="Memory"
          value={`${Math.floor(+total_memory / Math.pow(1024, 3))} GB`}
        >
          <MemoryUsage
            memoryUsed={+used_memory}
            memoryAvailable={+available_memory}
          />
        </Highlighted>

        <Highlighted
          icon={macAddressIcon}
          iconMessage="MAC Address Icon"
          label="MAC Address"
          value={mac_address}
        />

        <Highlighted
          label="Polluted"
          value={
            polluted_ide ? (
              <CheckIcon color="rgb(0, 196, 159)" width={24} height={24} />
            ) : (
              <Cross2Icon color="salmon" width={24} height={24} />
            )
          }
        />

        <Highlighted label="Ubuntu" value={ubuntuVersion!} />
      </Flex>
    );
  };

  return (
    instance &&
    !!ubuntuVersion && (
      <Card variant="surface" mb="4">
        {getIdeDetailsView()}
      </Card>
    )
  );
};
