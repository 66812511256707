import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
} from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, SRD, success } from "srd";
import { z } from "zod";

import { TRootState } from "./store";
import {
  TCapacityPartnerSearchQuery,
  ZCapacityPartner,
  TCapacityPartnerSearchResults,
  TCapacityPartner,
  TCapacityPartnerSetFaceAuth,
  TCapacityPartnerSetAfkWorker,
} from "../../../server/src/capacityPartners/types";
import axios from "../axios";
import { TPaginationStatus, paginationStatus } from "./pagination";
import { delayPromise, updateRowById } from "./utils";

type TState = {
  searchQuery: TCapacityPartnerSearchQuery;
  pageNumber: number;
  pageSize: number;
  capacityPartners: RD<string, TCapacityPartnerSearchResults>;
  afkWorker: { [id: string]: RD<string, null> };
  faceAuth: { [id: string]: RD<string, null> };
};

const initialState: TState = {
  searchQuery: { email: "", offset: 0, limit: 50 },
  pageNumber: 1,
  pageSize: 50,
  capacityPartners: notAsked(),
  afkWorker: {},
  faceAuth: {},
};

export const gotEmail = createAction<string>("capacityPartners/gotEmail");
export const clear = createAction("capacityPartners/clear");

export const search = createAsyncThunk(
  "capacityPartners/search",
  async (args, { getState }) => {
    const state = (getState() as any).capacityPartnersPageReducer as TState;

    const response = await axios.post("/api/capacity-partners/search", {
      ...state.searchQuery,
      offset: (state.pageNumber - 1) * state.pageSize,
    });

    return response.data;
  }
);

export function paginateToPage(pageNumber: number) {
  return (dispatch) => {
    dispatch(gotPageNumber(pageNumber));
    dispatch(search());
  };
}

export const gotPageNumber = createAction<number>(
  "capacityPartners/gotPageNumber"
);

export const setFaceAuth = createAsyncThunk(
  "capacityPartners/setFaceAuth",
  async ({ id, value }: { id: string; value: boolean }) => {
    const response = await axios.post("/api/capacity-partners/set-face-auth", {
      id,
      face_auth: value,
    } as TCapacityPartnerSetFaceAuth);

    // add minimum delay for smoother UI
    const delayedResponse = await delayPromise(response, 500);

    return delayedResponse.data;
  }
);

export const setAfkWorker = createAsyncThunk(
  "capacityPartners/setAfkWorker",
  async ({ id, value }: { id: string; value: boolean }) => {
    const response = await axios.post("/api/capacity-partners/set-afk-worker", {
      id,
      afk_worker: value,
    } as TCapacityPartnerSetAfkWorker);

    // add minimum delay for smoother UI
    const delayedResponse = await delayPromise(response, 500);

    return delayedResponse.data;
  }
);

export const capacityPartnersPageReducer = createReducer(
  initialState,
  (builder) => {
    return builder
      .addCase(gotEmail, (state, action) => {
        state.searchQuery.email = action.payload;
      })
      .addCase(clear, () => {
        return initialState;
      })
      .addCase(search.pending, (state) => {
        state.capacityPartners = loading();
      })
      .addCase(search.rejected, (state) => {
        // TODO log error to sentry
        state.capacityPartners = failure("Something went wrong");
      })
      .addCase(search.fulfilled, (state, action) => {
        const result = z
          .array(ZCapacityPartner)
          .safeParse(action.payload.data.rows);

        if (result.success) {
          state.capacityPartners = success(action.payload.data);
        } else {
          state.capacityPartners = failure("Invalid results");
        }
      })
      .addCase(gotPageNumber, (state, action) => {
        state.pageNumber = action.payload;
      })
      .addCase(setFaceAuth.pending, (state, action) => {
        const id = action.meta.arg.id;

        state.faceAuth[id] = loading();
      })
      .addCase(setFaceAuth.rejected, (state, action) => {
        const id = action.meta.arg.id;
        // TODO log error to sentry
        state.faceAuth[id] = failure("Something went wrong");
      })
      .addCase(setFaceAuth.fulfilled, (state, action) => {
        const { id, value } = action.meta.arg;

        state.faceAuth[id] = success(null);

        state.capacityPartners = updateRowById<TCapacityPartner>(
          state.capacityPartners,
          id,
          (cp) => {
            return {
              ...cp,
              face_auth: value,
            };
          }
        );
      })
      .addCase(setAfkWorker.pending, (state, action) => {
        const id = action.meta.arg.id;

        state.afkWorker[id] = loading();
      })
      .addCase(setAfkWorker.rejected, (state, action) => {
        const id = action.meta.arg.id;
        // TODO log error to sentry
        state.afkWorker[id] = failure("Something went wrong");
      })
      .addCase(setAfkWorker.fulfilled, (state, action) => {
        const { id, value } = action.meta.arg;

        state.afkWorker[id] = success(null);

        state.capacityPartners = updateRowById<TCapacityPartner>(
          state.capacityPartners,
          id,
          (cp) => {
            return {
              ...cp,
              afk_worker: value,
            };
          }
        );
      });
  }
);

export const stateSelector = (state: TRootState): TState =>
  state.capacityPartnersPageReducer;

export const paginationStatusSelector = createSelector(
  stateSelector,

  (state: TState): TPaginationStatus | null => {
    return SRD.unwrap(
      null,
      (results) => {
        return paginationStatus(state, results.total);
      },
      state.capacityPartners
    );
  }
);
