import React, { useState } from "react";
import { Heading, Select, Flex, Text, Checkbox } from "@radix-ui/themes";
import { format, subDays } from "date-fns";
import * as _ from "lodash";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import {
  GitlabPipelineStatus,
  ReportType,
  reportsApi,
} from "../../app/api/reports/reportsService";
import { DateInput } from "../../components/DateInput";
import { Modal } from "../../components/Modal/Modal";
import { TextFieldLabel } from "../../components/TextFieldLabel/TextFieldLabel";
import { IDE_BYPASS, PIPELINE_STATISTICS_REPORT } from "../consts";
import { InstancesInput } from "../../components/InstancesInput";
import Button from "../../components/Button/Button";
import { Error } from "../../components/Error";

type Props = {
  onClose: () => void;
  reportTypes: string[];
};

const sevenDaysAgo = subDays(new Date(), 7);

export const CreateReportModal = (props: Props) => {
  const [reportType, setReportType] = useState(PIPELINE_STATISTICS_REPORT);
  const [startDate, setStartDate] = useState(
    format(sevenDaysAgo, "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [params, setParams] = useState({} as any);
  const [rawJSONString, setRawJSONString] = useState("{}");
  const [forceJSONEditor, setForceJSONEditor] = useState(false);

  const [createReport, { isLoading, isError, error }] =
    reportsApi.useCreateReportV1ReportsPostMutation();

  const clearAll = () => {
    setParams({});
    setRawJSONString("{}");
  };

  const handleForceJSONEditor = (force) => {
    // clear json string and any params when toggling on/off the json editor
    // this is much simplier than trying to sync state across UI/editor
    clearAll();
    setForceJSONEditor(force);
  };

  const handleSetReportType = (reportType) => {
    setReportType(reportType);
    clearAll();
  };

  const handleCreateReport = async () => {
    const reportData = {
      type: reportType as ReportType,
      start_date: startDate,
      end_date: endDate,
      ...params,
    };

    // this blank authorization value is needed due to quirk in the openapi
    // spec auto generated by reports-api-service
    // it can be blank because the auth token is set as a header server side
    await createReport({ report: reportData, authorization: "" }).unwrap();

    props.onClose();
  };

  const errors = (error as any)?.data?.detail || [];

  const jsonValid = isValidJSON(rawJSONString);

  let paramsEditor;
  let canSubmit = true;

  if (reportType === PIPELINE_STATISTICS_REPORT) {
    const pipelineStatus = params.pipeline_status || "";

    const pipelineStatusOptions: GitlabPipelineStatus[] = [
      "running",
      "created",
      "fail",
      "in_progress",
      "pending",
      "success",
    ];

    paramsEditor = (
      <div data-testid="select-pipeline-status">
        <TextFieldLabel>Pipeline status</TextFieldLabel>
        <Select.Root
          value={pipelineStatus}
          onValueChange={(value) => {
            setParams((params) => {
              if (value === "blank") {
                return _.omit(params, "pipeline_status");
              }

              return {
                ...params,
                pipeline_status: value,
              };
            });
          }}
        >
          <Select.Trigger style={{ width: "170px" }} />
          <Select.Content>
            <Select.Item key="blank" value="blank"></Select.Item>
            {pipelineStatusOptions.map((pipelineStatusOption, idx) => (
              <Select.Item key={idx} value={pipelineStatusOption}>
                {pipelineStatusOption}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </div>
    );
  }

  if (reportType === IDE_BYPASS) {
    const instanceNames = params.instance_names || [];

    const instanceCount =
      instanceNames.length > 0 ? `(${instanceNames.length})` : "";

    paramsEditor = (
      <div data-testid="select-instances">
        <TextFieldLabel>Instances {instanceCount}</TextFieldLabel>
        <InstancesInput
          onInstanceInput={(instances) =>
            setParams({ instance_names: instances })
          }
          style={{ height: "240px" }}
        />
      </div>
    );
  }

  if (!paramsEditor || forceJSONEditor) {
    const extraStyle = jsonValid ? {} : { outline: "1px solid yellow" };

    canSubmit = jsonValid;

    paramsEditor = (
      <div data-testid="json-editor">
        <AceEditor
          mode="json"
          theme="monokai"
          onChange={(str) => {
            setRawJSONString(str);

            if (isValidJSON(str)) {
              setParams(JSON.parse(str));
            }
          }}
          name="json-editor"
          editorProps={{ $blockScrolling: true }}
          style={{ height: "200px", width: "100%", ...extraStyle }}
          value={rawJSONString}
        />
      </div>
    );
  }

  return (
    <Modal close={props.onClose} open={true}>
      <form>
        <Flex direction="column" gap="5">
          <Heading>Create report</Heading>
          <Flex gap="8">
            <div>
              <TextFieldLabel>Report type</TextFieldLabel>
              <Select.Root
                value={reportType}
                onValueChange={handleSetReportType}
              >
                <Select.Trigger
                  style={{ width: "170px" }}
                  data-testid="report-type"
                />
                <Select.Content>
                  <Select.Group>
                    {props.reportTypes.map((reportType) => (
                      <Select.Item key={reportType} value={reportType}>
                        {reportType}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </div>
            <div>
              <TextFieldLabel>Start date</TextFieldLabel>
              <DateInput
                value={startDate}
                onChange={(e) => setStartDate(e.currentTarget.value)}
                data-testid="start-date"
              />
            </div>
            <div>
              <TextFieldLabel>End date</TextFieldLabel>
              <DateInput
                value={endDate}
                onChange={(e) => setEndDate(e.currentTarget.value)}
                data-testid="end-date"
              />
            </div>
          </Flex>
          <Text as="label" size="2">
            <Flex gap="2">
              <Checkbox
                checked={forceJSONEditor}
                onClick={() => handleForceJSONEditor(!forceJSONEditor)}
                data-testid="use-json-editor"
              />
              Use json editor
            </Flex>
          </Text>
          <Flex direction="column" gap="2">
            {paramsEditor}
          </Flex>
          {isError && <Error>{errors.join(", ")}</Error>}
          <Flex gap="2">
            <Button
              type="submit"
              data-testid="submit"
              onClick={handleCreateReport}
              pending={isLoading}
              disabled={!canSubmit}
            >
              Create report
            </Button>

            <Button variant="outline" onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

function isValidJSON(str) {
  try {
    // Try parsing the string with JSON.parse.
    JSON.parse(str);
    // If it succeeds, return true indicating the string is valid JSON.
    return true;
  } catch (e) {
    // If an error is thrown, return false indicating the string is not valid JSON.
    return false;
  }
}
