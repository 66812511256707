import React from "react";
import { Flex, Separator, Text } from "@radix-ui/themes";

import healthPageStyles from "../../HealthPage.module.scss";

import styles from "./highlighted.module.scss";

type THighlightedProps = {
  icon?: string;
  iconMessage?: string;
  label: string;
  value: string | JSX.Element;
  children?: JSX.Element | Array<JSX.Element>;
};

export const Highlighted: React.FunctionComponent<THighlightedProps> = ({
  icon,
  iconMessage,
  label,
  value,
  children,
}: THighlightedProps): JSX.Element => {
  return (
    <Flex
      direction="row"
      wrap="wrap"
      align="center"
      gap="2"
      className={styles.highlighted}
      px="4"
      py="2"
    >
      {!!icon && (
        <img
          src={icon}
          alt={iconMessage || icon}
          className={healthPageStyles.icon}
        />
      )}
      <Text as="label">{label}: </Text>
      <Text as="span">{value}</Text>

      {children && (
        <>
          <Separator orientation="vertical" mx="2" />
          {children}
        </>
      )}
    </Flex>
  );
};
