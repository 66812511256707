import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import axios from "../axios";
import emailRegex from "email-regex";
import { produce } from "immer";
import { failure, loading, notAsked, RD, SRD, success } from "srd";

import {
  TAdminError,
  TAdminUser,
  TDeleteAdminUser,
  TInsertAdminUser,
  TUpdateAdminUser,
} from "../../../server/src/adminUsers/types";
import { TypedResponse } from "../../../server/src/types";

import { TRootState } from "./store";

type FormField<T> = { value: T; error: string };

type FieldValues = {
  email: string;
  password: string;
};

type Fields = {
  [key in keyof FieldValues]: FormField<FieldValues[key]>;
};

type TModal = {
  mode:
    | { modeName: "DontShow" }
    | { modeName: "Delete"; id: string }
    | { modeName: "Edit"; id: string }
    | { modeName: "Add" };

  fields: Fields;
  generatePassword: boolean;
  showPassword: boolean;
  request: RD<string, string>;
};

type TState = {
  adminUsers: RD<string, TAdminUser[]>;
  modal: TModal;
};

const initialModalState: TModal = {
  mode: { modeName: "DontShow" },
  fields: {
    email: { value: "", error: "" },
    password: { value: "", error: "" },
  },
  generatePassword: false,
  showPassword: false,
  request: notAsked(),
};

const initialState: TState = {
  adminUsers: notAsked(),
  modal: initialModalState,
};

export const gotEmail = createAction<string>("adminUsersPage/gotEmail");
export const gotPassword = createAction<string>("adminUsersPage/gotPassword");
export const toggleShowPassword = createAction(
  "adminUsersPage/toggleShowPassword"
);
export const setFields = createAction<Fields>("adminUsersPage/setFields");
export const closeModal = createAction("adminUsersPage/closeModal");
export const openAddModal = createAction("adminUsersPage/openAddModel");
export const openEditModal = createAction<TAdminUser>(
  "adminUsersPage/openEditModal"
);
export const openDeleteModal = createAction<TAdminUser>(
  "adminUsersPage/openDeleteModal"
);

export function toggleGenPassword() {
  return (dispatch, getState) => {
    const state = (getState() as TRootState).adminUsersPageReducer;

    const generatePassword = !state.modal.generatePassword;

    let password = "";

    if (generatePassword) {
      password = generateRandomPassword(12);
    }

    dispatch(setGenPassword({ generatePassword, password }));
  };
}

export function refreshGenPassword() {
  return (dispatch, getState) => {
    const state = (getState() as TRootState).adminUsersPageReducer;

    const password = generateRandomPassword(12);

    dispatch(
      setGenPassword({
        generatePassword: state.modal.generatePassword,
        password,
      })
    );
  };
}

export const setGenPassword = createAction<{
  generatePassword: boolean;
  password: string;
}>("adminUsersPage/setGenPassword");

export const fetchUsers = createAsyncThunk(
  "adminUsersPage/fetchUsers",
  async () => {
    const response = await axios.get("/api/admin-users/");

    return response.data;
  }
);

export function saveClicked() {
  return (dispatch, getState) => {
    const state = (getState() as TRootState).adminUsersPageReducer;

    if (state.modal.mode.modeName === "Add") {
      const fields = validateAddFields(state.modal.fields);

      if (fields.email.error || fields.password.error) {
        return dispatch(setFields(fields));
      }

      dispatch(
        create({
          email: state.modal.fields.email.value,
          password: state.modal.fields.password.value,
        })
      );
    }

    if (state.modal.mode.modeName === "Edit") {
      const fields = validateEditFields(state.modal.fields);
      if (fields.email.error) {
        return dispatch(setFields(fields));
      }

      dispatch(
        save({
          id: state.modal.mode.id,
          email: state.modal.fields.email.value,
          password: state.modal.fields.password.value,
        })
      );
    }
  };
}

function validateAddFields(fields: Fields): Fields {
  return produce(fields, (draft) => {
    draft.email.error = getEmailError(fields.email.value);
    draft.password.error = getPasswordError(fields.password.value);
  });
}

function validateEditFields(fields: Fields): Fields {
  return produce(fields, (draft) => {
    draft.email.error = getEmailError(fields.email.value);
  });
}

const getEmailError = (email) => {
  if (!emailRegex({ exact: true }).test(email)) {
    return "Invalid email";
  }

  return "";
};

const getPasswordError = (password) => {
  if (!password) {
    return "Password cannot be blank";
  }

  if (password.length < 8) {
    return "Password min length 8 characters";
  }

  // remove all letters and numbers, anything left must be a special/whitespace character
  const passwordSpecialChars = password.replace(/[a-zA-Z0-9]/g, "");

  if (passwordSpecialChars.length === 0) {
    return "Password needs at least one special character";
  }

  return "";
};

const save = createAsyncThunk(
  "adminUsersPage/save",
  async (data: TUpdateAdminUser) => {
    const response = await axios.post("/api/admin-users/update/", data);

    return response.data;
  }
);

const create = createAsyncThunk(
  "adminUsersPage/create",
  async (data: TInsertAdminUser) => {
    const response = await axios.post("/api/admin-users/insert", data);

    return response.data;
  }
);

export const deleteUser = createAsyncThunk(
  "adminUsersPage/deleteUser",
  async (data: string) => {
    const response = await axios.post("/api/admin-users/delete", { id: data });

    return response.data;
  }
);

export const adminUsersPageReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(fetchUsers.pending, (state) => {
      state.adminUsers = loading();
    })
    .addCase(fetchUsers.rejected, (state) => {
      state.adminUsers = failure("Something went wrong");
    })
    .addCase(fetchUsers.fulfilled, (state, action) => {
      state.adminUsers = success(action.payload.adminUsers);
    })
    .addCase(setGenPassword, (state, action) => {
      state.modal.generatePassword = action.payload.generatePassword;
      state.modal.fields.password.value = action.payload.password;
    })
    .addCase(toggleShowPassword, (state) => {
      state.modal.showPassword = !state.modal.showPassword;
    })
    .addCase(gotEmail, (state, action) => {
      state.modal.fields.email.value = action.payload;
      state.modal.fields.email.error = "";
    })
    .addCase(gotPassword, (state, action) => {
      state.modal.fields.password.value = action.payload;
      state.modal.fields.password.error = "";
    })
    .addCase(setFields, (state, action) => {
      state.modal.fields = action.payload;
    })
    .addCase(openAddModal, (state) => {
      state.modal.mode.modeName = "Add";
    })
    .addCase(openEditModal, (state, action) => {
      state.modal.mode = { modeName: "Edit", id: action.payload.id };
      state.modal.fields.email.value = action.payload.email;
    })
    .addCase(openDeleteModal, (state, action) => {
      state.modal.mode = { modeName: "Delete", id: action.payload.id };
      state.modal.fields.email.value = action.payload.email;
    })
    .addCase(closeModal, (state) => {
      state.modal = initialModalState;
    })
    .addCase(save.pending, (state) => {
      state.modal.request = loading();
    })
    .addCase(save.rejected, (state) => {
      state.modal.request = failure("Something went wrong");
    })
    .addCase(save.fulfilled, (state, action) => {
      const res = action.payload as TypedResponse<TAdminUser, TAdminError>;

      if (res.status === "Success") {
        state.adminUsers = SRD.map((adminUsers) => {
          return adminUsers.map((user) => {
            if (user.id === res.data.id) {
              return res.data;
            }

            return user;
          });
        }, state.adminUsers);

        state.modal.request = success("Admin user updated");
        state.modal = initialModalState;
      } else if (res.status === "Failure") {
        if (res.error === "Email already in use") {
          state.modal.fields.email.error = res.error;
        }

        state.modal.request = notAsked();
      }
    })
    .addCase(create.pending, (state) => {
      state.modal.request = loading();
    })
    .addCase(create.rejected, (state) => {
      state.modal.request = failure("Something went wrong");
    })
    .addCase(create.fulfilled, (state, action) => {
      const res = action.payload as TypedResponse<TAdminUser, TAdminError>;

      if (res.status === "Success") {
        state.adminUsers = SRD.map((adminUsers) => {
          return [res.data, ...adminUsers];
        }, state.adminUsers);

        state.modal.request = success("Admin user created");
        state.modal = initialModalState;
      } else if (res.status === "Failure") {
        if (res.error === "Email already in use") {
          state.modal.fields.email.error = res.error;
        }

        state.modal.request = notAsked();
      }
    })
    .addCase(deleteUser.pending, (state) => {
      state.modal.request = loading();
    })
    .addCase(deleteUser.rejected, (state) => {
      state.modal.request = failure("Something went wrong");
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      const res = action.payload as TypedResponse<TDeleteAdminUser, "">;

      if (res.status === "Success") {
        state.adminUsers = SRD.map((adminUsers) => {
          return adminUsers.filter((adminUser) => adminUser.id !== res.data.id);
        }, state.adminUsers);
        state.modal.request = success("Admin user deleted");
        state.modal = initialModalState;
      } else if (res.status === "Failure") {
        state.modal.request = failure(res.error);
      }
    });
});

export const adminUsersPageSelector = (state: TRootState): TState =>
  state.adminUsersPageReducer;

function generateRandomPassword(length) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
}
