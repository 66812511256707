import React from "react";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Flex, IconButton, Popover, Text } from "@radix-ui/themes";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

import memoryUsageIcon from "../../../../assets/memory-usage-light.svg";
import healthPageStyles from "../../HealthPage.module.scss";
import { TMemoryUsageProps } from "../../types/types";

import styles from "./MemoryUsage.module.scss";

type TPieChartData<T> = {
  name: string;
  value: T;
};

export const MemoryUsage: React.FunctionComponent<TMemoryUsageProps> = ({
  memoryUsed,
  memoryAvailable,
}: TMemoryUsageProps): JSX.Element | null => {
  const pieChartData: Array<TPieChartData<number>> = [
    {
      name: "Used",
      value: Math.floor(memoryUsed / Math.pow(1024, 3)),
    },
    {
      name: "Available",
      value: Math.floor(memoryAvailable / Math.pow(1024, 3)),
    },
  ];

  const COLORS: Array<string> = ["#0088FE", "#00C49F"];

  return (
    <Popover.Root>
      <Popover.Trigger>
        <IconButton
          variant="solid"
          radius="full"
          size="1"
          className={styles.infoCircleButton}
        >
          <InfoCircledIcon />
        </IconButton>
      </Popover.Trigger>

      <Popover.Content
        side="left"
        sideOffset={-40}
        align="start"
        alignOffset={100}
        size="1"
      >
        <Flex direction={"column"} gap={"4"} align="center">
          <Flex
            direction={"row"}
            wrap={"wrap"}
            gap={"2"}
            justify="center"
            align={"center"}
          >
            <img
              src={memoryUsageIcon}
              alt="Memory Usage Icon"
              className={healthPageStyles.icon}
            />
            <Text as={"label"}>Memory Usage</Text>
          </Flex>

          <PieChart width={256} height={256}>
            <Pie
              data={pieChartData}
              cy={100}
              label={true}
              labelLine={true}
              innerRadius={55}
              outerRadius={80}
              dataKey="value"
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              iconType={"circle"}
              align="center"
              verticalAlign="bottom"
              wrapperStyle={{ left: "15px" }}
            />
          </PieChart>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};
