import { Box, Flex, Heading, Separator } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { Timeline } from "rsuite";
import "rsuite/Timeline/styles/index.css";

import {
  THealthState,
  gotSelectedTimestamp,
  healthStateSelector,
} from "../../../app/healthPageReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import styles from "./IdeEvents.module.scss";

type TTimelineItem = {
  id: string;
  label: string;
};

export const IdeEvents: React.FunctionComponent = (): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const { appsByTimestamp } = useAppSelector<THealthState>(healthStateSelector);

  const [timelineItems, setTimelineItems] = useState<Array<TTimelineItem>>([]);
  const [activeTimelineItem, setActiveTimelineItem] = useState<number>(0);

  useEffect(() => {
    if (appsByTimestamp) {
      const timelineItems: Array<TTimelineItem> = Object.keys(
        appsByTimestamp
      ).map((timestamp) => ({
        id: timestamp,
        label: `${new Date(timestamp).toDateString()}, ${new Date(
          timestamp
        ).toLocaleTimeString()}`,
      }));

      setTimelineItems(timelineItems);
      setActiveTimelineItem(0);

      dispatch(gotSelectedTimestamp(timelineItems[0].id));
    }
  }, [appsByTimestamp]);

  const onTimelineItemClick = (id: string): void => {
    const selectedItemIndex = timelineItems.findIndex(
      (item) => item.id === id
    )!;
    setActiveTimelineItem(selectedItemIndex);

    dispatch(gotSelectedTimestamp(id));
  };

  return (
    appsByTimestamp && (
      <Flex direction="column" gap="2" height="100%">
        <Heading as="h4">Events</Heading>

        <Separator size="4" />

        <Box p="2" grow="1" className={styles.eventsTimelineContainer}>
          <Timeline isItemActive={(index) => index === activeTimelineItem}>
            {timelineItems.map(({ id, label }) => (
              <Timeline.Item
                key={id}
                id={id}
                onClickCapture={() => onTimelineItemClick(id)}
              >
                {label}
              </Timeline.Item>
            ))}
          </Timeline>
        </Box>
      </Flex>
    )
  );
};
