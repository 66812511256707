// CODEGEN DO NOT EDIT

import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const end = z.union([z.string(), z.null()]).optional();
const GenericActivityRequest = z
  .object({
    deployhash: z.string(),
    start: z.union([z.string(), z.null()]),
    end: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough();
const FaceAuthModel = z
  .object({
    timestamp: z.string().datetime({ offset: true }),
    success: z.boolean(),
  })
  .passthrough();
const FaceAuthActivityResponse = z
  .object({
    requested: GenericActivityRequest,
    data: z.union([z.array(FaceAuthModel), z.null()]),
  })
  .passthrough();
const ValidationError = z
  .object({
    loc: z.array(z.union([z.string(), z.number()])),
    msg: z.string(),
    type: z.string(),
  })
  .passthrough();
const HTTPValidationError = z
  .object({ detail: z.array(ValidationError) })
  .partial()
  .passthrough();
const TtfbModel = z
  .object({
    timestamp: z.string().datetime({ offset: true }),
    value: z.number(),
  })
  .passthrough();
const TtfbActivityResponse = z
  .object({
    requested: GenericActivityRequest,
    data: z.union([z.array(TtfbModel), z.null()]),
  })
  .passthrough();
const HealthCheckActivityResponse = z
  .object({
    requested: GenericActivityRequest,
    data: z.union([z.array(z.object({}).partial().passthrough()), z.null()]),
  })
  .passthrough();
const DeployHashRequest = z.object({ deployhash: z.string() }).passthrough();
const IsActiveModel = z.object({ active: z.boolean() }).passthrough();
const IsActiveResponse = z
  .object({ requested: DeployHashRequest, data: IsActiveModel })
  .passthrough();

export const schemas = {
  end,
  GenericActivityRequest,
  FaceAuthModel,
  FaceAuthActivityResponse,
  ValidationError,
  HTTPValidationError,
  TtfbModel,
  TtfbActivityResponse,
  HealthCheckActivityResponse,
  DeployHashRequest,
  IsActiveModel,
  IsActiveResponse,
};

export type Tend = z.infer<typeof end>;
export type TGenericActivityRequest = z.infer<typeof GenericActivityRequest>;
export type TFaceAuthModel = z.infer<typeof FaceAuthModel>;
export type TFaceAuthActivityResponse = z.infer<
  typeof FaceAuthActivityResponse
>;
export type TValidationError = z.infer<typeof ValidationError>;
export type THTTPValidationError = z.infer<typeof HTTPValidationError>;
export type TTtfbModel = z.infer<typeof TtfbModel>;
export type TTtfbActivityResponse = z.infer<typeof TtfbActivityResponse>;
export type THealthCheckActivityResponse = z.infer<
  typeof HealthCheckActivityResponse
>;
export type TDeployHashRequest = z.infer<typeof DeployHashRequest>;
export type TIsActiveModel = z.infer<typeof IsActiveModel>;
export type TIsActiveResponse = z.infer<typeof IsActiveResponse>;

const endpoints = makeApi([
  {
    method: "get",
    path: "/",
    alias: "root__get",
    requestFormat: "json",
    response: z.unknown(),
  },
  {
    method: "get",
    path: "/v1/deployment/:deployhash/faceauth",
    alias:
      "get_deployment_face_auth_activity_v1_deployment__deployhash__faceauth_get",
    description: `face-auth activity for deployment`,
    requestFormat: "json",
    parameters: [
      {
        name: "deployhash",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.string().datetime({ offset: true }),
      },
      {
        name: "end",
        type: "Query",
        schema: end,
      },
    ],
    response: FaceAuthActivityResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/deployment/:deployhash/health_check",
    alias:
      "get_deployment_health_check_v1_deployment__deployhash__health_check_get",
    description: `deployment health-check stats`,
    requestFormat: "json",
    parameters: [
      {
        name: "deployhash",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.string().datetime({ offset: true }),
      },
      {
        name: "end",
        type: "Query",
        schema: end,
      },
    ],
    response: HealthCheckActivityResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/deployment/:deployhash/is_active",
    alias: "get_is_active_v1_deployment__deployhash__is_active_get",
    description: `Return boolean value about given deployhash is an IDE instance and it is being actively using by any user from last 10 minutes.`,
    requestFormat: "json",
    parameters: [
      {
        name: "deployhash",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: IsActiveResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/v1/deployment/:deployhash/ttfb",
    alias: "get_deployment_ttfb_activity_v1_deployment__deployhash__ttfb_get",
    description: `time-to-first-byte(ms) for deployment`,
    requestFormat: "json",
    parameters: [
      {
        name: "deployhash",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.string().datetime({ offset: true }),
      },
      {
        name: "end",
        type: "Query",
        schema: end,
      },
    ],
    response: TtfbActivityResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
