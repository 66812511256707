import React, { InputHTMLAttributes } from "react";

import { TextField } from "@radix-ui/themes";

type Props = React.ComponentProps<typeof TextField.Input> &
  InputHTMLAttributes<HTMLImageElement>;

export const DateInput = (props: Props) => (
  <TextField.Input type="date" style={{ paddingRight: "10px" }} {...props} />
);
