import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import { adminUsersPageReducer } from "./adminUsersPageReducer";
import { healthPageReducer } from "./healthPageReducer";
import { instancePageReducer } from "./instancePageReducer";
import { layoutReducer } from "./layoutReducer";
import { loginPageReducer } from "./loginPageReducer";
import { capacityPartnersPageReducer } from "./capacityPartnersPageReducer";
import { apiUsersPageReducer } from "./apiUsersPageReducer";
import { countryCodesPageReducer } from "./countryCodesPageReducer";
import { featureFlagReducer } from "./featureFlagsReducer";
import { clustersOverviewPageSlice } from "./clustersOverviewPageReducer";
import { clusterDetailsPageReducer } from "./clusterDetailsPageReducer";
import { healthOverviewPageReducer } from "./healthOverviewPageReducer";
import { instancesHealthOverviewPageReducer } from "./instancesHealthOverviewPageReducer";
import { userPreferencesReducer } from "./userPreferencesReducer";
import * as ideApiBase from "./api/ide/ideApiBase";
import * as reportsApiBase from "./api/reports/reportsApiBase";

const persistUserPreferencesConfig = {
  key: "userPreferences",
  storage,
};

import { chatApi } from "../chat/chatApi";

export const store = configureStore({
  reducer: {
    instancePageReducer,
    loginPageReducer,
    adminUsersPageReducer,
    layoutReducer,
    capacityPartnersPageReducer,
    apiUsersPageReducer,
    healthPageReducer,
    countryCodesPageReducer,
    featureFlagReducer,
    clustersOverviewPageReducer: clustersOverviewPageSlice.reducer,
    clusterDetailsPageReducer,
    healthOverviewPageReducer,
    instancesHealthOverviewPageReducer,
    userPreferencesReducer: persistReducer(
      persistUserPreferencesConfig,
      userPreferencesReducer
    ) as any,
    [chatApi.reducerPath]: chatApi.reducer,
    [ideApiBase.emptySplitApi.reducerPath]: ideApiBase.emptySplitApi.reducer,
    [reportsApiBase.emptySplitApi.reducerPath]:
      reportsApiBase.emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    })
      .concat(chatApi.middleware)
      .concat(ideApiBase.emptySplitApi.middleware)
      .concat(reportsApiBase.emptySplitApi.middleware),
});

export const persistor = persistStore(store);

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof store.getState>;
export type TAppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  TRootState,
  unknown,
  Action<string>
>;
