import { emptySplitApi as api } from "./reportsApiBase";
export const addTagTypes = ["reports"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createReportV1ReportsPost: build.mutation<
        CreateReportV1ReportsPostApiResponse,
        CreateReportV1ReportsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/reports`,
          method: "POST",
          body: queryArg.report,
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["reports"],
      }),
      listReportsV1ReportsGet: build.query<
        ListReportsV1ReportsGetApiResponse,
        ListReportsV1ReportsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/reports`,
          params: {
            type: queryArg["type"],
            end_date: queryArg.endDate,
            start_date: queryArg.startDate,
          },
        }),
        providesTags: ["reports"],
      }),
      listReportTypesV1ReportsTypesGet: build.query<
        ListReportTypesV1ReportsTypesGetApiResponse,
        ListReportTypesV1ReportsTypesGetApiArg
      >({
        query: () => ({ url: `/v1/reports/types` }),
        providesTags: ["reports"],
      }),
      getReportV1ReportsReportIdGet: build.query<
        GetReportV1ReportsReportIdGetApiResponse,
        GetReportV1ReportsReportIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/v1/reports/${queryArg.reportId}` }),
        providesTags: ["reports"],
      }),
      getReportFileV1ReportsFileReportIdGet: build.query<
        GetReportFileV1ReportsFileReportIdGetApiResponse,
        GetReportFileV1ReportsFileReportIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/reports/file/${queryArg.reportId}`,
          headers: { accepts: queryArg.accepts },
        }),
        providesTags: ["reports"],
      }),
      rootGet: build.query<RootGetApiResponse, RootGetApiArg>({
        query: () => ({ url: `/` }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as reportsApi };
export type CreateReportV1ReportsPostApiResponse =
  /** status 202 Successful Response */ ReportRead;
export type CreateReportV1ReportsPostApiArg = {
  authorization: string | null;
  report:
    | BypassReportCreateRequest
    | PipelineStatsReportCreateRequest
    | FraudReportCreateRequest;
};
export type ListReportsV1ReportsGetApiResponse =
  /** status 200 Successful Response */ ReportListResponse;
export type ListReportsV1ReportsGetApiArg = {
  type: ReportType;
  endDate: string;
  startDate: string;
};
export type ListReportTypesV1ReportsTypesGetApiResponse =
  /** status 200 Successful Response */ any;
export type ListReportTypesV1ReportsTypesGetApiArg = void;
export type GetReportV1ReportsReportIdGetApiResponse =
  /** status 200 Successful Response */ ReportRead;
export type GetReportV1ReportsReportIdGetApiArg = {
  reportId: number;
};
export type GetReportFileV1ReportsFileReportIdGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetReportFileV1ReportsFileReportIdGetApiArg = {
  reportId: number;
  accepts?: ReportFileMimeType | null;
};
export type RootGetApiResponse = /** status 200 Successful Response */ any;
export type RootGetApiArg = void;
export type ReportType = "ide_bypass" | "pipeline_statistics" | "fraud";
export type ReportStatus = "pending" | "processing" | "completed" | "failed";
export type BypassReportOption = {
  instance_names: string[];
};
export type GitlabPipelineStatus =
  | "running"
  | "created"
  | "fail"
  | "in_progress"
  | "pending"
  | "success";
export type PipelineReportOption = {
  pipeline_status?: GitlabPipelineStatus | null;
};
export type FraudReportOption = {};
export type ReportRead = {
  created_at?: string;
  updated_at: string | null;
  type: ReportType;
  status?: ReportStatus | null;
  start_date: string;
  end_date: string;
  options: BypassReportOption | PipelineReportOption | FraudReportOption;
  report?: object[] | object | null;
  id: number;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type BypassReportCreateRequest = {
  type: "ide_bypass";
  end_date: string;
  start_date: string;
  instance_names: string[];
};
export type PipelineStatsReportCreateRequest = {
  type: "pipeline_statistics";
  end_date: string;
  start_date: string;
  pipeline_status?: GitlabPipelineStatus | null;
};
export type FraudReportCreateRequest = {
  type: "fraud";
  end_date: string;
  start_date: string;
};
export type ReportListRequest = {
  type: ReportType;
  end_date: string;
  start_date: string;
};
export type ReportDetails = {
  created_at?: string;
  updated_at: string | null;
  type: ReportType;
  status?: ReportStatus | null;
  start_date: string;
  end_date: string;
  options: BypassReportOption | PipelineReportOption | FraudReportOption;
  id: number;
};
export type ReportListResponse = {
  requested: ReportListRequest;
  reports: ReportDetails[];
};
export type ReportFileMimeType =
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "text/csv";
export const {
  useCreateReportV1ReportsPostMutation,
  useListReportsV1ReportsGetQuery,
  useListReportTypesV1ReportsTypesGetQuery,
  useGetReportV1ReportsReportIdGetQuery,
  useGetReportFileV1ReportsFileReportIdGetQuery,
  useRootGetQuery,
} = injectedRtkApi;
