import {
  TActivityApiData,
  TActivityApiAw,
  TActivityApiGit,
  TActivityApiPython,
} from "../../../../server/src/health/types";
import awIcon from "../../../assets/aw-icon.svg";
import gitIcon from "../../../assets/git-icon.svg";
import pythonIcon from "../../../assets/python-icon.svg";
import {
  TAppProperty,
  TAppAw,
  TAppGit,
  TAppPython,
  TAppsByTimestamp,
} from "../types/types";

export const getActivityData = (
  activityApiData: Array<TActivityApiData>,
  existingData: Array<TActivityApiData>
): Array<TActivityApiData> => {
  const filteredData: Array<TActivityApiData> = activityApiData.filter(
    (data) =>
      !existingData.find((existing) => existing.timestamp === data.timestamp)
  );

  return [...existingData, ...filteredData];
};

export const getAppsByTimestamp = (
  activitiyApiData: Array<TActivityApiData>,
  existingAppsByTimestamp: TAppsByTimestamp = {}
): TAppsByTimestamp => {
  const appsByTimestamp: TAppsByTimestamp = {};

  activitiyApiData.forEach(({ timestamp, app }: TActivityApiData) => {
    timestamp = new Date(timestamp).toISOString();
    appsByTimestamp[timestamp] = {};

    Object.keys(app).forEach((key: string) => {
      switch (key) {
        case "git":
          appsByTimestamp[timestamp].git = getGitApp(app[key]!);
          break;
        case "python":
          appsByTimestamp[timestamp].python = getPythonApp(app[key]!);
          break;
        case "aw":
          appsByTimestamp[timestamp].aw = getAwApp(app[key]!);
          break;
        default:
          return;
      }
    });

    const existing = { ...existingAppsByTimestamp };
    return Object.assign(appsByTimestamp, existing);
  });

  return appsByTimestamp;
};

const getGitApp = ({
  git_exists,
  git_user_configured,
  git_last_commit,
  display_name,
}: TActivityApiGit): TAppGit => {
  const gitApp: TAppGit = {
    displayName: display_name,
    icon: gitIcon,
    isInstalled: git_exists,
    gitUserConfigured: getAppProperty<boolean>(
      "User configured",
      git_user_configured
    ),
    gitLastCommit: getAppProperty<string>("Last commit", git_last_commit),
  };

  return gitApp;
};

const getPythonApp = ({
  python_version,
  python_exists,
  display_name,
}: TActivityApiPython): TAppPython => {
  const pythonApp: TAppPython = {
    displayName: display_name,
    icon: pythonIcon,
    isInstalled: python_exists,
    pythonVersion: getAppProperty<string>("Version", python_version),
  };

  return pythonApp;
};

const getAwApp = ({
  aw_server_exists,
  aw_window_exists,
  aw_afk_exists,
  aw_server_running,
  aw_window_running,
  aw_afk_running,
  aw_server_upstream,
  aw_display,
  display_name,
}: TActivityApiAw): TAppAw => {
  const awApp: TAppAw = {
    displayName: display_name,
    icon: awIcon,
    isInstalled: aw_server_exists,
    awWindowExists: getAppProperty<boolean>("AW Window", aw_window_exists),
    awAfkExists: getAppProperty<boolean>("AW AFK installed", aw_afk_exists),
    awServerRunning: getAppProperty<boolean>(
      "AW Server running",
      aw_server_running
    ),
    awWindowRunning: getAppProperty<boolean>(
      "AW Window open",
      aw_window_running
    ),
    awAfkRunning: getAppProperty<boolean>("AW AFK running", aw_afk_running),
    awServerUpstream: getAppProperty<boolean>(
      "AW Server upstream",
      aw_server_upstream
    ),
    awDisplay: getAppProperty<string>("AW Display", aw_display),
  };

  return awApp;
};

const getAppProperty = <T>(displayName: string, value: T): TAppProperty<T> => {
  const appProperty: TAppProperty<T> = {
    displayName,
    value,
  };

  return appProperty;
};
