import React from "react";
import { Badge } from "@radix-ui/themes";
import { match } from "ts-pattern";

interface TProvisionStatusProps extends React.ComponentProps<typeof Badge> {
  status: string;
}

export default function ProvisionStatus(props: TProvisionStatusProps) {
  const { status, ...rest } = props;

  return match(status)
    .with("archived", () => (
      <Badge {...rest} color="brown">
        Archived
      </Badge>
    ))
    .with("completed", () => (
      <Badge {...rest} color="green">
        Completed
      </Badge>
    ))
    .with("failed", () => (
      <Badge {...rest} color="red">
        Failed
      </Badge>
    ))
    .with("deleted", () => (
      <Badge {...rest} color="gray">
        Deleted
      </Badge>
    ))
    .with("in_progress", () => (
      <Badge {...rest} color="yellow">
        In progress
      </Badge>
    ))
    .with("soft_destroy", () => (
      <Badge {...rest} color="purple">
        Soft destroy
      </Badge>
    ))
    .otherwise((value) => <Badge {...rest}>{value}</Badge>);
}
