import React from "react";
import { Box, Flex, Heading, Separator } from "@radix-ui/themes";
import { SRD } from "srd";

import {
  healthStateSelector,
  THealthState,
} from "../../../app/healthPageReducer";
import { useAppSelector } from "../../../app/hooks";
import { Error } from "../../../components/Error";
import PageLoaderPositions from "../../../components/PageLoading/enums/PageLoaderPositions";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { AppDetails } from "../AppDetails/AppDetails";
import { AppList } from "../AppList/AppList";
import { AppsRefresh } from "../AppsRefresh/AppsRefresh";
import healthPageStyles from "../../HealthPage.module.scss";

import styles from "./IdeApps.module.scss";

export const IdeApps: React.FunctionComponent = (): JSX.Element => {
  const healthState = useAppSelector<THealthState>(healthStateSelector);
  const { activityApiResponse, appsByTimestamp } = healthState;

  return (
    <Flex direction="column" gap="2" grow="1" className={styles.appsContainer}>
      <Flex direction="row" justify="between">
        <Heading as="h4">Apps</Heading>

        <AppsRefresh />
      </Flex>

      <Separator size="4" />

      {SRD.match(
        {
          notAsked: () => <></>,
          loading: () => (
            <Flex direction="row" justify="center" align="center" grow="1">
              <PageLoading
                position={PageLoaderPositions.CENTER}
                size={70}
                label={
                  <label className={healthPageStyles.loaderLabel}>
                    Loading Apps ..
                  </label>
                }
              />
            </Flex>
          ),
          failure: () => (
            <Box>
              <Error>Failed to load apps.</Error>
            </Box>
          ),
          success: () =>
            appsByTimestamp && (
              <Flex
                direction="row"
                gap="4"
                grow="1"
                className={styles.appsBottomContainer}
              >
                <Box className={styles.appListContainer} height="100%">
                  <AppList />
                </Box>

                <Box grow="1" className={styles.appDetailsContainer} p="4">
                  <AppDetails />
                </Box>
              </Flex>
            ),
        },
        activityApiResponse
      )}
    </Flex>
  );
};
