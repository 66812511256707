import React from "react";
import { Badge } from "@radix-ui/themes";

export function YesNo(props: { value: boolean }) {
  const text = props.value ? "Yes" : "No";

  const color = props.value ? "jade" : "amber";

  return (
    <Badge variant="surface" color={color}>
      {text}
    </Badge>
  );
}
