import React, { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Flex, Table } from "@radix-ui/themes";

import { TGenericSection, organise } from "./data";
import { SortArrows } from "../../components/Table/TableSort";
import { Pagination } from "../../components/Pagination/Pagination";
import { paginationStatusZeroBased } from "../../app/pagination";

export const GenericReportView = (props: { data: any }) => {
  const data = useMemo(() => {
    return organise(props.data);
  }, [props.data]);

  return (
    <Flex direction="column" gap="7">
      {data.sections.map((data) => {
        return <GenericReportTable key={data.title} data={data} />;
      })}
    </Flex>
  );
};

export const GenericReportTable = (props: { data: TGenericSection }) => {
  const { title, rows, columns } = props.data;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState([] as any);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
      sorting,
    },
  });

  const { getHeaderGroups, getRowModel } = table;

  const ps = paginationStatusZeroBased(
    { pageNumber: pagination.pageIndex, pageSize: pagination.pageSize },
    rows.length
  );

  const handleSort = (columnId: string, direction: "asc" | "desc") => {
    table.setSorting([{ id: columnId, desc: direction === "desc" }]);
  };

  return (
    <Flex gap="3" direction="column" data-testid="generic-reports-table">
      <Flex justify="between" align="center">
        <div>{title}</div>
        <Pagination paginate={table.setPageIndex} status={ps} />
      </Flex>
      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            {getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.ColumnHeaderCell
                  key={header.id}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Flex align="center" gap="1">
                    <div
                      onClick={() => header.column.toggleSorting()}
                      style={{ cursor: "pointer", userSelect: "none" }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>

                    {header.column.getCanSort() && (
                      <SortArrows
                        onUpClick={() => handleSort(header.id, "asc")}
                        onDownClick={() => handleSort(header.id, "desc")}
                        upActive={header.column.getIsSorted() === "asc"}
                        downActive={header.column.getIsSorted() === "desc"}
                      />
                    )}
                  </Flex>
                </Table.ColumnHeaderCell>
              ));
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
};
