import { z } from "zod";

export const ZCapacityPartner = z.object({
  id: z.string(),
  email: z.string(),
  email_alias: z.string().nullable(),
  face_auth: z.boolean().nullable(),
  afk_worker: z.boolean().nullable(),
});

export type TCapacityPartner = z.infer<typeof ZCapacityPartner>;

export const ZCapacityPartnerSearchQuery = z.object({
  email: z.string(),
  offset: z.number(),
  limit: z.number(),
});

export type TCapacityPartnerSearchQuery = z.infer<
  typeof ZCapacityPartnerSearchQuery
>;

export const ZCapacityPartnerSearchResults = z.object({
  total: z.number(),
  rows: z.array(ZCapacityPartner),
});

export type TCapacityPartnerSearchResults = z.infer<
  typeof ZCapacityPartnerSearchResults
>;

export const ZCapacityPartnerSetFaceAuth = z.object({
  id: z.string(),
  face_auth: z.boolean(),
});

export type TCapacityPartnerSetFaceAuth = z.infer<
  typeof ZCapacityPartnerSetFaceAuth
>;

export const ZCapacityPartnerSetAfkWorker = z.object({
  id: z.string(),
  afk_worker: z.boolean(),
});

export type TCapacityPartnerSetAfkWorker = z.infer<
  typeof ZCapacityPartnerSetAfkWorker
>;
