import React from "react";
import { ClipboardCopyIcon } from "@radix-ui/react-icons";
import { useCopyToClipboard } from "usehooks-ts";

import styles from "./Copyable.module.scss";

export function Copyable(props: React.PropsWithChildren<{ copyText: string }>) {
  const copy = useCopyToClipboard()[1];

  return (
    <div
      className={styles.copyIcon}
      onClick={() => {
        copy(props.copyText);
      }}
    >
      <ClipboardCopyIcon />
    </div>
  );
}
