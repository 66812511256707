import React, { useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import { Heading, Flex } from "@radix-ui/themes";

import { Convo } from "../Convo/Convo";
import Button from "../../components/Button/Button";
import * as layout from "../../app/layoutReducer";
import { chatApi } from "../chatApi";
import Spinner from "../../components/Spinner/Spinner";
import { useAppDispatch } from "../../app/hooks";

import styles from "./ChatHistoryPage.module.scss";

export function ChatHistoryPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([]));
  }, []);

  const params = useParams();

  const hostname = params.hostname as string;

  const { usePostResetRequestCountMutation } = chatApi;

  const [resetRequestCount, { isLoading }] = usePostResetRequestCountMutation();

  function extractHexString(str) {
    const regex = /[0-9a-fA-F]{12}/;
    const match = str.match(regex);
    return match ? match[0] : null;
  }

  const instanceName = extractHexString(hostname);

  const instanceLink = instanceName ? (
    <Link to={`/instance/${instanceName}`}>{instanceName}</Link>
  ) : (
    <></>
  );

  return (
    <div className={styles.pageWrap}>
      <div className={styles.header}>
        <Flex gap="3" align="center">
          <Heading>{hostname}</Heading>
          {instanceLink}
        </Flex>
        <div>
          <Flex gap="2" direction="row" justify="center">
            <RateLimit hostname={hostname} />
            <Button
              size="2"
              onClick={() => resetRequestCount(hostname)}
              pending={isLoading}
            >
              Reset
            </Button>
          </Flex>
        </div>
      </div>
      <hr />
      <div className={styles.convoWrap}>
        <Convo hostname={hostname as string} />
      </div>
    </div>
  );
}

function RateLimit(props: { hostname: string }) {
  const { useGetRateLimitQuery } = chatApi;

  const { isLoading, isSuccess, isFetching, data } = useGetRateLimitQuery({
    hostname: props.hostname,
  });

  if (isLoading) {
    return (
      <div className={styles.rateLimit}>
        <Spinner />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div className={cn(styles.rateLimit, { isFetching })}>
        {data.request_count} / {data.request_limit} msgs today
      </div>
    );
  }
}
