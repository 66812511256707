import React, { useEffect, useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Table, Flex } from "@radix-ui/themes";
import cn from "classnames";

import { Pagination } from "../../components/Pagination/Pagination";
import { PipelineReadRead, PipelineStatus } from "../../app/api/ide/ideService";
import { useSearchPipelinesV1PipelinesSearchPostCustomQuery } from "../../app/api/ide/ideApiBase";
import Spinner from "../Spinner/Spinner";
import { Error } from "../Error";
import { paginationStatusZeroBased } from "../../app/pagination";
import { Timestamp } from "../Timestamp/Timestamp";
import { PipelinesStatus } from "../../components/PipelinesStatus";

const columnHelper = createColumnHelper<PipelineReadRead>();

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("pipeline_type", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    cell: (info) => (
      <PipelinesStatus status={info.getValue() as PipelineStatus} />
    ),
  }),
  columnHelper.accessor("started_at", {
    cell: (info) => {
      const value = info.getValue();

      if (value) {
        return <Timestamp value={value as string} />;
      }
    },
  }),
  columnHelper.accessor("execution_time", {
    cell: (info) => info.getValue()?.toFixed(0),
  }),
  columnHelper.accessor("external_pipeline_provider", {
    header: "provider",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("url", {
    cell: (info) => (
      <Link target="_blank" to={info.getValue()}>
        External link
      </Link>
    ),
  }),
];

export function PipelineTable(props: { instanceName: string }) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, error, isLoading, isFetching, isError, refetch } =
    useSearchPipelinesV1PipelinesSearchPostCustomQuery({
      pipelineFilterRequest: {
        pagination: {
          page: pagination.pageIndex + 1,
          page_size: pagination.pageSize,
        },
        filters: {
          instance__instance_name: {
            op: "contains",
            value: props.instanceName,
          },
        },
        sort: {
          created_at: "desc",
        },
      },
    });

  const rows = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const table = useReactTable({
    data: rows as PipelineReadRead[],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    state: {
      pagination,
    },
  });

  const { getHeaderGroups, getRowModel } = table;

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [pagination.pageIndex]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    const errorText = (error as any).data?.detail || (error as any).data;

    return <Error>{errorText}</Error>;
  }

  const ps = paginationStatusZeroBased(
    { pageNumber: pagination.pageIndex, pageSize: pagination.pageSize },
    data?.pagination.total_results as number
  );

  return (
    <Flex gap="3" direction="column">
      <Table.Root
        variant="surface"
        data-testid="pipeline-table"
        className={cn({ isFetching })}
      >
        <Table.Header>
          <Table.Row>
            {getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.ColumnHeaderCell
                  key={header.id}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Table.ColumnHeaderCell>
              ));
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <Flex justify="between" align="center">
        <Pagination paginate={table.setPageIndex} status={ps} />
      </Flex>
    </Flex>
  );
}
