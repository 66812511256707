import React, { ButtonHTMLAttributes } from "react";
import * as theme from "@radix-ui/themes";
import cn from "classnames";

import Spinner from "../Spinner/Spinner";

import styles from "./Button.module.scss";

type AllButtonProps = React.ComponentProps<typeof theme.Button> &
  ButtonHTMLAttributes<HTMLButtonElement>;

interface ButtonProps_ extends AllButtonProps {
  pending?: boolean;
}

export default function Button(props: ButtonProps_) {
  const { pending, ...rest } = props;

  let spinner = <></>;

  if (pending) {
    spinner = (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <span className={cn({ [styles.pending]: pending })}>
      <theme.Button {...(rest as any)} disabled={props.disabled || pending} />
      {spinner}
    </span>
  );
}
