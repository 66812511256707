export const setListInLocalStorage = (key, list) => {
  const serializedList = JSON.stringify(list);
  localStorage.setItem(key, serializedList);
};

export const getListFromLocalStorage = (key) => {
  const serializedList = localStorage.getItem(key);

  if (serializedList == null) {
    return [];
  }

  return JSON.parse(serializedList);
};

export const appendToListInLocalStorage = (key, item) => {
  const currentList = getListFromLocalStorage(key) || [];

  currentList.push(item);

  setListInLocalStorage(key, currentList);

  return currentList;
};

export const addStorageListener = (key, callback) => {
  const handleStorageChange = (event) => {
    if (event.key === key) {
      const newValue = JSON.parse(event.newValue);
      callback(newValue);
    }
  };

  window.addEventListener("storage", handleStorageChange);

  // return a function to remove the listener
  return () => {
    window.removeEventListener("storage", handleStorageChange);
  };
};
