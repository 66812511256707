import { emptySplitApi as api } from "./ideApiBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCountryCodesV1CountryCodesGet: build.query<
      GetCountryCodesV1CountryCodesGetApiResponse,
      GetCountryCodesV1CountryCodesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/country_codes`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    listInstancesV1InstancesGet: build.query<
      ListInstancesV1InstancesGetApiResponse,
      ListInstancesV1InstancesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    searchInstancesV1InstancesSearchPost: build.mutation<
      SearchInstancesV1InstancesSearchPostApiResponse,
      SearchInstancesV1InstancesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/search`,
        method: "POST",
        body: queryArg.instanceFilterRequest,
      }),
    }),
    getInstanceByNameV1InstancesInstanceNameGet: build.query<
      GetInstanceByNameV1InstancesInstanceNameGetApiResponse,
      GetInstanceByNameV1InstancesInstanceNameGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/instances/${queryArg.instanceName}` }),
    }),
    updateIdePipelineCompleteV1InstancesInstanceNamePatch: build.mutation<
      UpdateIdePipelineCompleteV1InstancesInstanceNamePatchApiResponse,
      UpdateIdePipelineCompleteV1InstancesInstanceNamePatchApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}`,
        method: "PATCH",
        body: queryArg.tesseractPipelineUpdateRequest,
      }),
    }),
    listClustersV1ClustersGet: build.query<
      ListClustersV1ClustersGetApiResponse,
      ListClustersV1ClustersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/clusters`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    createClusterV1ClustersPost: build.mutation<
      CreateClusterV1ClustersPostApiResponse,
      CreateClusterV1ClustersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/clusters`,
        method: "POST",
        body: queryArg.clusterCreate,
      }),
    }),
    getClusterInfoV1ClustersClusterIdGet: build.query<
      GetClusterInfoV1ClustersClusterIdGetApiResponse,
      GetClusterInfoV1ClustersClusterIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/clusters/${queryArg.clusterId}` }),
    }),
    editClusterV1ClustersClusterIdPut: build.mutation<
      EditClusterV1ClustersClusterIdPutApiResponse,
      EditClusterV1ClustersClusterIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/clusters/${queryArg.clusterId}`,
        method: "PUT",
        body: queryArg.clusterEdit,
      }),
    }),
    deleteClusterV1ClustersClusterIdDelete: build.mutation<
      DeleteClusterV1ClustersClusterIdDeleteApiResponse,
      DeleteClusterV1ClustersClusterIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/clusters/${queryArg.clusterId}`,
        method: "DELETE",
      }),
    }),
    getPipelineByIdV1PipelinesIdGet: build.query<
      GetPipelineByIdV1PipelinesIdGetApiResponse,
      GetPipelineByIdV1PipelinesIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/pipelines/${queryArg.id}` }),
    }),
    getPipelineByExternalIdV1PipelinesExternalPipelineProviderExternalPipelineIdGet:
      build.query<
        GetPipelineByExternalIdV1PipelinesExternalPipelineProviderExternalPipelineIdGetApiResponse,
        GetPipelineByExternalIdV1PipelinesExternalPipelineProviderExternalPipelineIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/pipelines/${queryArg.externalPipelineProvider}/${queryArg.externalPipelineId}`,
        }),
      }),
    createPipelineV1PipelinesPost: build.mutation<
      CreatePipelineV1PipelinesPostApiResponse,
      CreatePipelineV1PipelinesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/pipelines/`,
        method: "POST",
        body: queryArg.pipelineCreate,
      }),
    }),
    updatePipelineStatusV1PipelinesIdStatusStatusPut: build.mutation<
      UpdatePipelineStatusV1PipelinesIdStatusStatusPutApiResponse,
      UpdatePipelineStatusV1PipelinesIdStatusStatusPutApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/pipelines/${queryArg.id}/status/${queryArg.status}`,
        method: "PUT",
      }),
    }),
    searchPipelinesV1PipelinesSearchPost: build.mutation<
      SearchPipelinesV1PipelinesSearchPostApiResponse,
      SearchPipelinesV1PipelinesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/pipelines/search`,
        method: "POST",
        body: queryArg.pipelineFilterRequest,
      }),
    }),
    getGitlabPipelinesV1GitlabPipelinesGet: build.query<
      GetGitlabPipelinesV1GitlabPipelinesGetApiResponse,
      GetGitlabPipelinesV1GitlabPipelinesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/gitlab_pipelines`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    searchGitlabPipelinesEndpointV1GitlabPipelinesSearchPost: build.mutation<
      SearchGitlabPipelinesEndpointV1GitlabPipelinesSearchPostApiResponse,
      SearchGitlabPipelinesEndpointV1GitlabPipelinesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/gitlab_pipelines/search`,
        method: "POST",
        body: queryArg.gitlabPipelineFilterRequest,
      }),
    }),
    getGitlabPipelineByIdV1GitlabPipelinesIdGet: build.query<
      GetGitlabPipelineByIdV1GitlabPipelinesIdGetApiResponse,
      GetGitlabPipelineByIdV1GitlabPipelinesIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/gitlab_pipelines/${queryArg.id}` }),
    }),
    listAdminUsersV1AdminUsersGet: build.query<
      ListAdminUsersV1AdminUsersGetApiResponse,
      ListAdminUsersV1AdminUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin_users`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    createAdminUserV1AdminUsersPost: build.mutation<
      CreateAdminUserV1AdminUsersPostApiResponse,
      CreateAdminUserV1AdminUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin_users`,
        method: "POST",
        body: queryArg.adminUserCreate,
      }),
    }),
    getAdminUserByIdV1AdminUsersAdminUserIdGet: build.query<
      GetAdminUserByIdV1AdminUsersAdminUserIdGetApiResponse,
      GetAdminUserByIdV1AdminUsersAdminUserIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/admin_users/${queryArg.adminUserId}` }),
    }),
    editAdminUserV1AdminUsersAdminUserIdPut: build.mutation<
      EditAdminUserV1AdminUsersAdminUserIdPutApiResponse,
      EditAdminUserV1AdminUsersAdminUserIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin_users/${queryArg.adminUserId}`,
        method: "PUT",
        body: queryArg.adminUserCreate,
      }),
    }),
    deleteAdminUserV1AdminUsersAdminUserIdDelete: build.mutation<
      DeleteAdminUserV1AdminUsersAdminUserIdDeleteApiResponse,
      DeleteAdminUserV1AdminUsersAdminUserIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin_users/${queryArg.adminUserId}`,
        method: "DELETE",
      }),
    }),
    searchAdminUsersEndpointV1AdminUsersSearchPost: build.mutation<
      SearchAdminUsersEndpointV1AdminUsersSearchPostApiResponse,
      SearchAdminUsersEndpointV1AdminUsersSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin_users/search`,
        method: "POST",
        body: queryArg.adminUserFilterRequest,
      }),
    }),
    getApiUsersV1ApiUsersGet: build.query<
      GetApiUsersV1ApiUsersGetApiResponse,
      GetApiUsersV1ApiUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/api_users`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          order_by: queryArg.orderBy,
          order: queryArg.order,
        },
      }),
    }),
    editApiUserV1ApiUsersApiUserIdPut: build.mutation<
      EditApiUserV1ApiUsersApiUserIdPutApiResponse,
      EditApiUserV1ApiUsersApiUserIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/api_users/${queryArg.apiUserId}`,
        method: "PUT",
        body: queryArg.apiUserUpdate,
      }),
    }),
    deployIdeV1InstancesDeployPost: build.mutation<
      DeployIdeV1InstancesDeployPostApiResponse,
      DeployIdeV1InstancesDeployPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/deploy`,
        method: "POST",
        body: queryArg.deploymentCreateRequest,
      }),
    }),
    getIdeDetailsV1InstancesInstanceNameDetailsGet: build.query<
      GetIdeDetailsV1InstancesInstanceNameDetailsGetApiResponse,
      GetIdeDetailsV1InstancesInstanceNameDetailsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/details`,
      }),
    }),
    getIdeDetailsWithTokenV1InstancesDetailsAccessTokenGet: build.query<
      GetIdeDetailsWithTokenV1InstancesDetailsAccessTokenGetApiResponse,
      GetIdeDetailsWithTokenV1InstancesDetailsAccessTokenGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/details/${queryArg.accessToken}`,
      }),
    }),
    resetIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionPut:
      build.mutation<
        ResetIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionPutApiResponse,
        ResetIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionPutApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/instances/${queryArg.instanceName}/guacamole_connection`,
          method: "PUT",
        }),
      }),
    deleteIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionDelete:
      build.mutation<
        DeleteIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionDeleteApiResponse,
        DeleteIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/instances/${queryArg.instanceName}/guacamole_connection`,
          method: "DELETE",
        }),
      }),
    deallocateIdeV1InstancesInstanceNameDeallocatePut: build.mutation<
      DeallocateIdeV1InstancesInstanceNameDeallocatePutApiResponse,
      DeallocateIdeV1InstancesInstanceNameDeallocatePutApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/deallocate`,
        method: "PUT",
        body: queryArg.deploymentDeallocateRequet,
      }),
    }),
    updateIdeV1InstancesInstanceNameUpdatePatch: build.mutation<
      UpdateIdeV1InstancesInstanceNameUpdatePatchApiResponse,
      UpdateIdeV1InstancesInstanceNameUpdatePatchApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/update`,
        method: "PATCH",
        body: queryArg.deploymentUpdateRequest,
      }),
    }),
    deployEmulatorAppsV1InstancesInstanceNameEmulatorPost: build.mutation<
      DeployEmulatorAppsV1InstancesInstanceNameEmulatorPostApiResponse,
      DeployEmulatorAppsV1InstancesInstanceNameEmulatorPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/emulator`,
        method: "POST",
        body: queryArg.deploymentEmulatorCreateRequet,
      }),
    }),
    getDefaultEmulatorAppsV1InstancesInstanceNameEmulatorGet: build.query<
      GetDefaultEmulatorAppsV1InstancesInstanceNameEmulatorGetApiResponse,
      GetDefaultEmulatorAppsV1InstancesInstanceNameEmulatorGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/emulator`,
      }),
    }),
    updateEmulatorAppsV1InstancesEmulatorPut: build.mutation<
      UpdateEmulatorAppsV1InstancesEmulatorPutApiResponse,
      UpdateEmulatorAppsV1InstancesEmulatorPutApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/emulator`,
        method: "PUT",
        body: queryArg.deploymentEmulatorUpdateRequest,
      }),
    }),
    instanceFaceAuthV1InstancesInstanceNameFaceAuthPost: build.mutation<
      InstanceFaceAuthV1InstancesInstanceNameFaceAuthPostApiResponse,
      InstanceFaceAuthV1InstancesInstanceNameFaceAuthPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/face_auth`,
        method: "POST",
        body: queryArg.bodyInstanceFaceAuthV1InstancesInstanceNameFaceAuthPost,
      }),
    }),
    showWidgetV1InstancesInstanceNameWidgetGet: build.query<
      ShowWidgetV1InstancesInstanceNameWidgetGetApiResponse,
      ShowWidgetV1InstancesInstanceNameWidgetGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/widget`,
      }),
    }),
    createWidgetEventsV1InstancesInstanceNameWidgetPost: build.mutation<
      CreateWidgetEventsV1InstancesInstanceNameWidgetPostApiResponse,
      CreateWidgetEventsV1InstancesInstanceNameWidgetPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/widget`,
        method: "POST",
        body: queryArg.widgetEventCreateRequest,
      }),
    }),
    createTrackerEventsV1InstancesInstanceNameTrackerEventPost: build.mutation<
      CreateTrackerEventsV1InstancesInstanceNameTrackerEventPostApiResponse,
      CreateTrackerEventsV1InstancesInstanceNameTrackerEventPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/${queryArg.instanceName}/tracker_event`,
        method: "POST",
        body: queryArg.trackerEventCreateRequest,
      }),
    }),
    createAdminUsersSessionV1AdminUsersSessionPost: build.mutation<
      CreateAdminUsersSessionV1AdminUsersSessionPostApiResponse,
      CreateAdminUsersSessionV1AdminUsersSessionPostApiArg
    >({
      query: () => ({ url: `/v1/admin_users/session`, method: "POST" }),
    }),
    validateAdminUsersSessionV1AdminUsersSessionValidatePost: build.mutation<
      ValidateAdminUsersSessionV1AdminUsersSessionValidatePostApiResponse,
      ValidateAdminUsersSessionV1AdminUsersSessionValidatePostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin_users/session/validate`,
        method: "POST",
        headers: { authorization: queryArg.authorization },
      }),
    }),
    rootGet: build.query<RootGetApiResponse, RootGetApiArg>({
      query: () => ({ url: `/` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as ideApi };
export type GetCountryCodesV1CountryCodesGetApiResponse =
  /** status 200 Successful Response */ GetCountryCodesResponse;
export type GetCountryCodesV1CountryCodesGetApiArg = {
  page?: number;
  pageSize?: number;
  orderBy?: "created_at" | "country_code" | "region";
  order?: "asc" | "desc";
};
export type ListInstancesV1InstancesGetApiResponse =
  /** status 200 Successful Response */ InstanceListResponseRead;
export type ListInstancesV1InstancesGetApiArg = {
  page?: number;
  pageSize?: number;
  orderBy?:
    | "created_at"
    | "instance_name"
    | "provision_status"
    | "platform"
    | "region"
    | "capacity_partner__email"
    | "api_user__name"
    | "cluster__name";
  order?: "asc" | "desc";
};
export type SearchInstancesV1InstancesSearchPostApiResponse =
  /** status 200 Successful Response */ InstanceListResponseRead;
export type SearchInstancesV1InstancesSearchPostApiArg = {
  instanceFilterRequest: InstanceFilterRequest;
};
export type GetInstanceByNameV1InstancesInstanceNameGetApiResponse =
  /** status 200 Successful Response */ InstanceDetailRead;
export type GetInstanceByNameV1InstancesInstanceNameGetApiArg = {
  instanceName: string;
};
export type UpdateIdePipelineCompleteV1InstancesInstanceNamePatchApiResponse =
  /** status 200 Successful Response */ any;
export type UpdateIdePipelineCompleteV1InstancesInstanceNamePatchApiArg = {
  instanceName: string;
  tesseractPipelineUpdateRequest: TesseractPipelineUpdateRequest;
};
export type ListClustersV1ClustersGetApiResponse =
  /** status 200 Successful Response */ ClusterListResponse;
export type ListClustersV1ClustersGetApiArg = {
  page?: number;
  pageSize?: number;
  orderBy?: "created_at" | "name" | "region" | "region_code" | "cloud_provider";
  order?: "asc" | "desc";
};
export type CreateClusterV1ClustersPostApiResponse =
  /** status 200 Successful Response */ ClusterRead2;
export type CreateClusterV1ClustersPostApiArg = {
  clusterCreate: ClusterCreate;
};
export type GetClusterInfoV1ClustersClusterIdGetApiResponse =
  /** status 200 Successful Response */ ClusterRead2;
export type GetClusterInfoV1ClustersClusterIdGetApiArg = {
  clusterId: number;
};
export type EditClusterV1ClustersClusterIdPutApiResponse =
  /** status 200 Successful Response */ ClusterRead2;
export type EditClusterV1ClustersClusterIdPutApiArg = {
  clusterId: number;
  clusterEdit: ClusterEdit;
};
export type DeleteClusterV1ClustersClusterIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteClusterV1ClustersClusterIdDeleteApiArg = {
  clusterId: number;
};
export type GetPipelineByIdV1PipelinesIdGetApiResponse =
  /** status 200 Successful Response */ PipelineRead2;
export type GetPipelineByIdV1PipelinesIdGetApiArg = {
  id: number;
};
export type GetPipelineByExternalIdV1PipelinesExternalPipelineProviderExternalPipelineIdGetApiResponse =
  /** status 200 Successful Response */ PipelineRead2;
export type GetPipelineByExternalIdV1PipelinesExternalPipelineProviderExternalPipelineIdGetApiArg =
  {
    externalPipelineProvider: ExternalPipelineProvider;
    externalPipelineId: string;
  };
export type CreatePipelineV1PipelinesPostApiResponse =
  /** status 200 Successful Response */ PipelineRead2;
export type CreatePipelineV1PipelinesPostApiArg = {
  pipelineCreate: PipelineCreate;
};
export type UpdatePipelineStatusV1PipelinesIdStatusStatusPutApiResponse =
  /** status 200 Successful Response */ PipelineRead2;
export type UpdatePipelineStatusV1PipelinesIdStatusStatusPutApiArg = {
  id: number;
  status: PipelineStatus;
};
export type SearchPipelinesV1PipelinesSearchPostApiResponse =
  /** status 200 Successful Response */ PipelineListResponseRead;
export type SearchPipelinesV1PipelinesSearchPostApiArg = {
  pipelineFilterRequest: PipelineFilterRequest;
};
export type GetGitlabPipelinesV1GitlabPipelinesGetApiResponse =
  /** status 200 Successful Response */ GetGitlabPipelinesResponseRead;
export type GetGitlabPipelinesV1GitlabPipelinesGetApiArg = {
  page?: number;
  pageSize?: number;
  orderBy?:
    | "created_at"
    | "external_id"
    | "pipeline_action"
    | "execution_time"
    | "status";
  order?: "asc" | "desc";
};
export type SearchGitlabPipelinesEndpointV1GitlabPipelinesSearchPostApiResponse =
  /** status 200 Successful Response */ GetGitlabPipelinesResponseRead;
export type SearchGitlabPipelinesEndpointV1GitlabPipelinesSearchPostApiArg = {
  gitlabPipelineFilterRequest: GitlabPipelineFilterRequest;
};
export type GetGitlabPipelineByIdV1GitlabPipelinesIdGetApiResponse =
  /** status 200 Successful Response */ GitlabPipelineRead;
export type GetGitlabPipelineByIdV1GitlabPipelinesIdGetApiArg = {
  id: number;
};
export type ListAdminUsersV1AdminUsersGetApiResponse =
  /** status 200 Successful Response */ AdminUserListResponse;
export type ListAdminUsersV1AdminUsersGetApiArg = {
  page?: number;
  pageSize?: number;
  orderBy?: "created_at" | "email";
  order?: "asc" | "desc";
};
export type CreateAdminUserV1AdminUsersPostApiResponse =
  /** status 200 Successful Response */ AdminUser;
export type CreateAdminUserV1AdminUsersPostApiArg = {
  adminUserCreate: AdminUserCreateWrite;
};
export type GetAdminUserByIdV1AdminUsersAdminUserIdGetApiResponse =
  /** status 200 Successful Response */ AdminUser;
export type GetAdminUserByIdV1AdminUsersAdminUserIdGetApiArg = {
  adminUserId: number;
};
export type EditAdminUserV1AdminUsersAdminUserIdPutApiResponse =
  /** status 200 Successful Response */ AdminUser;
export type EditAdminUserV1AdminUsersAdminUserIdPutApiArg = {
  adminUserId: number;
  adminUserCreate: AdminUserCreateWrite;
};
export type DeleteAdminUserV1AdminUsersAdminUserIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteAdminUserV1AdminUsersAdminUserIdDeleteApiArg = {
  adminUserId: number;
};
export type SearchAdminUsersEndpointV1AdminUsersSearchPostApiResponse =
  /** status 200 Successful Response */ AdminUserListResponse;
export type SearchAdminUsersEndpointV1AdminUsersSearchPostApiArg = {
  adminUserFilterRequest: AdminUserFilterRequest;
};
export type GetApiUsersV1ApiUsersGetApiResponse =
  /** status 200 Successful Response */ GetApiUserResponse;
export type GetApiUsersV1ApiUsersGetApiArg = {
  page?: number;
  pageSize?: number;
  orderBy?: "created_at" | "name";
  order?: "asc" | "desc";
};
export type EditApiUserV1ApiUsersApiUserIdPutApiResponse =
  /** status 200 Successful Response */ ApiUser;
export type EditApiUserV1ApiUsersApiUserIdPutApiArg = {
  apiUserId: number;
  apiUserUpdate: ApiUserUpdate;
};
export type DeployIdeV1InstancesDeployPostApiResponse =
  /** status 200 Successful Response */ any;
export type DeployIdeV1InstancesDeployPostApiArg = {
  deploymentCreateRequest: DeploymentCreateRequest;
};
export type GetIdeDetailsV1InstancesInstanceNameDetailsGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetIdeDetailsV1InstancesInstanceNameDetailsGetApiArg = {
  instanceName: string;
};
export type GetIdeDetailsWithTokenV1InstancesDetailsAccessTokenGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetIdeDetailsWithTokenV1InstancesDetailsAccessTokenGetApiArg = {
  accessToken: string;
};
export type ResetIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionPutApiResponse =
  /** status 200 Successful Response */ any;
export type ResetIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionPutApiArg =
  {
    instanceName: string;
  };
export type DeleteIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionDeleteApiArg =
  {
    instanceName: string;
  };
export type DeallocateIdeV1InstancesInstanceNameDeallocatePutApiResponse =
  /** status 200 Successful Response */ any;
export type DeallocateIdeV1InstancesInstanceNameDeallocatePutApiArg = {
  instanceName: string;
  deploymentDeallocateRequet: DeploymentDeallocateRequet;
};
export type UpdateIdeV1InstancesInstanceNameUpdatePatchApiResponse =
  /** status 200 Successful Response */ any;
export type UpdateIdeV1InstancesInstanceNameUpdatePatchApiArg = {
  instanceName: string;
  deploymentUpdateRequest: DeploymentUpdateRequest;
};
export type DeployEmulatorAppsV1InstancesInstanceNameEmulatorPostApiResponse =
  /** status 200 Successful Response */ any;
export type DeployEmulatorAppsV1InstancesInstanceNameEmulatorPostApiArg = {
  instanceName: string;
  deploymentEmulatorCreateRequet: DeploymentEmulatorCreateRequet;
};
export type GetDefaultEmulatorAppsV1InstancesInstanceNameEmulatorGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetDefaultEmulatorAppsV1InstancesInstanceNameEmulatorGetApiArg = {
  instanceName: string;
};
export type UpdateEmulatorAppsV1InstancesEmulatorPutApiResponse =
  /** status 200 Successful Response */ any;
export type UpdateEmulatorAppsV1InstancesEmulatorPutApiArg = {
  deploymentEmulatorUpdateRequest: DeploymentEmulatorUpdateRequest;
};
export type InstanceFaceAuthV1InstancesInstanceNameFaceAuthPostApiResponse =
  /** status 200 Successful Response */ any;
export type InstanceFaceAuthV1InstancesInstanceNameFaceAuthPostApiArg = {
  instanceName: string;
  bodyInstanceFaceAuthV1InstancesInstanceNameFaceAuthPost: BodyInstanceFaceAuthV1InstancesInstanceNameFaceAuthPost;
};
export type ShowWidgetV1InstancesInstanceNameWidgetGetApiResponse =
  /** status 200 Successful Response */ any;
export type ShowWidgetV1InstancesInstanceNameWidgetGetApiArg = {
  instanceName: string;
};
export type CreateWidgetEventsV1InstancesInstanceNameWidgetPostApiResponse =
  /** status 200 Successful Response */ any;
export type CreateWidgetEventsV1InstancesInstanceNameWidgetPostApiArg = {
  instanceName: string;
  widgetEventCreateRequest: WidgetEventCreateRequest;
};
export type CreateTrackerEventsV1InstancesInstanceNameTrackerEventPostApiResponse =
  /** status 200 Successful Response */ any;
export type CreateTrackerEventsV1InstancesInstanceNameTrackerEventPostApiArg = {
  instanceName: string;
  trackerEventCreateRequest: TrackerEventCreateRequest;
};
export type CreateAdminUsersSessionV1AdminUsersSessionPostApiResponse =
  /** status 200 Successful Response */ any;
export type CreateAdminUsersSessionV1AdminUsersSessionPostApiArg = void;
export type ValidateAdminUsersSessionV1AdminUsersSessionValidatePostApiResponse =
  /** status 200 Successful Response */ any;
export type ValidateAdminUsersSessionV1AdminUsersSessionValidatePostApiArg = {
  authorization?: string;
};
export type RootGetApiResponse = /** status 200 Successful Response */ any;
export type RootGetApiArg = void;
export type CountryCodeBase = {
  id: number;
  country_code: string | null;
  region: string | null;
  country: string | null;
};
export type PaginationResponse = {
  page?: number;
  page_size?: number;
  total_results: number;
};
export type GetCountryCodesResponse = {
  data: CountryCodeBase[] | null;
  pagination: PaginationResponse;
};
export type ValidationErrorModel = {
  detail: string[];
};
export type ClusterRead = {
  region: string;
  region_code: string;
  cloud_provider: string;
  name?: string;
  enable_cloudflare_dns?: boolean;
  id: number;
};
export type ApiUserRead = {
  name: string;
  default_cloud_provider?: string | null;
  enable_vpn?: boolean | null;
  afk_worker?: boolean | null;
  enable_slack_alerts?: boolean | null;
  enable_failed_creation_alerts?: boolean | null;
  use_liveness_face_auth_api_user_level?: boolean | null;
  allow_delete_endpoint?: boolean | null;
  enable_tesseract?: boolean | null;
  id: number;
};
export type CapacityPartnerRead = {
  email: string;
  email_alias: string | null;
  country?: string | null;
  afk_worker?: boolean | null;
  face_auth?: boolean | null;
  id: number;
};
export type ExternalPipelineProvider = "gitlab" | "tesseract";
export type PipelineStatus =
  | "scheduled"
  | "running"
  | "paused"
  | "completed"
  | "failed"
  | "canceled";
export type PipelineRead = {
  name: string;
  pipeline_type?: string | null;
  external_pipeline_id: string;
  external_pipeline_provider: ExternalPipelineProvider;
  status?: PipelineStatus;
  id: number;
  started_at: string | null;
  finished_at: string | null;
};
export type PipelineReadRead = {
  name: string;
  pipeline_type?: string | null;
  external_pipeline_id: string;
  external_pipeline_provider: ExternalPipelineProvider;
  status?: PipelineStatus;
  id: number;
  started_at: string | null;
  finished_at: string | null;
  execution_time: number | null;
  url: string;
};
export type MobileEmulatorAppRead = {
  platform: number;
  file_path?: string | null;
  timeout?: number | null;
  id: number;
};
export type InstanceRead = {
  created_at?: string;
  updated_at?: string | null;
  instance_name?: string;
  provision_status?: string;
  project_id: string;
  access_token?: string;
  platform: string;
  region: string;
  archive_id?: string | null;
  completed_manual_triggers?: string;
  cloud_provider: string;
  delete_reason?: string | null;
  spin_up_time?: number | null;
  vpn_port: number | null;
  terminated?: boolean;
  webcam?: boolean | null;
  skip_emulators: boolean | null;
  is_deallocated?: boolean;
  fail_creation_alert_sent: boolean | null;
  id: number;
  cluster: ClusterRead | null;
  api_user: ApiUserRead | null;
  capacity_partner: CapacityPartnerRead | null;
  pipeline: PipelineRead | null;
  mobile_emulator_app_android: MobileEmulatorAppRead | null;
  mobile_emulator_app_ios: MobileEmulatorAppRead | null;
};
export type InstanceReadRead = {
  created_at?: string;
  updated_at?: string | null;
  instance_name?: string;
  provision_status?: string;
  project_id: string;
  access_token?: string;
  platform: string;
  region: string;
  archive_id?: string | null;
  completed_manual_triggers?: string;
  cloud_provider: string;
  delete_reason?: string | null;
  spin_up_time?: number | null;
  vpn_port: number | null;
  terminated?: boolean;
  webcam?: boolean | null;
  skip_emulators: boolean | null;
  is_deallocated?: boolean;
  fail_creation_alert_sent: boolean | null;
  id: number;
  cluster: ClusterRead | null;
  api_user: ApiUserRead | null;
  capacity_partner: CapacityPartnerRead | null;
  pipeline: PipelineReadRead | null;
  mobile_emulator_app_android: MobileEmulatorAppRead | null;
  mobile_emulator_app_ios: MobileEmulatorAppRead | null;
  ide_direct_url: string;
  ide_url: string;
};
export type InstanceListResponse = {
  data: InstanceRead[] | null;
  pagination: PaginationResponse;
};
export type InstanceListResponseRead = {
  data: InstanceReadRead[] | null;
  pagination: PaginationResponse;
};
export type PaginationRequest = {
  page?: number;
  page_size?: number;
};
export type StringFilterComparision = {
  op: "eq" | "ne" | "contains" | "not_contains";
  value: string;
};
export type StringFilterContains = {
  op: "in" | "not_in";
  value: string[];
};
export type NumberFilterInclusionRange = {
  op: "between" | "not_between";
  value: (number | number)[];
};
export type NumberFilterComparision = {
  op: "eq" | "ne" | "lt" | "gt" | "le" | "ge";
  value: number | number;
};
export type NumberFilterInclusion = {
  op: "in" | "not_in";
  value: (number | number)[];
};
export type DateTimeFilterInclusion = {
  op: "between" | "not_between";
  value: string[];
};
export type DateTimeFilterComparision = {
  op: "eq" | "ne" | "lt" | "gt" | "le" | "ge";
  value: string;
};
export type BoolFilter = {
  op: "eq" | "ne";
  value: boolean;
};
export type InstanceFilterFields = {
  instance_name?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  project_id?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  platform?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  cluster_id?:
    | (
        | ({
            op: "between";
          } & NumberFilterInclusionRange)
        | ({
            op: "eq";
          } & NumberFilterComparision)
        | ({
            op: "ge";
          } & NumberFilterComparision)
        | ({
            op: "gt";
          } & NumberFilterComparision)
        | ({
            op: "in";
          } & NumberFilterInclusion)
        | ({
            op: "le";
          } & NumberFilterComparision)
        | ({
            op: "lt";
          } & NumberFilterComparision)
        | ({
            op: "ne";
          } & NumberFilterComparision)
        | ({
            op: "not_between";
          } & NumberFilterInclusionRange)
        | ({
            op: "not_in";
          } & NumberFilterInclusion)
      )
    | null;
  region?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  cloud_provider?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  created_at?:
    | (
        | ({
            op: "between";
          } & DateTimeFilterInclusion)
        | ({
            op: "eq";
          } & DateTimeFilterComparision)
        | ({
            op: "ge";
          } & DateTimeFilterComparision)
        | ({
            op: "gt";
          } & DateTimeFilterComparision)
        | ({
            op: "le";
          } & DateTimeFilterComparision)
        | ({
            op: "lt";
          } & DateTimeFilterComparision)
        | ({
            op: "ne";
          } & DateTimeFilterComparision)
        | ({
            op: "not_between";
          } & DateTimeFilterInclusion)
      )
    | null;
  provision_status?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  is_deallocated?: BoolFilter | null;
  terminated?: BoolFilter | null;
  capacity_partner__email?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  api_user__name?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
};
export type InstanceFilterSort = {
  created_at?: ("asc" | "desc") | null;
  instance_name?: ("asc" | "desc") | null;
  provision_status?: ("asc" | "desc") | null;
  platform?: ("asc" | "desc") | null;
  region?: ("asc" | "desc") | null;
  capacity_partner__email?: ("asc" | "desc") | null;
  api_user__name?: ("asc" | "desc") | null;
  cluster__name?: ("asc" | "desc") | null;
};
export type InstanceFilterRequest = {
  pagination: PaginationRequest;
  search?: string | null;
  filters?: InstanceFilterFields | null;
  sort?: InstanceFilterSort | null;
};
export type CapacityPartnerDetail = {
  email: string;
  email_alias: string | null;
  country?: string | null;
  afk_worker?: boolean | null;
  face_auth?: boolean | null;
  id: number;
  rsa_public: string;
  rsa_private: string;
};
export type MobileEmulatorAppDetail = {
  platform: number;
  file_path?: string | null;
  timeout?: number | null;
  id: number;
  public_key: string;
};
export type InstanceDetail = {
  created_at?: string;
  updated_at?: string | null;
  instance_name?: string;
  provision_status?: string;
  project_id: string;
  access_token?: string;
  platform: string;
  region: string;
  archive_id?: string | null;
  completed_manual_triggers?: string;
  cloud_provider: string;
  delete_reason?: string | null;
  spin_up_time?: number | null;
  vpn_port: number | null;
  terminated?: boolean;
  webcam?: boolean | null;
  skip_emulators: boolean | null;
  is_deallocated?: boolean;
  fail_creation_alert_sent: boolean | null;
  id: number;
  cluster: ClusterRead | null;
  api_user: ApiUserRead | null;
  capacity_partner: CapacityPartnerDetail;
  pipeline: PipelineRead | null;
  mobile_emulator_app_android: MobileEmulatorAppDetail | null;
  mobile_emulator_app_ios: MobileEmulatorAppDetail | null;
  ingress_fqdn: string;
  encrypted_ide_password: string;
};
export type InstanceDetailRead = {
  created_at?: string;
  updated_at?: string | null;
  instance_name?: string;
  provision_status?: string;
  project_id: string;
  access_token?: string;
  platform: string;
  region: string;
  archive_id?: string | null;
  completed_manual_triggers?: string;
  cloud_provider: string;
  delete_reason?: string | null;
  spin_up_time?: number | null;
  vpn_port: number | null;
  terminated?: boolean;
  webcam?: boolean | null;
  skip_emulators: boolean | null;
  is_deallocated?: boolean;
  fail_creation_alert_sent: boolean | null;
  id: number;
  cluster: ClusterRead | null;
  api_user: ApiUserRead | null;
  capacity_partner: CapacityPartnerDetail;
  pipeline: PipelineReadRead | null;
  mobile_emulator_app_android: MobileEmulatorAppDetail | null;
  mobile_emulator_app_ios: MobileEmulatorAppDetail | null;
  ingress_fqdn: string;
  encrypted_ide_password: string;
  ide_direct_url: string;
  ide_url: string;
};
export type NotFoundErrorModel = {
  detail: string;
};
export type TesseractPipelineUpdateRequest = {
  pipeline_id: string | number;
};
export type ClusterListModel = {
  region: string;
  region_code: string;
  cloud_provider: string;
  name?: string;
  enable_cloudflare_dns?: boolean;
  id: number;
  instance_count: number;
  fqdn: string;
};
export type ClusterListResponse = {
  data: ClusterListModel[] | null;
  pagination: PaginationResponse;
};
export type Cluster = {
  region: string;
  region_code: string;
  cloud_provider: string;
  name?: string;
  enable_cloudflare_dns?: boolean;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
};
export type ClusterRead2 = {
  region: string;
  region_code: string;
  cloud_provider: string;
  name?: string;
  enable_cloudflare_dns?: boolean;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
  fqdn: string;
};
export type CloudProvider = "az" | "aws";
export type ClusterCreate = {
  region: string;
  region_code: string;
  cloud_provider: CloudProvider;
  name?: string;
  enable_cloudflare_dns?: boolean;
};
export type ClusterEdit = {
  region?: string | null;
  region_code?: string | null;
  cloud_provider?: CloudProvider | null;
  name?: string | null;
  enable_cloudflare_dns?: boolean | null;
};
export type Pipeline = {
  name: string;
  pipeline_type?: string | null;
  external_pipeline_id: string;
  external_pipeline_provider: ExternalPipelineProvider;
  status?: PipelineStatus;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
  started_at?: string | null;
  finished_at?: string | null;
};
export type PipelineRead2 = {
  name: string;
  pipeline_type?: string | null;
  external_pipeline_id: string;
  external_pipeline_provider: ExternalPipelineProvider;
  status?: PipelineStatus;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
  started_at?: string | null;
  finished_at?: string | null;
  execution_time: number | null;
  url: string;
  instance_id: number | null;
  instance_name: string | null;
};
export type PipelineCreate = {
  name: string;
  pipeline_type?: string | null;
  external_pipeline_id: string;
  external_pipeline_provider: ExternalPipelineProvider;
  status?: PipelineStatus;
  instance_name: string;
};
export type PipelineListResponse = {
  data: Pipeline[] | null;
  pagination: PaginationResponse;
};
export type PipelineListResponseRead = {
  data: PipelineRead2[] | null;
  pagination: PaginationResponse;
};
export type PipelineFilterFields = {
  name?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  pipeline_type?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  external_pipeline_id?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  external_pipeline_provider?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  status?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  created_at?:
    | (
        | ({
            op: "between";
          } & DateTimeFilterInclusion)
        | ({
            op: "eq";
          } & DateTimeFilterComparision)
        | ({
            op: "ge";
          } & DateTimeFilterComparision)
        | ({
            op: "gt";
          } & DateTimeFilterComparision)
        | ({
            op: "le";
          } & DateTimeFilterComparision)
        | ({
            op: "lt";
          } & DateTimeFilterComparision)
        | ({
            op: "ne";
          } & DateTimeFilterComparision)
        | ({
            op: "not_between";
          } & DateTimeFilterInclusion)
      )
    | null;
  started_at?:
    | (
        | ({
            op: "between";
          } & DateTimeFilterInclusion)
        | ({
            op: "eq";
          } & DateTimeFilterComparision)
        | ({
            op: "ge";
          } & DateTimeFilterComparision)
        | ({
            op: "gt";
          } & DateTimeFilterComparision)
        | ({
            op: "le";
          } & DateTimeFilterComparision)
        | ({
            op: "lt";
          } & DateTimeFilterComparision)
        | ({
            op: "ne";
          } & DateTimeFilterComparision)
        | ({
            op: "not_between";
          } & DateTimeFilterInclusion)
      )
    | null;
  finished_at?:
    | (
        | ({
            op: "between";
          } & DateTimeFilterInclusion)
        | ({
            op: "eq";
          } & DateTimeFilterComparision)
        | ({
            op: "ge";
          } & DateTimeFilterComparision)
        | ({
            op: "gt";
          } & DateTimeFilterComparision)
        | ({
            op: "le";
          } & DateTimeFilterComparision)
        | ({
            op: "lt";
          } & DateTimeFilterComparision)
        | ({
            op: "ne";
          } & DateTimeFilterComparision)
        | ({
            op: "not_between";
          } & DateTimeFilterInclusion)
      )
    | null;
  instance__instance_name?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
};
export type PipelineFilterSort = {
  created_at?: ("asc" | "desc") | null;
  started_at?: ("asc" | "desc") | null;
  finished_at?: ("asc" | "desc") | null;
  name?: ("asc" | "desc") | null;
  status?: ("asc" | "desc") | null;
};
export type PipelineFilterRequest = {
  pagination: PaginationRequest;
  filters?: PipelineFilterFields | null;
  sort?: PipelineFilterSort | null;
};
export type InstanceBase = {
  instance_name?: string;
  provision_status?: string;
  project_id: string;
  access_token?: string;
  platform: string;
  region: string;
  archive_id?: string | null;
  completed_manual_triggers?: string;
  cloud_provider: string;
  delete_reason?: string | null;
  spin_up_time?: number | null;
  vpn_port: number | null;
  terminated?: boolean;
  webcam?: boolean | null;
  skip_emulators: boolean | null;
  is_deallocated?: boolean;
  fail_creation_alert_sent: boolean | null;
};
export type GitlabPipelineResponseModel = {
  external_id: number;
  status: string;
  total_jobs?: number | null;
  percentage?: number | null;
  execution_time?: number | null;
  pipeline_action?: string | null;
  id: number;
  instance: InstanceBase | null;
};
export type GitlabPipelineResponseModelRead = {
  external_id: number;
  status: string;
  total_jobs?: number | null;
  percentage?: number | null;
  execution_time?: number | null;
  pipeline_action?: string | null;
  id: number;
  instance: InstanceBase | null;
  url: string;
};
export type GetGitlabPipelinesResponse = {
  data: GitlabPipelineResponseModel[] | null;
  pagination: PaginationResponse;
};
export type GetGitlabPipelinesResponseRead = {
  data: GitlabPipelineResponseModelRead[] | null;
  pagination: PaginationResponse;
};
export type GitlabPipelineFilterFields = {
  instance_id?:
    | (
        | ({
            op: "between";
          } & NumberFilterInclusionRange)
        | ({
            op: "eq";
          } & NumberFilterComparision)
        | ({
            op: "ge";
          } & NumberFilterComparision)
        | ({
            op: "gt";
          } & NumberFilterComparision)
        | ({
            op: "in";
          } & NumberFilterInclusion)
        | ({
            op: "le";
          } & NumberFilterComparision)
        | ({
            op: "lt";
          } & NumberFilterComparision)
        | ({
            op: "ne";
          } & NumberFilterComparision)
        | ({
            op: "not_between";
          } & NumberFilterInclusionRange)
        | ({
            op: "not_in";
          } & NumberFilterInclusion)
      )
    | null;
  external_id?:
    | (
        | ({
            op: "between";
          } & NumberFilterInclusionRange)
        | ({
            op: "eq";
          } & NumberFilterComparision)
        | ({
            op: "ge";
          } & NumberFilterComparision)
        | ({
            op: "gt";
          } & NumberFilterComparision)
        | ({
            op: "in";
          } & NumberFilterInclusion)
        | ({
            op: "le";
          } & NumberFilterComparision)
        | ({
            op: "lt";
          } & NumberFilterComparision)
        | ({
            op: "ne";
          } & NumberFilterComparision)
        | ({
            op: "not_between";
          } & NumberFilterInclusionRange)
        | ({
            op: "not_in";
          } & NumberFilterInclusion)
      )
    | null;
  status?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  created_at?:
    | (
        | ({
            op: "between";
          } & DateTimeFilterInclusion)
        | ({
            op: "eq";
          } & DateTimeFilterComparision)
        | ({
            op: "ge";
          } & DateTimeFilterComparision)
        | ({
            op: "gt";
          } & DateTimeFilterComparision)
        | ({
            op: "le";
          } & DateTimeFilterComparision)
        | ({
            op: "lt";
          } & DateTimeFilterComparision)
        | ({
            op: "ne";
          } & DateTimeFilterComparision)
        | ({
            op: "not_between";
          } & DateTimeFilterInclusion)
      )
    | null;
  execution_time?:
    | (
        | ({
            op: "between";
          } & NumberFilterInclusionRange)
        | ({
            op: "eq";
          } & NumberFilterComparision)
        | ({
            op: "ge";
          } & NumberFilterComparision)
        | ({
            op: "gt";
          } & NumberFilterComparision)
        | ({
            op: "in";
          } & NumberFilterInclusion)
        | ({
            op: "le";
          } & NumberFilterComparision)
        | ({
            op: "lt";
          } & NumberFilterComparision)
        | ({
            op: "ne";
          } & NumberFilterComparision)
        | ({
            op: "not_between";
          } & NumberFilterInclusionRange)
        | ({
            op: "not_in";
          } & NumberFilterInclusion)
      )
    | null;
  instance__instance_name?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  pipeline_action?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
};
export type GitlabPipelineFilterSort = {
  created_at?: ("asc" | "desc") | null;
  external_id?: ("asc" | "desc") | null;
  pipeline_action?: ("asc" | "desc") | null;
  execution_time?: ("asc" | "desc") | null;
  status?: ("asc" | "desc") | null;
};
export type GitlabPipelineFilterRequest = {
  pagination: PaginationRequest;
  filters?: GitlabPipelineFilterFields | null;
  sort?: GitlabPipelineFilterSort | null;
};
export type GitlabPipeline = {
  instance_id: number;
  external_id: number;
  status: string;
  total_jobs?: number | null;
  percentage?: number | null;
  execution_time?: number | null;
  pipeline_action?: string | null;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
};
export type GitlabPipelineRead = {
  instance_id: number;
  external_id: number;
  status: string;
  total_jobs?: number | null;
  percentage?: number | null;
  execution_time?: number | null;
  pipeline_action?: string | null;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
  url: string;
};
export type AdminUserRead = {
  created_at?: string;
  updated_at?: string | null;
  email: string;
  id: number;
};
export type AdminUserListResponse = {
  data: AdminUserRead[] | null;
  pagination: PaginationResponse;
};
export type AdminUser = {
  reset_password_token?: string | null;
  reset_password_sent_at?: string | null;
  remember_created_at?: string | null;
  confirmation_token?: string | null;
  confirmed_at?: string | null;
  confirmation_sent_at?: string | null;
  deleted_at?: string | null;
  created_at?: string;
  updated_at?: string | null;
  email: string;
  id?: number | null;
};
export type AdminUserCreate = {
  email: string;
};
export type AdminUserCreateWrite = {
  email: string;
  password: string;
};
export type AdminUserFilterFields = {
  email?:
    | (
        | ({
            op: "contains";
          } & StringFilterComparision)
        | ({
            op: "eq";
          } & StringFilterComparision)
        | ({
            op: "in";
          } & StringFilterContains)
        | ({
            op: "ne";
          } & StringFilterComparision)
        | ({
            op: "not_contains";
          } & StringFilterComparision)
        | ({
            op: "not_in";
          } & StringFilterContains)
      )
    | null;
  created_at?:
    | (
        | ({
            op: "between";
          } & DateTimeFilterInclusion)
        | ({
            op: "eq";
          } & DateTimeFilterComparision)
        | ({
            op: "ge";
          } & DateTimeFilterComparision)
        | ({
            op: "gt";
          } & DateTimeFilterComparision)
        | ({
            op: "le";
          } & DateTimeFilterComparision)
        | ({
            op: "lt";
          } & DateTimeFilterComparision)
        | ({
            op: "ne";
          } & DateTimeFilterComparision)
        | ({
            op: "not_between";
          } & DateTimeFilterInclusion)
      )
    | null;
};
export type AdminUserFilterSort = {
  created_at?: ("asc" | "desc") | null;
  email?: ("asc" | "desc") | null;
};
export type AdminUserFilterRequest = {
  pagination: PaginationRequest;
  filters?: AdminUserFilterFields | null;
  sort?: AdminUserFilterSort | null;
};
export type GetApiUserResponse = {
  data: ApiUserRead[] | null;
  pagination: PaginationResponse;
};
export type ApiUser = {
  name: string;
  default_cloud_provider?: string | null;
  enable_vpn?: boolean | null;
  afk_worker?: boolean | null;
  enable_slack_alerts?: boolean | null;
  enable_failed_creation_alerts?: boolean | null;
  use_liveness_face_auth_api_user_level?: boolean | null;
  allow_delete_endpoint?: boolean | null;
  enable_tesseract?: boolean | null;
  clusters: string[];
  token: string;
  time_from_afk?: number | null;
  created_at?: string;
  updated_at?: string | null;
  id?: number | null;
};
export type ApiUserUpdate = {
  name?: string | null;
  default_cloud_provider?: string | null;
  enable_vpn?: boolean | null;
  afk_worker?: boolean | null;
  enable_slack_alerts?: boolean | null;
  enable_failed_creation_alerts?: boolean | null;
  use_liveness_face_auth_api_user_level?: boolean | null;
  allow_delete_endpoint?: boolean | null;
  enable_tesseract?: boolean | null;
  clusters?: string[] | null;
  token?: string | null;
  time_from_afk?: number | null;
};
export type InstanceDeploymentCreateRequest = {
  email: string;
  platform: string;
  region?: string | null;
  project_id: string | number;
  skip_emulators?: boolean | null;
  /** This field has been deprecated, please use skip_emulators */
  skip_emulator?: boolean | null;
  skip_callback?: boolean | null;
  country_code?: string | null;
  android_app_location?: string | null;
  ios_app_location?: string | null;
  cloud_provider?: string | null;
  webcam?: boolean | null;
};
export type DeploymentCreateRequest = {
  ide: InstanceDeploymentCreateRequest;
};
export type DeploymentDeallocateRequet = {
  delete_reason: string;
  deallocate_expert?: boolean | null;
};
export type GitlabJobParams = {
  id: number;
  name: string;
  stage: string;
  pipeline: number;
};
export type GitlabPipelineParams = {
  id: number;
  status: string;
  action: string;
  notify_user: string;
  skip_callback: string;
};
export type DeploymentUpdateRequest = {
  job?: GitlabJobParams | null;
  pipeline?: GitlabPipelineParams | null;
};
export type DeploymentEmulatorCreateRequet = {
  android_app_location?: string | null;
  ios_app_location?: string | null;
};
export type DeploymentEmulatorUpdateRequest = {
  project_id: string;
  email: string;
  platform: string;
  app_location: string;
};
export type BodyInstanceFaceAuthV1InstancesInstanceNameFaceAuthPost = {
  target_image: Blob;
};
export type WidgetEventCreateRequest = {
  widget_id: number;
  rating: number;
  question: string;
  answer: string | null;
  event_name: string;
};
export type TrackerEventCreateRequest = {
  event_name: string;
  tracker_story_id: string | number;
  afk?: boolean | null;
  timestamp: string;
};
export const {
  useGetCountryCodesV1CountryCodesGetQuery,
  useListInstancesV1InstancesGetQuery,
  useSearchInstancesV1InstancesSearchPostMutation,
  useGetInstanceByNameV1InstancesInstanceNameGetQuery,
  useUpdateIdePipelineCompleteV1InstancesInstanceNamePatchMutation,
  useListClustersV1ClustersGetQuery,
  useCreateClusterV1ClustersPostMutation,
  useGetClusterInfoV1ClustersClusterIdGetQuery,
  useEditClusterV1ClustersClusterIdPutMutation,
  useDeleteClusterV1ClustersClusterIdDeleteMutation,
  useGetPipelineByIdV1PipelinesIdGetQuery,
  useGetPipelineByExternalIdV1PipelinesExternalPipelineProviderExternalPipelineIdGetQuery,
  useCreatePipelineV1PipelinesPostMutation,
  useUpdatePipelineStatusV1PipelinesIdStatusStatusPutMutation,
  useSearchPipelinesV1PipelinesSearchPostMutation,
  useGetGitlabPipelinesV1GitlabPipelinesGetQuery,
  useSearchGitlabPipelinesEndpointV1GitlabPipelinesSearchPostMutation,
  useGetGitlabPipelineByIdV1GitlabPipelinesIdGetQuery,
  useListAdminUsersV1AdminUsersGetQuery,
  useCreateAdminUserV1AdminUsersPostMutation,
  useGetAdminUserByIdV1AdminUsersAdminUserIdGetQuery,
  useEditAdminUserV1AdminUsersAdminUserIdPutMutation,
  useDeleteAdminUserV1AdminUsersAdminUserIdDeleteMutation,
  useSearchAdminUsersEndpointV1AdminUsersSearchPostMutation,
  useGetApiUsersV1ApiUsersGetQuery,
  useEditApiUserV1ApiUsersApiUserIdPutMutation,
  useDeployIdeV1InstancesDeployPostMutation,
  useGetIdeDetailsV1InstancesInstanceNameDetailsGetQuery,
  useGetIdeDetailsWithTokenV1InstancesDetailsAccessTokenGetQuery,
  useResetIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionPutMutation,
  useDeleteIdeGuacamoleConnectionV1InstancesInstanceNameGuacamoleConnectionDeleteMutation,
  useDeallocateIdeV1InstancesInstanceNameDeallocatePutMutation,
  useUpdateIdeV1InstancesInstanceNameUpdatePatchMutation,
  useDeployEmulatorAppsV1InstancesInstanceNameEmulatorPostMutation,
  useGetDefaultEmulatorAppsV1InstancesInstanceNameEmulatorGetQuery,
  useUpdateEmulatorAppsV1InstancesEmulatorPutMutation,
  useInstanceFaceAuthV1InstancesInstanceNameFaceAuthPostMutation,
  useShowWidgetV1InstancesInstanceNameWidgetGetQuery,
  useCreateWidgetEventsV1InstancesInstanceNameWidgetPostMutation,
  useCreateTrackerEventsV1InstancesInstanceNameTrackerEventPostMutation,
  useCreateAdminUsersSessionV1AdminUsersSessionPostMutation,
  useValidateAdminUsersSessionV1AdminUsersSessionValidatePostMutation,
  useRootGetQuery,
} = injectedRtkApi;
