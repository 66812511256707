import React from "react";
import { Table } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import cn from "classnames";

import {
  InstanceReadRead,
  InstanceFilterSort,
} from "../../app/api/ide/ideService";
import ProvisionStatus from "../ProvisionStatus";
import { BundleLink } from "../BundleLink";
import { YesNo } from "../YesNo";
import { Timestamp } from "../Timestamp/Timestamp";
import { Sortable } from "../Table/TableSort";
import CloudProvider from "../CloudProvider";

export type ColumnId =
  | "id"
  | "created_at"
  | "ide_direct_url"
  | "ide_url"
  | "instance_name"
  | "region"
  | "pipeline"
  | "capacity_partner_email"
  | "mobile_emulator_app_android"
  | "mobile_emulator_app_ios"
  | "provision_status"
  | "is_deallocated"
  | "cluster_name"
  | "region_code"
  | "platform"
  | "api_user"
  | "cloud_provider";

export const allColumns: ColumnId[] = [
  "id",
  "instance_name",
  "ide_url",
  "ide_direct_url",
  "created_at",
  "provision_status",
  "pipeline",
  "capacity_partner_email",
  "cluster_name",
  "region",
  "region_code",
  "cloud_provider",
  "api_user",
  "platform",
  "is_deallocated",
  "mobile_emulator_app_ios",
  "mobile_emulator_app_android",
];

type ColumnConfig = {
  renderCell: (instance: InstanceReadRead, idx: number) => React.ReactNode;
  renderHeader: (onSort: (InstanceFilterSort) => void) => React.ReactNode;
};

type ColumnMap = {
  [columnId in ColumnId]: ColumnConfig;
};

export function InstanceTable(props: {
  instances: InstanceReadRead[];
  activeColumns: ColumnId[];
  onSort: (InstanceFilterSort) => void;
  activeSort: InstanceFilterSort;
  baseTabIndex?: number;
  isFetching?: boolean;
}) {
  const baseTabIndex = props.baseTabIndex ? props.baseTabIndex : 0;

  const columnMap: ColumnMap = {
    id: {
      renderHeader: () => "Id",
      renderCell: (instance) => instance.id,
    },
    instance_name: {
      renderHeader: () => "Instance name",
      renderCell: (instance, idx) => (
        <Link
          to={`/instance/${instance.instance_name}`}
          tabIndex={baseTabIndex + idx}
          target="_blank"
        >
          {instance.instance_name}
        </Link>
      ),
    },
    ide_url: {
      renderHeader: () => "Ide link",
      renderCell: (instance) => (
        <>
          {instance.ide_url && (
            <Link
              target="_blank"
              to={instance.ide_url}
              style={{ whiteSpace: "nowrap" }}
            >
              Open IDE
            </Link>
          )}
        </>
      ),
    },
    ide_direct_url: {
      renderHeader: () => "Direct link",
      renderCell: (instance) => (
        <>
          {instance.ide_direct_url && (
            <Link target="_blank" to={instance.ide_direct_url}>
              Direct link
            </Link>
          )}
        </>
      ),
    },
    region: {
      renderHeader: () => (
        <Sortable
          field="region"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Region
        </Sortable>
      ),
      renderCell: (instance) => instance.region,
    },
    region_code: {
      renderHeader: () => "Region code",
      renderCell: (instance) => instance.cluster?.region_code,
    },
    platform: {
      renderHeader: () => (
        <Sortable
          field="platform"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Platform
        </Sortable>
      ),
      renderCell: (instance) => instance.platform,
    },
    api_user: {
      renderHeader: () => (
        <Sortable
          field="api_user__name"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Api user
        </Sortable>
      ),
      renderCell: (instance) => instance.api_user?.name,
    },
    cluster_name: {
      renderHeader: () => (
        <Sortable
          field="cluster__name"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Cluster
        </Sortable>
      ),
      renderCell: (instance) => (
        <>
          {instance.cluster && (
            <Link to={`/cluster/${instance.cluster.id}`}>
              {instance.cluster.name}
            </Link>
          )}
        </>
      ),
    },
    pipeline: {
      renderHeader: () => "Pipeline",
      renderCell: (instance) => (
        <>
          {instance.pipeline && (
            <Link target="_blank" to={instance.pipeline.url}>
              {instance.pipeline.id}
            </Link>
          )}
        </>
      ),
    },
    capacity_partner_email: {
      renderHeader: () => (
        <Sortable
          field="capacity_partner__email"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Capacity partner
        </Sortable>
      ),
      renderCell: (instance) => instance.capacity_partner?.email,
    },
    provision_status: {
      renderHeader: () => (
        <Sortable
          field="provision_status"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Provision status
        </Sortable>
      ),
      renderCell: (instance) => (
        <>
          {instance.provision_status && (
            <ProvisionStatus status={instance.provision_status as any} />
          )}
        </>
      ),
    },
    mobile_emulator_app_ios: {
      renderHeader: () => "iOS app",
      renderCell: (instance) => (
        <>
          {instance.mobile_emulator_app_ios?.file_path && (
            <BundleLink
              linkText="iOS app"
              url={instance.mobile_emulator_app_ios.file_path}
            />
          )}
        </>
      ),
    },
    mobile_emulator_app_android: {
      renderHeader: () => "Android app",
      renderCell: (instance) => (
        <>
          {instance.mobile_emulator_app_android?.file_path && (
            <BundleLink
              linkText="Android app"
              url={instance.mobile_emulator_app_android.file_path}
            />
          )}
        </>
      ),
    },
    is_deallocated: {
      renderHeader: () => "Deallocated",
      renderCell: (instance) => (
        <YesNo value={instance.is_deallocated || false} />
      ),
    },
    created_at: {
      renderHeader: () => (
        <Sortable
          field="created_at"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Created at
        </Sortable>
      ),
      renderCell: (instance) => (
        <>{instance.created_at && <Timestamp value={instance.created_at} />}</>
      ),
    },
    cloud_provider: {
      renderHeader: () => (
        <Sortable
          field="cloud_provider"
          onSortChange={props.onSort}
          activeSort={props.activeSort as any}
        >
          Cloud provider
        </Sortable>
      ),
      renderCell: (instance) => (
        <CloudProvider value={instance.cloud_provider as any} />
      ),
    },
  };

  const columnsToShow = allColumns.filter((col) =>
    props.activeColumns.includes(col)
  );

  const { isFetching } = props;

  return (
    <Table.Root
      variant="surface"
      data-testid="results-table"
      className={cn({ isFetching })}
    >
      <Table.Header>
        <Table.Row>
          {columnsToShow.map((columnId) => (
            <Table.ColumnHeaderCell
              key={columnId}
              style={{ whiteSpace: "nowrap" }}
            >
              {columnMap[columnId].renderHeader(() => {})}
            </Table.ColumnHeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.instances.map((instance) => (
          <Table.Row key={instance.id}>
            {columnsToShow.map((columnId, idx) => (
              <Table.Cell key={columnId}>
                {columnMap[columnId].renderCell(instance, idx)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
}
