import React, { useEffect } from "react";
import { SRD, notAsked } from "srd";
import { Flex, Table, Switch, Text } from "@radix-ui/themes";

import styles from "./ApiUsersPage.module.scss";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  stateSelector,
  fetch,
  setAfkWorker,
  setSlackAlerts,
  setFailedCreationAlerts,
} from "../app/apiUsersPageReducer";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { Error } from "../components/Error";
import * as layout from "../app/layoutReducer";
import { Spinable } from "../components/Spinable/Spinable";

export function ApiUsersPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(stateSelector);

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([layout.plainBreadcrumb("API users")]));

    dispatch(fetch());
  }, []);

  return (
    <div className={styles.page}>
      {SRD.match(
        {
          notAsked: () => <></>,
          loading: () => <PageLoading />,
          failure: (msg) => <Error>{msg}</Error>,
          success: (results) => (
            <div className={styles.content}>
              <Table.Root variant="surface" data-testid="results-table">
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>
                      API username
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>AFK worker</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      Enable slack alerts
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      Enable failed creation alerts
                    </Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {results.map((row) => (
                    <Table.Row key={row.id}>
                      <Table.RowHeaderCell>{row.name}</Table.RowHeaderCell>
                      <Table.Cell>
                        <Spinable
                          pending={SRD.isLoading(
                            state.afkWorker[row.id] || notAsked()
                          )}
                        >
                          <Text as="label" size="2">
                            <Flex gap="2">
                              <Switch
                                checked={row.afk_worker === true}
                                onClick={() =>
                                  dispatch(
                                    setAfkWorker({
                                      id: row.id,
                                      value: !row.afk_worker,
                                    })
                                  )
                                }
                              />
                              AFK worker
                            </Flex>
                          </Text>
                        </Spinable>
                      </Table.Cell>
                      <Table.Cell>
                        <Spinable
                          pending={SRD.isLoading(
                            state.slackAlerts[row.id] || notAsked()
                          )}
                        >
                          <Text as="label" size="2">
                            <Flex gap="2">
                              <Switch
                                checked={row.enable_slack_alerts === true}
                                onClick={() =>
                                  dispatch(
                                    setSlackAlerts({
                                      id: row.id,
                                      value: !row.enable_slack_alerts,
                                    })
                                  )
                                }
                              />
                              Enable slack alerts
                            </Flex>
                          </Text>
                        </Spinable>
                      </Table.Cell>
                      <Table.Cell>
                        <Spinable
                          pending={SRD.isLoading(
                            state.failedCreationAlerts[row.id] || notAsked()
                          )}
                        >
                          <Text as="label" size="2">
                            <Flex gap="2">
                              <Switch
                                checked={
                                  row.enable_failed_creation_alerts === true
                                }
                                onClick={() =>
                                  dispatch(
                                    setFailedCreationAlerts({
                                      id: row.id,
                                      value: !row.enable_failed_creation_alerts,
                                    })
                                  )
                                }
                              />
                              Enable failed creation alerts
                            </Flex>
                          </Text>
                        </Spinable>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Root>
            </div>
          ),
        },
        state.rows
      )}
    </div>
  );
}
