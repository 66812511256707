import React from "react";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@radix-ui/themes";

import {
  gotSelectedApp,
  healthStateSelector,
  THealthState,
} from "../../../app/healthPageReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import healthPageStyles from "../../HealthPage.module.scss";
import { TIdeApp } from "../../types/types";

import styles from "./AppList.module.scss";

export const AppList: React.FunctionComponent = (): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const healthState = useAppSelector<THealthState>(healthStateSelector);
  const { appsByTimestamp, selectedTimestamp } = healthState;

  let selectedTimestampApps: Array<TIdeApp> | null = null;
  if (appsByTimestamp) {
    if (selectedTimestamp) {
      selectedTimestampApps = Object.values(appsByTimestamp[selectedTimestamp]);
    } else {
      const dates = Object.keys(appsByTimestamp).map(
        (timestamp: string) => new Date(timestamp)
      );
      dates.sort();
      const latestDate: Date = dates[dates.length - 1];
      const latestTimestamp: string = latestDate.toISOString();
      selectedTimestampApps = Object.values(appsByTimestamp[latestTimestamp]);
    }
  }

  const onRowClick = (appDisplayName: string): void => {
    dispatch(gotSelectedApp(appDisplayName));
  };

  const getRows = (): Array<JSX.Element> | null => {
    return (
      selectedTimestampApps &&
      selectedTimestampApps.map((app: TIdeApp) => (
        <Table.Row
          key={app.displayName}
          onClick={() => onRowClick(app.displayName)}
        >
          <Table.Cell>
            <img
              src={app.icon}
              className={healthPageStyles.icon}
              alt={`${app.displayName} Icon`}
            />
          </Table.Cell>
          <Table.Cell>{app.displayName}</Table.Cell>
          <Table.Cell>
            {app.isInstalled ? (
              <CheckIcon color="rgb(0, 196, 159)" width={24} height={24} />
            ) : (
              <Cross2Icon color="salmon" width={24} height={24} />
            )}
          </Table.Cell>
        </Table.Row>
      ))
    );
  };

  return (
    appsByTimestamp && (
      <Table.Root variant="surface" className={styles.appList}>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>App</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Installed</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{getRows()}</Table.Body>
      </Table.Root>
    )
  );
};
