import React from "react";
import { format, formatDistance } from "date-fns";
import {
  setTimestampFormat,
  timestampFormatSelector,
} from "../../app/userPreferencesReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

export type TimestampFormats = "distance" | "pretty" | "raw";

export function Timestamp(props: { value: string }) {
  const currentFormat = useAppSelector(timestampFormatSelector);
  const dispatch = useAppDispatch();

  const formatOptions: TimestampFormats[] = ["distance", "pretty", "raw"];

  const currentIndex = formatOptions.indexOf(currentFormat);

  const onClick = () => {
    const nextFormat = formatOptions[(currentIndex + 1) % formatOptions.length];
    dispatch(setTimestampFormat(nextFormat));
  };

  const timestamp = new Date(props.value);
  const today = new Date();

  let toShow = props.value;

  if (currentFormat === "distance") {
    toShow = formatDistance(timestamp, today, { addSuffix: true });
  }

  if (currentFormat === "pretty") {
    toShow = format(timestamp, "HH:mm dd/MM/yyyy");
  }

  return (
    <div
      onClick={onClick}
      style={{ cursor: "pointer", userSelect: "none", whiteSpace: "nowrap" }}
      data-testid="timestamp"
    >
      {toShow}
    </div>
  );
}
