import React, { useEffect } from "react";
import { Heading, Flex } from "@radix-ui/themes";

import { RecentChats } from "../RecentChats/RecentChats";
import * as layout from "../../app/layoutReducer";
import { useAppDispatch } from "../../app/hooks";

import styles from "./RecentChatsPage.module.scss";

export const RecentChatsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([]));
  }, []);

  return (
    <div className={styles.pageWrap}>
      <Flex direction="column" gap="5">
        <div className={styles.header}>
          <Heading>Recent chats</Heading>
          <div>Last day</div>
        </div>
        <div>
          <RecentChats />
        </div>
      </Flex>
    </div>
  );
};
