import { RD, SRD } from "srd";

type WithId<T extends object> = T & { id: string };

type WithRowsOfWithId<T extends object> = {
  total: number;
  rows: Array<WithId<T>>;
};

/**
 * Generic function to update an item in a typical page api response
 */
export function updateRowById<T extends object>(
  data: RD<string, WithRowsOfWithId<T>>,
  id: string,
  fn: (item: WithId<T>) => WithId<T>
) {
  return SRD.map(
    (items) => {
      const rows = items.rows.map((item) => {
        if (item.id === id) {
          return fn(item);
        }

        return item;
      });

      return { ...items, rows };
    },

    data
  );
}

export function delayPromise(promise, minimumDelay) {
  return Promise.all([
    promise,
    new Promise((resolve) => setTimeout(resolve, minimumDelay)),
  ]).then((results) => results[0]);
}
