import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { checkForAuthError } from "../checkAuth";
import {
  SearchPipelinesV1PipelinesSearchPostApiArg,
  SearchPipelinesV1PipelinesSearchPostApiResponse,
  SearchInstancesV1InstancesSearchPostApiArg,
  SearchInstancesV1InstancesSearchPostApiResponse,
} from "./ideService";

const baseQuery = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    // location.origin needed for msw to work
    baseUrl: new URL(baseUrl, location.origin).href,
  });

  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    checkForAuthError(result);

    return result;
  };
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: "ideApi",
  baseQuery: baseQuery("/api/proxy/ide"),
  endpoints: (build) => ({
    // create a query version of search piplines so we can use refetch behaviour
    searchPipelinesV1PipelinesSearchPostCustom: build.query<
      SearchPipelinesV1PipelinesSearchPostApiResponse,
      SearchPipelinesV1PipelinesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/pipelines/search`,
        method: "POST",
        body: queryArg.pipelineFilterRequest,
      }),
    }),
    searchInstances: build.query<
      SearchInstancesV1InstancesSearchPostApiResponse,
      SearchInstancesV1InstancesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/search`,
        method: "POST",
        body: queryArg.instanceFilterRequest,
      }),
    }),
  }),
});

export const {
  useSearchPipelinesV1PipelinesSearchPostCustomQuery,
  useSearchInstancesQuery,
} = emptySplitApi;
