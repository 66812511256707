export const instancesHealthOverviewResponse = {
  status: "success",
  data: {
    clusterName: "bxide-prod-ap-south-1",
    clusterTimestamp: "",
    timestamp: "",
    instances: [
      {
        uuid: "86:41:41:8f:3f:aa",
        instanceName: "web-a7738918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 3.5,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67018a699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: true,
        pythonVersion: "2.7.17",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "web-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 31.5,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67012b699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: false,
        pythonVersion: "",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "web-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 65.9,
        pollutedIde: true,
        gitExists: false,
        gitUserConfigured: false,
        gitLastCommit: "",
        pythonExists: false,
        pythonVersion: "",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: false,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "mobile-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:ff:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 99.12,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67012b699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: true,
        pythonVersion: "3.10.0",
        awServerExists: false,
        awWindowExists: false,
        awAfkExists: false,
        awServerRunning: false,
        awWindowRunning: false,
        awDisplay: "Invalid Display",
        awAfkRunning: false,
        awServerUpstream: false,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:aa",
        instanceName: "web-a7738918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 50.31,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67018a699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: true,
        pythonVersion: "2.7.17",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "web-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 23.98,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67012b699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: false,
        pythonVersion: "",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "web-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 55.9,
        pollutedIde: true,
        gitExists: false,
        gitUserConfigured: false,
        gitLastCommit: "",
        pythonExists: false,
        pythonVersion: "",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: false,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "mobile-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:ff:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 78.76,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67012b699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: true,
        pythonVersion: "3.10.0",
        awServerExists: false,
        awWindowExists: false,
        awAfkExists: false,
        awServerRunning: false,
        awWindowRunning: false,
        awDisplay: "Invalid Display",
        awAfkRunning: false,
        awServerUpstream: false,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "web-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 12.34,
        pollutedIde: false,
        gitExists: true,
        gitUserConfigured: true,
        gitLastCommit: "67012b699f3ea174aed6ee30ac2f296400d1c99b",
        pythonExists: false,
        pythonVersion: "",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: false,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
      {
        uuid: "86:41:41:8f:3f:ab",
        instanceName: "web-a7838918397e-0",
        cpu: 0.9,
        disk: 31.2,
        macAddress: "86:41:41:8f:3f:aa",
        totalMemory: 270179409920,
        usedMemory: 260589322240,
        availableMemory: 7626502144,
        memoryPercent: 92.34,
        pollutedIde: true,
        gitExists: false,
        gitUserConfigured: false,
        gitLastCommit: "",
        pythonExists: false,
        pythonVersion: "",
        awServerExists: true,
        awWindowExists: true,
        awAfkExists: true,
        awServerRunning: true,
        awWindowRunning: true,
        awDisplay: "Invalid Display",
        awAfkRunning: true,
        awServerUpstream: true,
        ubuntuContext: "v1",
        ubuntuVersion: "18.04",
      },
    ],
  },
};
