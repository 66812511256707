import { z } from "zod";
import * as _ from "lodash";

const PipelineStatisticsSchema = z.object({
  created_at: z.string().datetime(),
  updated_at: z.string().datetime(),
  type: z.string(),
  status: z.string(),
  start_date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  end_date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  report: z.object({
    execution_time_average: z.array(
      z.object({
        pipeline_action: z.string(),
        average_execution_time: z.any(),
      })
    ),
    pipelines: z.array(
      z.object({
        status: z.string(),
        percentage: z.number().nullable(),
        pipeline_action: z.string(),
        id: z.number(),
        updated_at: z.string(),
        created_at: z.string(),
        execution_time: z.number(),
      })
    ),
  }),
});

export function parse(report) {
  return PipelineStatisticsSchema.safeParse(report);
}

type OrganisedData = {
  pipelines: string[];
  timeseries: { [key: string]: { created_at; execution_time }[] };
  execution_time_average: { [key: string]: number };
};

export function organise(
  rawReport: z.infer<typeof PipelineStatisticsSchema>
): OrganisedData {
  const pipelines = rawReport.report.pipelines;

  const grouped = _.groupBy(pipelines, "pipeline_action");

  const timeseries = {};

  Object.keys(grouped).forEach((pipeline) => {
    timeseries[pipeline] = _.sortBy(grouped[pipeline], "created_at");
  });

  const execution_time_average = {};

  rawReport.report.execution_time_average.forEach(
    ({ pipeline_action, average_execution_time }) => {
      execution_time_average[pipeline_action] = average_execution_time;
    }
  );

  return {
    pipelines: _.sortBy(Object.keys(timeseries)),
    timeseries,
    execution_time_average,
  };
}
