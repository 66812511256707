import React, { useState, useEffect } from "react";
import { Flex, Text, Card, Heading, Select } from "@radix-ui/themes";
import ReactJson from "react-json-view";

import { PipelineReportsView } from "../PipelineReportsView/PipelineReportsView";
import { ReportStatus } from "../../components/ReportStatus";
import { GenericReportView } from "../GenericReportView/GenericReportView";
import { PIPELINE_STATISTICS_REPORT } from "../consts";
import * as pipelineReportData from "../PipelineReportsView/data";
import { useGetReportV1ReportsReportIdGetQuery } from "../../app/api/reports/reportsService";

import { Error } from "../../components/Error";
import { PageLoading } from "../../components/PageLoading/PageLoading";
import { useAppDispatch } from "../../app/hooks";
import * as layout from "../../app/layoutReducer";

import styles from "./ViewReportPage.module.scss";

type Props = {
  params: {
    reportId: string;
  };
};

export const ViewReportPageLoader = (props: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([]));
  }, []);

  const { isLoading, isError, isSuccess, data } =
    useGetReportV1ReportsReportIdGetQuery({
      reportId: parseInt(props.params.reportId, 10),
    });

  if (isError) {
    return <Error>Something went wrong</Error>;
  }

  if (isLoading) {
    return (
      <div className={styles.pageWrap}>
        <PageLoading />
      </div>
    );
  }

  if (isSuccess) {
    return <ViewReportPage report={data} />;
  }
};

type ViewType = "pretty" | "table" | "json";

export const ViewReportPage = (props: { report: any }) => {
  const [viewType, setViewType] = useState<ViewType | null>(null);
  const [validViewTypes, setValidViewTypes] = useState<ViewType[]>([
    "table",
    "json",
  ]);

  const data = props.report;

  let allowPrettyView = false;

  let prettyReportView = <></>;

  const pipelinesParsed = pipelineReportData.parse(data);

  if (data.type === PIPELINE_STATISTICS_REPORT && pipelinesParsed.success) {
    allowPrettyView = true;
    prettyReportView = <PipelineReportsView data={pipelinesParsed.data} />;
  }

  useEffect(() => {
    if (allowPrettyView) {
      setValidViewTypes((state) => [...state, "pretty"]);
      setViewType("pretty");
    } else {
      setViewType("table");
    }
  }, [data]);

  return (
    <div className={styles.pageWrap}>
      <Flex direction="column" gap="3">
        <Flex justify="between" align="center">
          <Flex gap="3" align="center">
            <Heading>Report</Heading>

            <ReportStatus value={data.status} />
          </Flex>
          <div>
            <a
              href={`/api/proxy/reports/v1/reports/file/${data.id}`}
              rel="noreferrer"
              target="_blank"
            >
              Download
            </a>
          </div>
        </Flex>
        <Card>
          <Flex direction="column" gap="5">
            <Flex justify="between">
              <Flex gap="3">
                <Text>
                  <Text color="gray">Type:</Text> {data.type}
                </Text>
                <Text>
                  <Text color="gray">Id:</Text> {data.id}
                </Text>
              </Flex>
              <Flex gap="3">
                <Text>
                  <Text color="gray">Start date:</Text> {data.start_date}
                </Text>
                <Text>
                  <Text color="gray">End date:</Text> {data.end_date}
                </Text>
              </Flex>
            </Flex>
            {Object.keys(data.options).length > 0 && (
              <ReactJson
                src={data.options}
                name="options"
                displayObjectSize={false}
                displayDataTypes={false}
                theme="monokai"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "14px",
                }}
              />
            )}
          </Flex>
        </Card>
        <form>
          <Select.Root
            value={viewType || ""}
            onValueChange={(value) => {
              if (value !== "") {
                setViewType(value as ViewType);
              }
            }}
          >
            <Select.Trigger
              style={{ width: "100px" }}
              data-testid="view-type-select"
            />
            <Select.Content>
              {validViewTypes.map((vt) => (
                <Select.Item key={vt} value={vt}>
                  {vt}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </form>
      </Flex>
      <div className={styles.dataWrap}>
        {viewType === "pretty" && prettyReportView}
        {viewType === "table" && <GenericReportView data={data.report} />}
        {viewType === "json" && (
          <ReactJson
            src={data.report}
            name="report"
            displayObjectSize={false}
            displayDataTypes={false}
            theme="monokai"
            style={{
              backgroundColor: "transparent",
              fontSize: "14px",
            }}
          />
        )}
      </div>
    </div>
  );
};
