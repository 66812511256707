import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success } from "srd";

import { THealthOverviewResponse } from "../../../server/src/healthOverview/types";
// import axios from "../axios";
import { healthOverviewResponse } from "../mocks/data/healthOverviewResponse";
import { TRootState } from "./store";

type TState = {
  healthOverview: RD<string, Array<THealthOverviewResponse>>;
};

const initialState: TState = {
  healthOverview: notAsked(),
};

export const getHealthOverview = createAsyncThunk(
  "healthOverview/getHealthOverviewData",
  async () => {
    /**
     * Mocking API endpoints till available.
     */

    // const response = await axios.get("/api/health-overview");

    // return response.data.data;

    const response = await new Promise<{
      status: string;
      data: Array<THealthOverviewResponse>;
    }>((resolve /* , reject */) => {
      setTimeout(() => {
        resolve(healthOverviewResponse);
        // reject("test error")
      }, 2100);
    });

    return response.data;
  }
);

export const healthOverviewPageReducer = createReducer(
  initialState,
  (builder) => {
    return builder
      .addCase(getHealthOverview.pending, (state) => {
        state.healthOverview = loading();
      })
      .addCase(getHealthOverview.rejected, (state) => {
        state.healthOverview = failure("Something went wrong");
      })
      .addCase(getHealthOverview.fulfilled, (state, action) => {
        state.healthOverview = success(action.payload);
      });
  }
);

export const healthOverviewStateSelector = (state: TRootState) =>
  state.healthOverviewPageReducer;
