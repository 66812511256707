import {
  CheckIcon,
  Cross2Icon,
  DotFilledIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import { Trigger } from "@radix-ui/react-select";
import {
  Box,
  Button,
  Flex,
  Select,
  Table,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SRD } from "srd";

import {
  THealthOverviewResponse,
  TInstancesHealthOverviewResponse,
} from "../../../server/src/healthOverview/types";
import {
  getHealthOverview,
  healthOverviewStateSelector,
} from "../app/healthOverviewPageReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getInstancesHealth,
  instancesHealthOverviewStateSelector,
} from "../app/instancesHealthOverviewPageReducer";
import * as layout from "../app/layoutReducer";
import { Error } from "../components/Error";
import { PageLoading } from "../components/PageLoading/PageLoading";
import PageLoaderPositions from "../components/PageLoading/enums/PageLoaderPositions";
import Spinner from "../components/Spinner/Spinner";
import { UsageBar } from "../components/UsageBar/UsageBar";

import styles from "./InstancesHealthOverviewPage.module.scss";

export const InstancesHealthOverviewPage: React.FunctionComponent =
  (): JSX.Element => {
    const { instancesHealth } = useAppSelector(
      instancesHealthOverviewStateSelector
    );
    const { healthOverview } = useAppSelector(healthOverviewStateSelector);

    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const clusterQueryParam = searchParams.get("cluster");

    const [selectedCluster, setSelectedCluster] = useState<string | null>(
      clusterQueryParam
    );

    const tableHeaderRef = useRef<HTMLDivElement | null>(null);
    const tableBodyRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      dispatch(
        layout.setBreadcrumbs([
          layout.plainBreadcrumb("Instances Health Overview"),
        ])
      );

      selectedCluster &&
        dispatch(getInstancesHealth(/* { cluster: selectedCluster } */));

      healthOverview.tag !== "Success" && dispatch(getHealthOverview());
    }, []);

    useLayoutEffect(() => {
      if (
        instancesHealth.tag === "Success" &&
        instancesHealth.data.instances.length &&
        tableHeaderRef.current &&
        tableBodyRef.current
      ) {
        const tableHeaders = Array.from(
          tableHeaderRef.current.getElementsByTagName("th")
        );

        const tableBodyRows = Array.from(
          tableBodyRef.current.getElementsByTagName("tr")
        );

        const tableBodyCells = Array.from(
          tableBodyRows[0].getElementsByTagName("td")
        );

        tableBodyCells.forEach((cell, index) => {
          tableHeaders[index].style.minWidth = `${cell.clientWidth}px`;
          tableHeaders[index].style.maxWidth = `${cell.clientWidth}px`;
        });

        const tableHeaderScrollArea = tableHeaderRef.current.querySelector(
          ".rt-ScrollAreaViewport"
        );
        const tableBodyScrollArea = tableBodyRef.current.querySelector(
          ".rt-ScrollAreaViewport"
        );

        tableHeaderScrollArea?.addEventListener(
          "scroll",
          ({ target }) =>
            (tableBodyScrollArea!.scrollLeft = (
              target as HTMLDivElement
            ).scrollLeft)
        );

        tableBodyScrollArea?.addEventListener(
          "scroll",
          ({ target }) =>
            (tableHeaderScrollArea!.scrollLeft = (
              target as HTMLDivElement
            ).scrollLeft)
        );
      }
    }, [instancesHealth]);

    const onClusterSelection = (selectedCluster: string): void => {
      setSelectedCluster(selectedCluster);

      dispatch(getInstancesHealth(/* { cluster: selectedCluster } */));
    };

    return (
      <Flex direction="column" className={styles.instancesHealthOverviewPage}>
        <Box mb="4">
          <Select.Root
            value={selectedCluster || undefined}
            onValueChange={onClusterSelection}
          >
            {SRD.match(
              {
                notAsked: () => <></>,
                loading: () => (
                  <Trigger asChild={true}>
                    <Button disabled={true} variant="soft" color="grass">
                      <Flex align="baseline">
                        <Spinner size={14} />
                        &nbsp;&nbsp;&nbsp;&nbsp;Loading clusters ..
                      </Flex>
                    </Button>
                  </Trigger>
                ),
                failure: () =>
                  clusterQueryParam ? (
                    <Select.Trigger
                      placeholder="Select cluster"
                      variant="soft"
                      color="grass"
                    />
                  ) : (
                    <Trigger asChild={true}>
                      <Button disabled={true} variant="soft" color="red">
                        <Flex align="center">
                          <ExclamationTriangleIcon />
                          &nbsp;&nbsp;Failed to load clusters.
                        </Flex>
                      </Button>
                    </Trigger>
                  ),
                success: () => (
                  <Select.Trigger
                    placeholder="Select cluster"
                    variant="soft"
                    color="grass"
                  />
                ),
              },
              healthOverview
            )}

            <Select.Content variant="soft" color="grass">
              {clusterQueryParam && (
                <Select.Item value={clusterQueryParam} key={clusterQueryParam}>
                  {clusterQueryParam}
                </Select.Item>
              )}

              {SRD.match(
                {
                  notAsked: () => <></>,
                  loading: () => (
                    <>
                      <Select.Separator />
                      <Select.Item
                        value={"Loading clusters .."}
                        disabled={true}
                      >
                        <Spinner size={14} />
                        &nbsp;&nbsp;Loading clusters ..
                      </Select.Item>
                    </>
                  ),
                  failure: () => (
                    <>
                      <Select.Separator />
                      <Select.Item
                        value={"Failed to load clusters."}
                        disabled={true}
                      >
                        <Flex align="center">
                          <ExclamationTriangleIcon />
                          &nbsp;&nbsp;Failed to load clusters.
                        </Flex>
                      </Select.Item>
                    </>
                  ),
                  success: (
                    healthOverviewData: Array<THealthOverviewResponse>
                  ) => {
                    if (healthOverviewData.length) {
                      return clusterQueryParam
                        ? healthOverviewData
                            .filter(
                              ({ clusterName }) =>
                                clusterName !== clusterQueryParam
                            )
                            .map(({ clusterName }) => (
                              <Select.Item
                                value={clusterName}
                                key={clusterName}
                              >
                                {clusterName}
                              </Select.Item>
                            ))
                        : healthOverviewData.map(({ clusterName }) => (
                            <Select.Item value={clusterName} key={clusterName}>
                              {clusterName}
                            </Select.Item>
                          ));
                    }

                    return <></>;
                  },
                },
                healthOverview
              )}
            </Select.Content>
          </Select.Root>
        </Box>

        {SRD.match(
          {
            notAsked: () => <></>,
            loading: () => (
              <Flex direction="row" justify="center" align="center">
                <PageLoading
                  position={PageLoaderPositions.CENTER}
                  size={30}
                  label={
                    <label className={styles.loaderLabel}>
                      Loading instances health ..
                    </label>
                  }
                />
              </Flex>
            ),
            failure: () => (
              <Box>
                <Error>
                  Failed to load instances health for the cluster - &apos;
                  {selectedCluster}&apos;.
                </Error>
              </Box>
            ),
            success: (
              instancesHealthData: TInstancesHealthOverviewResponse
            ) => (
              <>
                <Table.Root
                  ref={tableHeaderRef}
                  variant="surface"
                  className={styles.instancesHealthTableHeader}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>CPU</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Disk</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Memory</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        MAC Address
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Polluted</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Git</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Git user</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        Last commit
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>Python</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        Python version
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>AW server</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>AW window</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>AW AFK</Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        AW display
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        AW server upstream
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        IDE version
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell>
                        Ubuntu version
                      </Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>
                </Table.Root>

                <Table.Root
                  ref={tableBodyRef}
                  variant="surface"
                  className={styles.instancesHealthTableBody}
                >
                  <Table.Body>
                    {instancesHealthData.instances.map((data) => (
                      <Table.Row key={data.uuid}>
                        <Table.Cell>{data.instanceName}</Table.Cell>
                        <Table.Cell>{data.cpu}</Table.Cell>
                        <Table.Cell>{data.disk}</Table.Cell>
                        <Table.Cell>
                          <Flex direction="column" gap="2">
                            <Tooltip
                              content={`Used: ${Math.floor(
                                +data.usedMemory / Math.pow(1024, 3)
                              )} GB | Available: ${Math.floor(
                                +data.availableMemory / Math.pow(1024, 3)
                              )} GB`}
                            >
                              <UsageBar used={data.memoryPercent} />
                            </Tooltip>
                            <Text>{`${Math.floor(
                              +data.totalMemory / Math.pow(1024, 3)
                            )} GB`}</Text>
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>{data.macAddress}</Table.Cell>
                        <Table.Cell>
                          {data.pollutedIde ? (
                            <CheckIcon
                              color="rgb(0, 196, 159)"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <Cross2Icon color="salmon" width={24} height={24} />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {data.gitExists ? (
                            <CheckIcon
                              color="rgb(0, 196, 159)"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <Cross2Icon color="salmon" width={24} height={24} />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {data.gitUserConfigured ? (
                            <CheckIcon
                              color="rgb(0, 196, 159)"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <Cross2Icon color="salmon" width={24} height={24} />
                          )}
                        </Table.Cell>
                        <Table.Cell>{data.gitLastCommit || "N/A"}</Table.Cell>
                        <Table.Cell>
                          {data.pythonExists ? (
                            <CheckIcon
                              color="rgb(0, 196, 159)"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <Cross2Icon color="salmon" width={24} height={24} />
                          )}
                        </Table.Cell>
                        <Table.Cell>{data.pythonVersion || "N/A"}</Table.Cell>
                        <Table.Cell>
                          <Flex direction="column" gap="2">
                            <Flex align="center" gap="1">
                              {data.awServerExists ? (
                                <CheckIcon
                                  color="rgb(0, 196, 159)"
                                  width={24}
                                  height={24}
                                />
                              ) : (
                                <Cross2Icon
                                  color="salmon"
                                  width={24}
                                  height={24}
                                />
                              )}
                              <Text>
                                {data.awServerExists
                                  ? "Installed"
                                  : "Not found"}
                              </Text>
                            </Flex>
                            {data.awServerExists && (
                              <Flex align="center" gap="1">
                                <DotFilledIcon
                                  color={
                                    data.awServerRunning
                                      ? "rgb(0, 196, 159)"
                                      : "rgb(250 129 114)"
                                  }
                                  width={24}
                                  height={24}
                                />
                                <Text>
                                  {data.awServerRunning ? "Active" : "Inactive"}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>
                          <Flex direction="column" gap="2">
                            <Flex align="center" gap="1">
                              {data.awWindowExists ? (
                                <CheckIcon
                                  color="rgb(0, 196, 159)"
                                  width={24}
                                  height={24}
                                />
                              ) : (
                                <Cross2Icon
                                  color="salmon"
                                  width={24}
                                  height={24}
                                />
                              )}
                              <Text>
                                {data.awWindowExists
                                  ? "Installed"
                                  : "Not found"}
                              </Text>
                            </Flex>
                            {data.awWindowExists && (
                              <Flex align="center" gap="1">
                                <DotFilledIcon
                                  color={
                                    data.awWindowRunning
                                      ? "rgb(0, 196, 159)"
                                      : "rgb(250 129 114)"
                                  }
                                  width={24}
                                  height={24}
                                />
                                <Text>
                                  {data.awWindowRunning ? "Active" : "Inactive"}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>
                          <Flex direction="column" gap="2">
                            <Flex align="center" gap="1">
                              {data.awAfkExists ? (
                                <CheckIcon
                                  color="rgb(0, 196, 159)"
                                  width={24}
                                  height={24}
                                />
                              ) : (
                                <Cross2Icon
                                  color="salmon"
                                  width={24}
                                  height={24}
                                />
                              )}
                              <Text>
                                {data.awAfkExists ? "Installed" : "Not found"}
                              </Text>
                            </Flex>
                            {data.awAfkExists && (
                              <Flex align="center" gap="1">
                                <DotFilledIcon
                                  color={
                                    data.awAfkRunning
                                      ? "rgb(0, 196, 159)"
                                      : "rgb(250 129 114)"
                                  }
                                  width={24}
                                  height={24}
                                />
                                <Text>
                                  {data.awAfkRunning ? "Active" : "Inactive"}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>{data.awDisplay}</Table.Cell>
                        <Table.Cell>
                          {data.awServerUpstream ? (
                            <CheckIcon
                              color="rgb(0, 196, 159)"
                              width={24}
                              height={24}
                            />
                          ) : (
                            <Cross2Icon color="salmon" width={24} height={24} />
                          )}
                        </Table.Cell>
                        <Table.Cell>{data.ubuntuContext}</Table.Cell>
                        <Table.Cell>{data.ubuntuVersion}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Root>
              </>
            ),
          },
          instancesHealth
        )}
      </Flex>
    );
  };
