import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success } from "srd";

import {
  TActivityApiPayload,
  TActivityApiResponse,
  TActivityApiData,
} from "../../../server/src/api/activity/types";
import {
  getAppsByTimestamp,
  getActivityData,
} from "../HealthPage/data/healthData";
import { TAppsByTimestamp } from "../HealthPage/types/types";
import { activityApi } from "../api";

import { TRootState } from "./store";

export type THealthState = {
  activityApiResponse: RD<string, TActivityApiResponse>;
  activityApiData: Array<TActivityApiData>;
  appsByTimestamp: TAppsByTimestamp | null;
  selectedTimestamp: string | null;
  selectedApp: string | null;
};

const initialState: THealthState = {
  activityApiResponse: notAsked(),
  activityApiData: [],
  appsByTimestamp: null,
  selectedTimestamp: null,
  selectedApp: null,
};

export const getActivityApiData = createAsyncThunk(
  "healthPage/getActivityApiData",
  async ({
    instanceName,
    timestampStart,
    timestampEnd,
  }: TActivityApiPayload) => {
    let queries = { start: timestampStart } as any;

    if (timestampEnd) {
      queries = {
        ...queries,
        end: timestampEnd,
      };
    }

    const res =
      await activityApi.get_deployment_health_check_v1_deployment__deployhash__health_check_get(
        {
          params: { deployhash: instanceName },
          queries,
        }
      );

    return res;
  }
);

const handleFailure = (state: THealthState, message: string): void => {
  const failureState = failure(message);

  state.activityApiResponse = failureState;
};

export const gotSelectedTimestamp = createAction<string>(
  "healthPage/gotSelectedTimestamp"
);

export const gotSelectedApp = createAction<string>("healthPage/gotSelectedApp");

export const healthPageReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(getActivityApiData.pending, (state) => {
      const loadingState = loading();

      state.activityApiResponse = loadingState;
    })
    .addCase(getActivityApiData.rejected, (state) => {
      handleFailure(state, "Something went wrong.");
    })
    .addCase(getActivityApiData.fulfilled, (state, action) => {
      const activity = action.payload as TActivityApiResponse;

      state.activityApiResponse = success(activity);

      if (activity.data?.length) {
        state.activityApiData = getActivityData(
          activity.data,
          state.activityApiData
        );

        state.appsByTimestamp = getAppsByTimestamp(
          activity.data,
          state.appsByTimestamp || {}
        );
      }
    })
    .addCase(gotSelectedTimestamp, (state, action) => {
      state.selectedTimestamp = action.payload;
    })
    .addCase(gotSelectedApp, (state, action) => {
      state.selectedApp = action.payload;
    });
});

export const healthStateSelector = (state: TRootState): THealthState =>
  state.healthPageReducer;
