import {
  getListFromLocalStorage,
  setListInLocalStorage,
} from "../localStorageList";

export const RECENTLY_VISTED_STORAGE_KEY = "recently_visited";
const MAX_ITEMS = 100;

export type TVisitedPad = { id: string; timestamp: number };

export function addRecentlyVisitedPad(id: string) {
  const pad: TVisitedPad = { id, timestamp: Date.now() };

  const existingList = getListFromLocalStorage(RECENTLY_VISTED_STORAGE_KEY);

  if (existingList.length > MAX_ITEMS) {
    existingList.shift(); // remove oldest item
  }

  existingList.push(pad);

  setListInLocalStorage(RECENTLY_VISTED_STORAGE_KEY, existingList);
}

// sort by timestamp, remove duplicates, keep latest
export function sortByTimestamp(items: TVisitedPad[]) {
  const sortedItems = [...items].sort((a, b) => b.timestamp - a.timestamp);

  const latestItemMap = new Map();

  sortedItems.forEach((item) => {
    if (!latestItemMap.has(item.id)) {
      latestItemMap.set(item.id, item);
    }
  });

  return Array.from(latestItemMap.values());
}
