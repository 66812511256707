import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success } from "srd";
import { TRootState } from "./store";
import { TAddEditClusterForm } from "../AddEditClusterForm/types";
import { ideApi } from "./api/ide/ideService";

type TModal = { modalType: "none" } | { modalType: "addCluster" };

type TState = {
  filterQuery: string;
  addEditClusterRequest: RD<string, string>;
  modal: TModal;
};

const initialState: TState = {
  filterQuery: "",
  addEditClusterRequest: notAsked(),
  modal: { modalType: "none" },
};

export const addCluster = createAsyncThunk<any, TAddEditClusterForm>(
  "clustersOverviewPage/addCluster",
  async (form, { dispatch }) => {
    await dispatch(
      ideApi.endpoints.createClusterV1ClustersPost.initiate({
        clusterCreate: form,
      })
    );

    dispatch(clustersOverviewPageSlice.actions.closeModal());
  }
);

export const clustersOverviewPageSlice = createSlice({
  name: "clustersOverviewPage",
  initialState,
  reducers: {
    gotFilter: (state, action: PayloadAction<string>) => {
      state.filterQuery = action.payload;
    },
    clear: (state) => {
      state.filterQuery = "";
    },
    showAddModal: (state) => {
      state.modal = { modalType: "addCluster" };
      state.addEditClusterRequest = notAsked();
    },
    closeModal: (state) => {
      state.modal = { modalType: "none" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCluster.pending, (state) => {
        state.addEditClusterRequest = loading();
      })
      .addCase(addCluster.rejected, (state, action) => {
        state.addEditClusterRequest = failure(action.payload as any);
      })
      .addCase(addCluster.fulfilled, (state) => {
        state.addEditClusterRequest = success("");
      });
  },
});

export const { gotFilter, clear, showAddModal, closeModal } =
  clustersOverviewPageSlice.actions;

export const clustersOverviewPageSelector = (state: TRootState): TState =>
  state.clustersOverviewPageReducer;
