import React from "react";

export function BundleLink(props: { linkText: string; url: string }) {
  const defaultText = props.url.includes("default") ? "(default)" : "";

  return (
    <>
      <a target="_blank" href={props.url} rel="noreferrer">
        {props.linkText}
      </a>
      &nbsp; {defaultText}
    </>
  );
}
