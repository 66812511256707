import * as _ from "lodash";

export type TGenericSection = {
  title: string;
  rows: any[];
  columns: any;
};

export type TOrganisedGenericData = {
  sections: TGenericSection[];
};

export function organise(data: any): TOrganisedGenericData {
  const out = {
    sections: [] as any,
  };

  const topLevelKeys = Object.keys(data);

  for (const tlk of topLevelKeys) {
    if (isArrayOfObjects(data[tlk]) && data[tlk][0]) {
      const columns = generateTableColumns(data[tlk][0]);

      out.sections.push({
        title: tlk,
        rows: data[tlk],
        columns,
      });
    }
  }

  return out;
}

function isArrayOfObjects(value: any): boolean {
  if (!Array.isArray(value)) {
    return false;
  }

  for (const item of value) {
    if (typeof item !== "object" || item === null) {
      return false;
    }
  }

  return true;
}

// move id to front, created_at and updated_at to the end, and sort the rest alphabetically
export function rearrangeKeys(keys: string[]) {
  const [id, others] = _.partition(keys, (str) => str === "id");

  const othersSorted = _.sortBy(others);

  const sortedKeys = [...id, ...othersSorted];

  const specialKeys = ["created_at", "updated_at"];

  const filtered = _.filter(sortedKeys, (item) => !specialKeys.includes(item));

  const toMoveToEnd = _.filter(sortedKeys, (item) =>
    specialKeys.includes(item)
  );

  return filtered.concat(toMoveToEnd);
}

function generateTableColumns(obj) {
  const keys = rearrangeKeys(Object.keys(obj));

  const columns = keys.map((key) => {
    const value = obj[key];

    const columnDef = {
      header: key,
      accessorKey: key,
    } as any;

    if (typeof value === "number") {
      columnDef.cell = ({ getValue }) => getValue();
    } else if (typeof value === "string") {
      if (value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+$/)) {
        columnDef.cell = ({ getValue }) =>
          new Date(getValue()).toLocaleString();
      } else {
        columnDef.cell = ({ getValue }) => getValue();
      }
    } else if (typeof value === "boolean") {
      columnDef.cell = ({ getValue }) => (getValue() ? "Yes" : "No");
    }

    return columnDef;
  });

  return columns;
}
