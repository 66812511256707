import React, { useState } from "react";
import axios from "axios";
import { Blockquote, Flex } from "@radix-ui/themes";

import { apiProxySelfServicePath } from "../../../../server/src/api/consts";

import Button from "../../components/Button/Button";

type TResult = "ok" | "error";

export const XRDPFix = (props: { id: string }) => {
  const [pending, setPending] = useState(false);
  const [result, setResult] = useState<TResult | null>(null);

  const onClick = () => {
    setPending(true);
    setResult(null);

    axios
      .put(
        `${apiProxySelfServicePath}/v1/self_support/${props.id}/fix_black_screen`
      )
      .then(() => {
        // NOOP as api request is async, as api call kicks off pipeline job
        setPending(false);
        setResult("ok");
      })
      .catch((error) => {
        setPending(false);
        setResult("error");
        console.log(error);
      });
  };

  return (
    <Flex gap="3">
      <Button onClick={onClick} pending={pending}>
        XRDP fix
      </Button>
      {result === "ok" && (
        <Blockquote color="green">Pipeline successfully started</Blockquote>
      )}
      {result === "error" && (
        <Blockquote color="red">Something went wrong</Blockquote>
      )}
    </Flex>
  );
};
