import React, { useEffect, useState } from "react";
import { Heading, Flex, Text } from "@radix-ui/themes";
import { format, subDays } from "date-fns";
import * as _ from "lodash";

import { ReportsTable } from "../ReportsTable";
import * as layout from "../../app/layoutReducer";
import { DateInput } from "../../components/DateInput";
import { CreateReportModal } from "../CreateReportModal/CreateReportModal";
import Button from "../../components/Button/Button";
import { useAppDispatch } from "../../app/hooks";
import { useListReportTypesV1ReportsTypesGetQuery } from "../../app/api/reports/reportsService";
import { Error } from "../../components/Error";
import { PageLoading } from "../../components/PageLoading/PageLoading";

import styles from "./ReportsPage.module.scss";

const thirtyDaysAgo = subDays(new Date(), 30);

export const ReportsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([]));
  }, []);

  const [startDate, setStartDate] = useState(
    format(thirtyDaysAgo, "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  const [showCreateModal, setShowCreateModal] = useState(false);

  const { isLoading, isError, data } =
    useListReportTypesV1ReportsTypesGetQuery();

  if (isError) {
    return <Error>Something went wrong</Error>;
  }

  if (isLoading) {
    return (
      <div className={styles.pageWrap}>
        <PageLoading />
      </div>
    );
  }

  const reportTypes = _.sortBy(data.report_types);

  return (
    <div className={styles.pageWrap} data-testid="reports-page">
      <Flex direction="column" gap="6">
        <Flex justify="between">
          <Heading>Reports</Heading>
          <Button onClick={() => setShowCreateModal(true)}>
            Create report
          </Button>
        </Flex>
        <Flex gap="4">
          <Flex gap="2" align="center">
            <Text>Start date:</Text>
            <DateInput
              value={startDate}
              onChange={(e) => setStartDate(e.currentTarget.value)}
              data-testid="startDate"
            />
          </Flex>
          <Flex gap="2" align="center">
            <Text>End date:</Text>
            <DateInput
              value={endDate}
              onChange={(e) => setEndDate(e.currentTarget.value)}
              data-testid="endDate"
            />
          </Flex>
        </Flex>
        {reportTypes.map((reportType) => (
          <ReportsTable
            key={reportType}
            title={reportType}
            reportType={reportType}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
      </Flex>
      {showCreateModal && (
        <CreateReportModal
          onClose={() => setShowCreateModal(false)}
          reportTypes={reportTypes}
        />
      )}
    </div>
  );
};
