import React from "react";
import { Pie, PieChart, Legend, Cell } from "recharts";
import { blue, green } from "@radix-ui/colors";

// if whole number return as is, if not round to one decimal place
function roundToOneDecimal(num) {
  return num % 1 === 0 ? num : Math.round(num * 10) / 10;
}

export function DiskUsageChart(props: {
  data: { name: string; value: number }[];
}) {
  const formattedData = props.data.map(({ name, value }) => {
    const roundedValue = roundToOneDecimal(value);

    return {
      name: `${roundedValue}% ${name}`,
      value: roundedValue,
    };
  });

  const COLORS = [blue.blue10, green.green10];

  return (
    <PieChart width={120} height={120}>
      <Pie
        data={formattedData}
        cy={30}
        innerRadius={20}
        outerRadius={30}
        dataKey="value"
      >
        {formattedData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend
        iconType={"circle"}
        align="center"
        verticalAlign="bottom"
        iconSize={6}
        content={({ payload }) => {
          const items = payload?.map((item) => {
            return (
              <div
                style={{
                  fontSize: "14px",
                  color: item.color,
                  textAlign: "center",
                }}
                key={item.value}
              >
                {item.value}
              </div>
            );
          });

          return <div>{items}</div>;
        }}
      />
    </PieChart>
  );
}
