import React from "react";
import { Text } from "@radix-ui/themes";

import styles from "./TextFieldLabel.module.scss";

export function TextFieldLabel(props: React.PropsWithChildren) {
  return (
    <div className={styles.label}>
      <Text size="2">{props.children}</Text>
    </div>
  );
}
