import React, { useEffect, useRef } from "react";
import { SRD, notAsked } from "srd";
import { Flex, Table, TextField, Switch, Text } from "@radix-ui/themes";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  search,
  gotEmail,
  stateSelector,
  paginationStatusSelector,
  paginateToPage,
  setFaceAuth,
  setAfkWorker,
  clear,
} from "../app/capacityPartnersPageReducer";
import Button from "../components/Button/Button";
import { Error } from "../components/Error";
import styles from "./CapacityPartnersPage.module.scss";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { Pagination } from "../components/Pagination/Pagination";
import { Spinable } from "../components/Spinable/Spinable";
import * as layout from "../app/layoutReducer";

export function CapacityPartnersPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(stateSelector);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    dispatch(
      layout.setBreadcrumbs([layout.plainBreadcrumb("Capacity partners")])
    );

    function handleFocus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    // focus search input on page load
    handleFocus();
  }, []);

  const paginationStatus = useAppSelector(paginationStatusSelector);

  let pagination = <></>;

  if (paginationStatus) {
    pagination = (
      <Pagination
        status={paginationStatus}
        paginate={(nextPage) => dispatch(paginateToPage(nextPage))}
      />
    );
  }

  return (
    <div className={styles.page}>
      <Flex gap="4" direction="column">
        <Flex gap="2">
          <div className={styles.searchInput}>
            <TextField.Input
              placeholder="Search..."
              tabIndex={1}
              onInput={(e) => dispatch(gotEmail(e.currentTarget.value))}
              ref={inputRef}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  dispatch(search());
                }
              }}
              value={state.searchQuery.email}
              data-testid="search-input"
            />
          </div>
          <Button
            onClick={() => dispatch(search())}
            pending={SRD.isLoading(state.capacityPartners)}
            data-testid="search-button"
          >
            Search
          </Button>
          <Button color="crimson" onClick={() => dispatch(clear())}>
            Clear
          </Button>
        </Flex>
        <div>
          {SRD.match(
            {
              notAsked: () => <div></div>,
              loading: () => <PageLoading />,
              failure: (msg) => (
                <div className={styles.error}>
                  <Error>{msg}</Error>
                </div>
              ),

              success: (results) => {
                return (
                  <>
                    <div className={styles.topPagination}>{pagination}</div>
                    <Table.Root variant="surface" data-testid="results-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
                          <Table.ColumnHeaderCell>
                            Email alias
                          </Table.ColumnHeaderCell>
                          <Table.ColumnHeaderCell>
                            Face auth
                          </Table.ColumnHeaderCell>
                          <Table.ColumnHeaderCell>
                            AFK worker
                          </Table.ColumnHeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {results.rows.map((row) => (
                          <Table.Row key={row.id}>
                            <Table.RowHeaderCell>
                              {row.email}
                            </Table.RowHeaderCell>
                            <Table.Cell>{row.email_alias}</Table.Cell>
                            <Table.Cell>
                              <Spinable
                                pending={SRD.isLoading(
                                  state.faceAuth[row.id] || notAsked()
                                )}
                              >
                                <Text as="label" size="2">
                                  <Flex gap="2">
                                    <Switch
                                      checked={row.face_auth === true}
                                      onClick={() =>
                                        dispatch(
                                          setFaceAuth({
                                            id: row.id,
                                            value: !row.face_auth,
                                          })
                                        )
                                      }
                                    />
                                    Face auth
                                  </Flex>
                                </Text>
                              </Spinable>
                            </Table.Cell>
                            <Table.Cell>
                              <Spinable
                                pending={SRD.isLoading(
                                  state.afkWorker[row.id] || notAsked()
                                )}
                              >
                                <Text as="label" size="2">
                                  <Flex gap="2">
                                    <Switch
                                      checked={row.afk_worker === true}
                                      onClick={() =>
                                        dispatch(
                                          setAfkWorker({
                                            id: row.id,
                                            value: !row.afk_worker,
                                          })
                                        )
                                      }
                                    />
                                    AFK worker
                                  </Flex>
                                </Text>
                              </Spinable>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table.Root>
                    <div className={styles.bottomPagination}>{pagination}</div>
                  </>
                );
              },
            },
            state.capacityPartners
          )}
        </div>
      </Flex>
    </div>
  );
}
