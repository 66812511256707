import { createAction, createReducer } from "@reduxjs/toolkit";

import { TRootState } from "./store";

export function plainBreadcrumb(text: string): Breadcrumb {
  return {
    type: "plain",
    text,
  };
}

export function urlBreadcrumb(text: string, url: string): Breadcrumb {
  return {
    type: "url",
    text,
    url,
  };
}

type Breadcrumb =
  | { type: "plain"; text: string }
  | { type: "url"; text: string; url: string };

type TState = {
  menuOpen: boolean;
  recentlyVisitedOpen: boolean;
  breadcrumbs: Breadcrumb[];
};

const initialState: TState = {
  menuOpen: false,
  recentlyVisitedOpen: false,
  breadcrumbs: [],
};

export const setMenuOpen = createAction<boolean>("layout/setMenuOpen");
export const setRecentlyVisitedOpen = createAction<boolean>(
  "layout/setRecentlyVisitedOpen"
);

export const setBreadcrumbs = createAction<Breadcrumb[]>(
  "layout/setBreadcrumbs"
);

export const layoutReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(setMenuOpen, (state, action) => {
      state.menuOpen = action.payload;
    })
    .addCase(setRecentlyVisitedOpen, (state, action) => {
      state.recentlyVisitedOpen = action.payload;
    })
    .addCase(setBreadcrumbs, (state, action) => {
      state.breadcrumbs = action.payload;
    });
});

export const layoutStateSelector = (state: TRootState): TState =>
  state.layoutReducer;
