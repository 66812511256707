import React from "react";

import { TCloudProvider } from "../../../server/src/api/ide/ideApi";

export default function CloudProvider(props: { value: TCloudProvider }) {
  let color = "#ff9900"; // aws

  if (props.value === "az") {
    color = "#007fff";
  }

  return <span style={{ fontWeight: "bold", color }}>{props.value}</span>;
}
