import { Flex, Box } from "@radix-ui/themes";
import React from "react";

import styles from "./UsageBar.module.scss";
import classNames from "classnames";

export type TUsageBarProps = {
  used: number;
};

export const UsageBar: React.FunctionComponent<TUsageBarProps> = ({
  used,
}: TUsageBarProps): JSX.Element => {
  return (
    <Flex width="100%" height="4" className={styles.usageBar}>
      <Box
        style={{ flexBasis: `${used}%` }}
        height="100%"
        className={classNames({
          [styles.used]: used <= 50,
          [styles.usedWarning]: used > 50 && used <= 90,
          [styles.usedDanger]: used > 90,
        })}
      ></Box>
    </Flex>
  );
};
