import React, { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as layout from "../app/layoutReducer";
import styles from "./InstanceSearchPage.module.scss";
import {
  instanceColsSelector,
  toggleInstanceColumn,
} from "../app/userPreferencesReducer";
import { InstanceSearch } from "../components/InstanceSearch/InstanceSearch";
import { DESC } from "../consts";

export function InstanceSearchPage() {
  const instanceCols = useAppSelector(instanceColsSelector);

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    dispatch(
      layout.setBreadcrumbs([layout.plainBreadcrumb("Search instances")])
    );

    function handleFocus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    // focus search input on page load
    handleFocus();
  }, []);

  return (
    <div className={styles.searchPage}>
      <InstanceSearch
        showRegions={true}
        inputRef={inputRef}
        activeColumns={instanceCols}
        toggleColumn={(col) => dispatch(toggleInstanceColumn(col))}
        defaultSort={{
          created_at: DESC,
        }}
        autoRefetch
      />
    </div>
  );
}
