import React from "react";
import { UpdateIcon } from "@radix-ui/react-icons";
import { Flex, Text, Switch, Select } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { TActivityApiPayload } from "../../../../../server/src/health/types";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  THealthState,
  healthStateSelector,
  getActivityApiData,
} from "../../../app/healthPageReducer";
import refreshIntervalConstants, {
  defaultRefreshInterval,
} from "../../constants/refreshIntervalConstants";
import Button from "../../../components/Button/Button";

import styles from "./AppsRefresh.module.scss";

export const AppsRefresh: React.FunctionComponent = (): JSX.Element => {
  const healthState: THealthState = useAppSelector(healthStateSelector);
  const { activityApiResponse } = healthState;

  const dispatch = useAppDispatch();

  const { instanceName } = useParams<string>();

  const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(true);
  const [refreshInterval, setRefreshInterval] = useState<number>(
    defaultRefreshInterval
  );

  const refreshIntervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearInterval(refreshIntervalRef.current);

    if (isAutoRefresh)
      refreshIntervalRef.current = setInterval(
        () => refreshApps(),
        refreshInterval
      );
  }, [isAutoRefresh, refreshInterval]);

  const refreshApps = (): void => {
    const activityApiPayload: TActivityApiPayload = {
      instanceName: instanceName!,
      timestampStart: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
      timestampEnd: "",
    };

    dispatch(getActivityApiData(activityApiPayload));
  };

  const onRefreshModeChange = (isAutoRefresh: boolean): void => {
    setIsAutoRefresh(isAutoRefresh);
  };

  const onRefreshButtonClick = (): void => refreshApps();

  const onRefreshIntervalSelection = (refreshInterval: string): void => {
    setRefreshInterval(+refreshInterval);
  };

  return (
    <Flex direction="row" align="center" gap="4">
      <Flex direction="row" align="center" gap="2">
        <Text as="label">Auto refresh</Text>
        <Switch
          defaultChecked={isAutoRefresh}
          onCheckedChange={onRefreshModeChange}
        />
      </Flex>

      <Select.Root
        defaultValue={String(defaultRefreshInterval)}
        disabled={activityApiResponse.tag === "Loading" || !isAutoRefresh}
        onValueChange={onRefreshIntervalSelection}
      >
        <Select.Trigger />
        <Select.Content position="popper">
          {refreshIntervalConstants.map((refreshInterval) => (
            <Select.Item
              key={refreshInterval}
              value={String(refreshInterval)}
            >{`${refreshInterval / 1000} Seconds`}</Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <Button
        variant="soft"
        color="grass"
        disabled={activityApiResponse.tag === "Loading" || isAutoRefresh}
        className={styles.refreshButton}
        onClick={onRefreshButtonClick}
      >
        <UpdateIcon />
        &nbsp;Refresh
      </Button>
    </Flex>
  );
};
