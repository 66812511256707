import React, { useEffect, useState, useRef } from "react";
import {
  DropdownMenu,
  Flex,
  Select,
  TextField,
  Text,
  Checkbox,
} from "@radix-ui/themes";
import cn from "classnames";
import { TriangleDownIcon, ReloadIcon } from "@radix-ui/react-icons";

import Button from "../Button/Button";
import { Error } from "../Error";
import { PageLoading } from "../PageLoading/PageLoading";
import ProvisionStatus from "../ProvisionStatus";
import { Pagination } from "../Pagination/Pagination";
import {
  ColumnId,
  InstanceTable,
  allColumns,
} from "../InstanceTable/InstanceTable";
import { useSearchInstancesQuery } from "../../app/api/ide/ideApiBase";
import {
  TInstanceFilterFields,
  TInstanceFilterRequest,
  TInstanceFilterSort,
} from "../../../../server/src/api/ide/ideApi";
import { paginationStatus } from "../../app/pagination";
import Spinner from "../Spinner/Spinner";

import styles from "./InstanceSearch.module.scss";

// TODO remove hardcoding
export const regions = [
  "ap-south-1",
  "us-east-1",
  "germanywestcentral",
  "ap-southeast-1",
  "eastus",
  "singapore",
  "centralindia",
];

const PAGE_SIZE = 50;

// prepare search params used in search request
function prepareSearchBody(inputs: {
  region: string;
  query: string;
  status: string[];
  fixedFilters?: TInstanceFilterFields;
  pageNumber: number;
  sort: TInstanceFilterSort;
}) {
  const region = inputs.region === "all" ? null : inputs.region;

  const searchParams: TInstanceFilterRequest = {
    pagination: {
      page: inputs.pageNumber,
      page_size: PAGE_SIZE,
    },
    sort: inputs.sort,
  };

  let filters = {} as TInstanceFilterFields;

  if (inputs.status.length) {
    filters.provision_status = {
      op: "in",
      value: inputs.status,
    };
  }

  filters = {
    ...filters,
    ...(inputs.fixedFilters || {}),
  };

  if (region) {
    filters.region = {
      op: "eq",
      value: region,
    };
  }

  if (inputs.query) {
    searchParams.search = inputs.query.trim();
  }

  return {
    ...searchParams,
    filters,
    sort: inputs.sort,
  };
}

type Props = {
  showRegions: boolean;
  fixedFilters?: TInstanceFilterFields;
  inputRef?: React.RefObject<HTMLInputElement>;
  activeColumns: ColumnId[];
  toggleColumn: (ColumnId) => void;
  autoRefetch?: boolean;
  defaultStatus?: string[];
  defaultSort?: TInstanceFilterSort;
};

export const InstanceSearch = (props: Props) => {
  const [queryInput, setQueryInput] = useState("");
  const [query, setQuery] = useState("");
  const [statusList, setStatusList] = useState<string[]>([]);
  const [region, setRegion] = useState("all");
  const [pageNumber, setPageNumber] = useState(1);
  const [sort, setSort] = useState<TInstanceFilterSort>({});
  const [freshSearch, setFreshSearch] = useState(false);

  const refreshTimeoutRef = useRef<any | null>(null);

  const { data, isLoading, isFetching, isSuccess, refetch, isError, error } =
    useSearchInstancesQuery({
      instanceFilterRequest: prepareSearchBody({
        query,
        region,
        status: statusList,
        fixedFilters: props.fixedFilters,
        pageNumber,
        sort,
      }),
    });

  // refresh every x seconds after last request
  useEffect(() => {
    if (isFetching) {
      clearTimeout(refreshTimeoutRef.current);
    } else {
      refreshTimeoutRef.current = setTimeout(() => {
        if (props.autoRefetch) {
          refetch();
        }
      }, 5000);
    }

    return () => {
      clearTimeout(refreshTimeoutRef.current);
    };
  }, [isFetching]);

  useEffect(() => {
    if (props.defaultStatus) {
      setStatusList(props.defaultStatus);
    }
  }, [props.defaultStatus]);

  useEffect(() => {
    if (props.defaultSort) {
      setSort(props.defaultSort);
    }
  }, [props.defaultSort]);

  function resetSearch() {
    setQuery("");
    setQueryInput("");
    setRegion("all");
    setStatusList([]);
    setSort({});

    search();
  }

  async function search() {
    setQuery(queryInput);

    setFreshSearch(true);
    await refetch();
    setFreshSearch(false);
  }

  function setStatus(status) {
    const statusAlreadyActive = statusList.includes(status);

    if (statusAlreadyActive) {
      // toggle off
      setStatusList(statusList.filter((item) => item !== status));
    } else {
      setStatusList([...statusList, status]);
    }
  }

  const statusInactive = (status) => !statusList.includes(status);

  let results = <></>;

  if (isError) {
    results = (
      <div className={styles.error}>
        <Error>{JSON.stringify(error, null, 4)}</Error>
      </div>
    );
  }

  if (isSuccess) {
    const searchResults = data.data || ([] as any);

    const ps = paginationStatus(
      {
        pageNumber: data.pagination.page as number,
        pageSize: data.pagination.page_size as number,
      },
      data.pagination.total_results
    );

    let pagination = <></>;

    if (ps) {
      pagination = <Pagination status={ps} paginate={setPageNumber} />;
    }

    let reloadButton = <ReloadIcon onClick={() => refetch()} />;

    if (isFetching) {
      reloadButton = <Spinner />;
    }

    results = (
      <Flex
        direction="column"
        gap="2"
        data-testid={`table-page-${ps?.pageNumber}`}
      >
        <Flex justify="between">
          <Flex gap="2">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <div>
                  <Button variant="soft">
                    Columns
                    <TriangleDownIcon />
                  </Button>
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <Flex gap="2" direction="column" className={styles.colList}>
                  {allColumns.map((col) => {
                    return (
                      <div
                        key={col}
                        onClick={() => props.toggleColumn(col)}
                        style={{ cursor: "pointer" }}
                      >
                        <Text size="2">
                          <Flex gap="2">
                            <Checkbox
                              checked={props.activeColumns.includes(col)}
                            />
                            {col}
                          </Flex>
                        </Text>
                      </div>
                    );
                  })}
                </Flex>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            <div
              className={cn(styles.reload, { [styles.clickable]: !isLoading })}
            >
              {reloadButton}
            </div>
          </Flex>
          {pagination}
        </Flex>
        <InstanceTable
          instances={searchResults}
          onSort={setSort}
          activeColumns={props.activeColumns}
          activeSort={sort}
          baseTabIndex={2}
          isFetching={isFetching}
        />
      </Flex>
    );
  }

  if (isLoading || freshSearch) {
    results = <PageLoading />;
  }

  return (
    <Flex direction="column" gap="4">
      <Flex direction="column" gap="3">
        <Flex gap="3">
          <div className={styles.searchInput}>
            <TextField.Input
              placeholder="Search..."
              tabIndex={1}
              onInput={(e) => setQueryInput(e.currentTarget.value)}
              ref={props.inputRef}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
              data-testid="search-input"
              value={queryInput}
            />
          </div>
          {props.showRegions && (
            <Select.Root
              value={region}
              onValueChange={(value) => setRegion(value)}
            >
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="all">All regions</Select.Item>
                <Select.Separator />
                {regions.map((region) => {
                  return (
                    <Select.Item value={region} key={region}>
                      {region}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select.Root>
          )}
          <Flex gap="2">
            <Button
              tabIndex={2}
              onClick={() => search()}
              pending={isLoading}
              data-testid="search-button"
            >
              Search
            </Button>
            <Button color="crimson" onClick={() => resetSearch()}>
              Clear
            </Button>
          </Flex>
        </Flex>
        <Flex gap="2">
          <ProvisionStatus
            className={cn(styles.status, {
              [styles.statusInactive]: statusInactive("completed"),
            })}
            onClick={() => setStatus("completed")}
            size="1"
            status="completed"
          />
          <ProvisionStatus
            className={cn(styles.status, {
              [styles.statusInactive]: statusInactive("in_progress"),
            })}
            onClick={() => setStatus("in_progress")}
            size="1"
            status="in_progress"
          />
          <ProvisionStatus
            className={cn(styles.status, {
              [styles.statusInactive]: statusInactive("archived"),
            })}
            onClick={() => setStatus("archived")}
            size="1"
            status="archived"
          />
          <ProvisionStatus
            className={cn(styles.status, {
              [styles.statusInactive]: statusInactive("failed"),
            })}
            onClick={() => setStatus("failed")}
            size="1"
            status="failed"
          />
          <ProvisionStatus
            className={cn(styles.status, {
              [styles.statusInactive]: statusInactive("deleted"),
            })}
            onClick={() => setStatus("deleted")}
            size="1"
            status="deleted"
          />
          <ProvisionStatus
            className={cn(styles.status, {
              [styles.statusInactive]: statusInactive("soft_destroy"),
            })}
            onClick={() => setStatus("soft_destroy")}
            size="1"
            status="soft_destroy"
          />
        </Flex>
      </Flex>
      {results}
    </Flex>
  );
};
