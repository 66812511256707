import React from "react";
import cn from "classnames";

import Spinner from "../Spinner/Spinner";
import styles from "./Spinable.module.scss";

export function Spinable(props: React.PropsWithChildren<{ pending: boolean }>) {
  return (
    <div className={styles.wrap}>
      <div className={cn({ [styles.hideContent]: props.pending })}>
        {props.children}
      </div>
      <div
        className={cn(styles.spinner, {
          [styles.hideSpinner]: props.pending !== true,
        })}
      >
        <Spinner />
      </div>
    </div>
  );
}
