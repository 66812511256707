import React from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Dialog } from "@radix-ui/themes";

import styles from "./Modal.module.scss";

export function Modal(
  props: React.PropsWithChildren<{
    close: () => void;
    open: boolean;
  }>
) {
  const { close, open } = props;

  return (
    <Dialog.Root open={open}>
      <Dialog.Content
        onPointerDownOutside={() => close()}
        onEscapeKeyDown={() => close()}
        className={styles.content}
      >
        {props.children}
        <div className={styles.closeBtn} onClick={() => close()}>
          <Cross2Icon />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
