import React from "react";
import { TriangleUpIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import cn from "classnames";

import styles from "./TableSort.module.scss";
import { ASC, DESC } from "../../consts";

type SortArrowsProps = {
  onUpClick: () => void;
  onDownClick: () => void;
  upActive?: boolean;
  downActive?: boolean;
  sortHint?: string;
};

// Dumb reusable up/down arrow component
export function SortArrows(props: SortArrowsProps) {
  let upProps = {};
  let downProps = {};

  if (props.sortHint) {
    const testIdBase = props.sortHint
      .toLocaleLowerCase()
      .split(/\s+/)
      .join("-");

    upProps = {
      ["data-testid"]: `sort-${testIdBase}-asc`,
      ["data-active"]: props.upActive,
      title: `${props.sortHint} asc`,
    };

    downProps = {
      ["data-testid"]: `sort-${testIdBase}-desc`,
      ["data-active"]: props.downActive,
      title: `${props.sortHint} desc`,
    };
  }

  return (
    <div className={styles.tableSort}>
      <div
        onClick={props.onUpClick}
        className={cn(styles.arrow, { [styles.active]: props.upActive })}
        {...upProps}
      >
        <TriangleUpIcon />
      </div>
      <div
        onClick={props.onDownClick}
        {...downProps}
        className={cn(styles.arrow, { [styles.active]: props.downActive })}
      >
        <TriangleDownIcon />
      </div>
    </div>
  );
}

type SortableProps = {
  field: string;
  activeSort?: { [field: string]: string };
  onSortChange: (data: { [field: string]: string }) => void;
  children: React.ReactNode;
} & Pick<SortArrowsProps, "sortHint">;

export function Sortable(props: SortableProps) {
  let upActive = false;
  let downActive = false;

  const { activeSort } = props;

  if (activeSort && activeSort[props.field]) {
    upActive = activeSort[props.field] === ASC;
    downActive = activeSort[props.field] === DESC;
  }

  const sortHint = props.sortHint ? props.sortHint : props.field;

  return (
    <div className={styles.sortable}>
      <div className={styles.label}>{props.children}</div>
      <SortArrows
        {...props}
        onUpClick={() => props.onSortChange({ [props.field]: ASC })}
        onDownClick={() => props.onSortChange({ [props.field]: DESC })}
        upActive={upActive}
        downActive={downActive}
        sortHint={sortHint}
      />
    </div>
  );
}
