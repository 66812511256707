import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import cn from "classnames";

import { Table } from "@radix-ui/themes";
import { chatApi } from "./chatApi";

export const RateLimitTable = () => {
  const { useGetRateLimitsQuery } = chatApi;

  const { isLoading, isFetching, data } = useGetRateLimitsQuery({
    today: true,
    maxed: true,
  });

  const rows = data || [];

  return (
    <Table.Root
      variant="surface"
      data-testid="results-table"
      className={cn({ isFetching: isLoading || isFetching })}
    >
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Hostname</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Request count</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Rate limit</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Last reset</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row) => (
          <Table.Row key={row.hostname}>
            <Table.Cell>
              <Link to={"/chat/" + row.hostname}>{row.hostname}</Link>
            </Table.Cell>
            <Table.Cell>{row.request_count}</Table.Cell>
            <Table.Cell>{row.request_limit}</Table.Cell>
            <Table.Cell>
              {format(new Date(row.last_reset), "dd-MM-yyyy")}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};
