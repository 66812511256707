import { Box, Flex, Heading, Separator } from "@radix-ui/themes";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SRD } from "srd";

import { MESSAGE_API_KEY_ERROR } from "../../../server/src/constants";
import { TActivityApiPayload } from "../../../server/src/health/types";
import {
  getActivityApiData,
  healthStateSelector,
  THealthState,
} from "../app/healthPageReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";

import { IdeApps } from "./components/IdeApps/IdeApps";
import { IdeDetails } from "./components/IdeDetails/IdeDetails";
import { IdeEvents } from "./components/IdeEvents/IdeEvents";
import styles from "./HealthPage.module.scss";

export const HealthPage: React.FunctionComponent = (): JSX.Element => {
  const { activityApiResponse } =
    useAppSelector<THealthState>(healthStateSelector);

  const navigate = useNavigate();
  const { instanceName } = useParams<string>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const activityApiPayload: TActivityApiPayload = {
      instanceName: instanceName!,
      timestampStart: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
      timestampEnd: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
    };

    dispatch(getActivityApiData(activityApiPayload));
  }, []);

  useEffect(() => {
    if (
      SRD.isFailure(activityApiResponse) &&
      activityApiResponse.error === MESSAGE_API_KEY_ERROR
    ) {
      axios.post("/public-api/logout");
      navigate("/login");
    }
  }, [activityApiResponse]);

  return (
    <Flex direction="column" width="100%" p="4" className={styles.healthPage}>
      <Heading mb="4">IDE Health</Heading>

      <Separator orientation="horizontal" size="4" mb="4" />

      <IdeDetails />

      <Flex
        direction="row"
        gap="4"
        grow="1"
        p="4"
        className={styles.eventsAndAppsContainer}
      >
        <Box className={styles.eventsContainer}>
          <IdeEvents />
        </Box>

        <Separator orientation="vertical" size="4" />

        <Box className={styles.appsContainer}>
          <IdeApps />
        </Box>
      </Flex>
    </Flex>
  );
};
