export const apiProxyBasePath = "/api/proxy";
export const apiProxyActivity = "/activity";
export const apiProxySelfService = "/self-service";
export const apiProxyIde = "/ide";
export const apiProxyReports = "/reports";

export const apiProxyActivityPath = `${apiProxyBasePath}${apiProxyActivity}`;
export const apiProxyIdePath = `${apiProxyBasePath}${apiProxyIde}`;
export const apiProxyReportsPath = `${apiProxyBasePath}${apiProxyReports}`;
export const apiProxySelfServicePath = `${apiProxyBasePath}${apiProxySelfService}`;
