import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, SRD, success } from "srd";

import { TApiUserRead } from "../../../server/src/api/ide/ideApi";
import { TRootState } from "./store";
import { ideApi } from "../api";

type TState = {
  rows: RD<string, TApiUserRead[]>;
  afkWorker: { [id: number]: RD<string, null> };
  slackAlerts: { [id: number]: RD<string, null> };
  failedCreationAlerts: { [id: number]: RD<string, null> };
};

const initialState: TState = {
  rows: notAsked(),
  afkWorker: {},
  slackAlerts: {},
  failedCreationAlerts: {},
};

export const fetch = createAsyncThunk("apiUsers/fetch", async () => {
  const response = await ideApi.get_api_users_v1_api_users_get({
    queries: {
      page_size: 200,
    },
  });

  return response;
});

export const setAfkWorker = createAsyncThunk(
  "apiUsers/setAfkWorker",
  async ({ id, value }: { id: number; value: boolean }) => {
    const response = await ideApi.edit_api_user_v1_api_users__api_user_id__put(
      { afk_worker: value },
      {
        params: {
          api_user_id: id,
        },
      }
    );
    return response.data;
  }
);

export const setSlackAlerts = createAsyncThunk(
  "apiUsers/setSlackAlerts",
  async ({ id, value }: { id: number; value: boolean }) => {
    const response = await ideApi.edit_api_user_v1_api_users__api_user_id__put(
      { enable_slack_alerts: value },
      {
        params: {
          api_user_id: id,
        },
      }
    );
    return response.data;
  }
);

export const setFailedCreationAlerts = createAsyncThunk(
  "apiUsers/setFailedCreateAlerts",
  async ({ id, value }: { id: number; value: boolean }) => {
    const response = await ideApi.edit_api_user_v1_api_users__api_user_id__put(
      { enable_failed_creation_alerts: value },
      {
        params: {
          api_user_id: id,
        },
      }
    );
    return response.data;
  }
);

export const apiUsersPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetch.pending, (state) => {
      state.rows = loading();
    })
    .addCase(fetch.rejected, (state, action) => {
      // TODO log to sentry
      console.log(action);
      state.rows = failure("Something went wrong");
    })
    .addCase(fetch.fulfilled, (state, action) => {
      state.rows = success(action.payload.data || []);
    })
    .addCase(setAfkWorker.pending, (state, action) => {
      const id = action.meta.arg.id;

      state.afkWorker[id] = loading();
    })
    .addCase(setAfkWorker.rejected, (state, action) => {
      const id = action.meta.arg.id;
      // TODO log error to sentry
      state.afkWorker[id] = failure("Something went wrong");
    })
    .addCase(setAfkWorker.fulfilled, (state, action) => {
      const { id, value } = action.meta.arg;

      state.afkWorker[id] = success(null);

      state.rows = updateRowById(state.rows, id, (row) => {
        return {
          ...row,
          afk_worker: value,
        };
      });
    })
    .addCase(setSlackAlerts.pending, (state, action) => {
      const id = action.meta.arg.id;

      state.slackAlerts[id] = loading();
    })
    .addCase(setSlackAlerts.rejected, (state, action) => {
      const id = action.meta.arg.id;
      // TODO log error to sentry
      state.slackAlerts[id] = failure("Something went wrong");
    })
    .addCase(setSlackAlerts.fulfilled, (state, action) => {
      const { id, value } = action.meta.arg;

      state.slackAlerts[id] = success(null);

      state.rows = updateRowById(state.rows, id, (row) => {
        return {
          ...row,
          enable_slack_alerts: value,
        };
      });
    })
    .addCase(setFailedCreationAlerts.pending, (state, action) => {
      const id = action.meta.arg.id;

      state.failedCreationAlerts[id] = loading();
    })
    .addCase(setFailedCreationAlerts.rejected, (state, action) => {
      const id = action.meta.arg.id;
      // TODO log error to sentry
      state.failedCreationAlerts[id] = failure("Something went wrong");
    })
    .addCase(setFailedCreationAlerts.fulfilled, (state, action) => {
      const { id, value } = action.meta.arg;

      state.failedCreationAlerts[id] = success(null);

      state.rows = updateRowById(state.rows, id, (row) => {
        return {
          ...row,
          enable_failed_creation_alerts: value,
        };
      });
    });
});

function updateRowById(
  data: RD<string, TApiUserRead[]>,
  id: number,
  fn: (item: TApiUserRead) => TApiUserRead
) {
  return SRD.map(
    (rows) => {
      return rows.map((item) => {
        if (item.id === id) {
          return fn(item);
        }

        return item;
      });
    },

    data
  );
}

export const stateSelector = (state: TRootState): TState =>
  state.apiUsersPageReducer;
