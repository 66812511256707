import React, { useEffect, useRef } from "react";
import { SRD } from "srd";
import { Flex, Table, TextField } from "@radix-ui/themes";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { paginationStatus } from "../app/pagination";

import {
  stateSelector,
  paginateToPage,
  clear,
  fetchCountryCodes,
  gotFilter,
  filterPaginateCountryCodes,
} from "../app/countryCodesPageReducer";
import Button from "../components/Button/Button";
import { Error } from "../components/Error";
import styles from "./CountryCodesPage.module.scss";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { Pagination } from "../components/Pagination/Pagination";
import * as layout from "../app/layoutReducer";

export function CountryCodesPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(stateSelector);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([layout.plainBreadcrumb("Country codes")]));

    function handleFocus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    // focus search input on page load
    handleFocus();
    dispatch(fetchCountryCodes());
  }, []);

  return (
    <div className={styles.page}>
      <Flex gap="4" direction="column">
        <Flex gap="2">
          <div className={styles.searchInput}>
            <TextField.Input
              placeholder="Filter..."
              tabIndex={1}
              onInput={(e) => dispatch(gotFilter(e.currentTarget.value))}
              ref={inputRef}
              value={state.filterQuery}
              data-testid="search-input"
            />
          </div>
          <Button color="crimson" onClick={() => dispatch(clear())}>
            Clear
          </Button>
        </Flex>

        <div>
          {SRD.match(
            {
              notAsked: () => <div></div>,
              loading: () => <PageLoading />,
              failure: (msg) => (
                <div className={styles.error}>
                  <Error>{msg}</Error>
                </div>
              ),

              success: (results) => {
                const filtered = filterPaginateCountryCodes(state, results);

                const ps = paginationStatus(state, filtered.total);

                let pagination = <></>;

                if (ps) {
                  pagination = (
                    <Pagination
                      status={ps}
                      paginate={(nextPage) =>
                        dispatch(paginateToPage(nextPage))
                      }
                    />
                  );
                }

                return (
                  <>
                    <div className={styles.topPagination}>{pagination}</div>
                    <Table.Root variant="surface" data-testid="results-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColumnHeaderCell>
                            Country
                          </Table.ColumnHeaderCell>
                          <Table.ColumnHeaderCell>
                            Country Code
                          </Table.ColumnHeaderCell>
                          <Table.ColumnHeaderCell>
                            Region
                          </Table.ColumnHeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {filtered.rows.map((row) => (
                          <Table.Row key={row.id}>
                            <Table.RowHeaderCell>
                              {row.country}
                            </Table.RowHeaderCell>
                            <Table.RowHeaderCell>
                              {row.country_code}
                            </Table.RowHeaderCell>
                            <Table.Cell>{row.region}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table.Root>
                    <div
                      className={styles.bottomPagination}
                      data-testid={`page-${ps.pageNumber}`}
                    >
                      {pagination}
                    </div>
                  </>
                );
              },
            },
            state.countryCodes
          )}
        </div>
      </Flex>
    </div>
  );
}
