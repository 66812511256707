import React, { useEffect, useState } from "react";
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInSeconds,
} from "date-fns";

import { Link } from "react-router-dom";
import {
  addStorageListener,
  getListFromLocalStorage,
} from "../localStorageList";
import {
  RECENTLY_VISTED_STORAGE_KEY,
  sortByTimestamp,
  TVisitedPad,
} from "./recentlyVisited";

import styles from "./RecentlyVisitedPanel.module.scss";
import { Flex } from "@radix-ui/themes";

const PadLink = (props: { id: string }) => {
  const id = props.id;
  const prefix = id.slice(0, 4);
  const rest = id.slice(4);

  return (
    <Link to={`instance/${id}`} className={styles.link}>
      <span className={styles.prefix}>{prefix}</span>
      {rest}
    </Link>
  );
};

export const RecentlyVisited = () => {
  const [recent, setRecent] = useState<TVisitedPad[]>(() => {
    return getListFromLocalStorage(RECENTLY_VISTED_STORAGE_KEY);
  });

  useEffect(() => {
    const removeListener = addStorageListener(
      RECENTLY_VISTED_STORAGE_KEY,
      (newValue) => {
        setRecent(newValue);
      }
    );

    // clean up the listener when the component unmounts
    return removeListener;
  }, []);

  const sortedTimestampPads = sortByTimestamp(recent);

  let el: any = (
    <div data-testid="no-recently-visited">No recently visited</div>
  );

  if (sortedTimestampPads.length > 0) {
    el = sortedTimestampPads.map((pad) => (
      <Flex gap="3" key={pad.id}>
        <PadLink id={pad.id} />
        <div>{formatCompactTimeDistance(pad.timestamp)}</div>
      </Flex>
    ));
  }

  return <div className={styles.content}>{el}</div>;
};

export function formatCompactTimeDistance(date) {
  const now = new Date();
  const secondsDiff = differenceInSeconds(now, date);
  const minutesDiff = differenceInMinutes(now, date);
  const hoursDiff = differenceInHours(now, date);
  const daysDiff = differenceInDays(now, date);

  if (daysDiff > 1) {
    return `${daysDiff}d ago`;
  } else if (daysDiff === 1) {
    return "1d ago";
  } else if (hoursDiff >= 1) {
    const remainingMinutes = minutesDiff % 60;
    return `${hoursDiff}h ${remainingMinutes}m ago`;
  } else if (minutesDiff >= 1) {
    return `${minutesDiff}m ago`;
  } else {
    return `${secondsDiff}s ago`;
  }
}
