import {
  createAsyncThunk,
  createReducer,
  createAction,
} from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success, SRD } from "srd";
import * as dateFns from "date-fns";

import { TActivityApiResponse } from "../../../server/src/health/types";
import { activityApi, ideApi } from "../api";
import {
  TInstanceListResponse,
  TInstanceRead,
} from "../../../server/src/api/ide/types";
import { TIsActiveResponse } from "../../../server/src/api/activity/activityApi";

export type TCpuTimeOption = { label: string; value: dateFns.Duration };

export const past1Hour = { label: "1h", value: { hours: 1 } };
export const past8Hour = { label: "8h", value: { hours: 8 } };
export const past24Hour = { label: "24h", value: { hours: 24 } };

type TState = {
  instanceName: string;
  instance: RD<string, TInstanceRead>;
  cpuTimeOption: TCpuTimeOption;
  activityApiResponse: RD<string, TActivityApiResponse>;
  isActiveResponse: RD<string, TIsActiveResponse>;
  // We want to save disk usage after first activity api fetch, then store the result
  // and not refetch when the cpu history is changed
  disk: RD<string, string>;
};

const initialState: TState = {
  instanceName: "",
  instance: notAsked(),
  cpuTimeOption: past24Hour,
  activityApiResponse: notAsked(),
  isActiveResponse: notAsked(),
  disk: notAsked(),
};

export const setInstanceName = createAction<string>(
  "instancePage/setInstanceName"
);

export const fetchInstance = createAsyncThunk(
  "instancePage/fetchInstance",
  async (args, { getState }) => {
    const state: TState = (getState() as any).instancePageReducer;

    const res = await ideApi.search_instances_v1_instances_search_post({
      pagination: {
        page: 1,
        page_size: 1,
      },
      filters: {
        instance_name: {
          op: "eq",
          value: state.instanceName,
        },
      },
    });

    return res;
  }
);

export const fetchActivity = createAsyncThunk(
  "instancePage/fetchActivity",
  async (args, { getState }) => {
    const state: TState = (getState() as any).instancePageReducer;

    const now = new Date();

    const timestampStart = dateFns
      .sub(now, state.cpuTimeOption.value)
      .toISOString();

    const response =
      await activityApi.get_deployment_health_check_v1_deployment__deployhash__health_check_get(
        {
          params: { deployhash: state.instanceName },
          queries: { start: timestampStart },
        }
      );

    return response;
  }
);

export const fetchIsActive = createAsyncThunk(
  "instancePage/fetchIsActive",
  async (args, { getState }) => {
    const state: TState = (getState() as any).instancePageReducer;

    const response =
      await activityApi.get_is_active_v1_deployment__deployhash__is_active_get({
        params: { deployhash: state.instanceName },
      });

    return response;
  }
);

export function gotCpuTimeChange(cpuTimeOption: TCpuTimeOption) {
  return (dispatch) => {
    dispatch(setCpuTime(cpuTimeOption));
    dispatch(fetchActivity());
  };
}

export const setCpuTime = createAction<TCpuTimeOption>(
  "instancePage/setCpuTime"
);

export const gotPipelinePageNumber = createAction<number>(
  "instancePage/gotPipelinePageNumber"
);

export const instancePageReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(setInstanceName, (state, action) => {
      state.instanceName = action.payload;
    })
    .addCase(fetchInstance.pending, (state) => {
      state.instance = loading();
    })
    .addCase(fetchInstance.rejected, (state, action) => {
      console.log(action);

      // TODO check for real error
      state.instance = failure("something went wrong");
    })
    .addCase(fetchInstance.fulfilled, (state, action) => {
      const res = action.payload as TInstanceListResponse;

      if (res.data?.[0]) {
        state.instance = success(res.data[0]);
      } else {
        state.instance = failure("Not found");
      }
    })
    .addCase(setCpuTime, (state, action) => {
      state.cpuTimeOption = action.payload;
    })
    .addCase(fetchActivity.pending, (state) => {
      state.activityApiResponse = loading();

      if (diskNotSet(state)) {
        state.disk = loading();
      }
    })
    .addCase(fetchActivity.rejected, (state) => {
      // TODO check for real error
      const error = failure("something went wrong");

      state.activityApiResponse = error;

      if (diskNotSet(state)) {
        state.disk = error;
      }
    })
    .addCase(fetchActivity.fulfilled, (state, action) => {
      const activity: TActivityApiResponse = action.payload as any;

      state.activityApiResponse = success(activity);

      // store disk seperatly if not set
      if (diskNotSet(state)) {
        if (activity.data && activity.data[0]) {
          state.disk = success(activity.data[0].instance.disk);
        } else {
          state.disk = success("");
        }
      }
    })
    .addCase(fetchIsActive.pending, (state) => {
      state.isActiveResponse = loading();
    })
    .addCase(fetchIsActive.rejected, (state) => {
      // Deleted instances will return a http error, so if something goes wrong just act we never asked
      state.isActiveResponse = notAsked();
    })
    .addCase(fetchIsActive.fulfilled, (state, action) => {
      state.isActiveResponse = success(action.payload);
    });
});

function diskNotSet(state: TState) {
  return SRD.isNotAsked(state.disk) || SRD.isLoading(state.disk);
}

export const stateSelector = (state): TState => state.instancePageReducer;
