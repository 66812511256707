import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Card, Flex, Heading, TextField, Text, Badge } from "@radix-ui/themes";
import { chatApi } from "../chatApi";
import * as layout from "../../app/layoutReducer";
import Spinner from "../../components/Spinner/Spinner";
import Button from "../../components/Button/Button";
import { RateLimitTable } from "../RateLimitTable";
import { useAppDispatch } from "../../app/hooks";

import styles from "./ChatControlPanelPage.module.scss";

export const ChatControlPanelPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([]));
  }, []);

  return (
    <div className={styles.pageWrap}>
      <Flex gap="5" direction="column">
        <Flex justify="between">
          <Heading as="h5">Chat control panel</Heading>
          <EnableChat />
        </Flex>
        <Card>
          <Flex gap="5" direction="column">
            <Flex direction="column" gap="2">
              <Text>Daily request limit</Text>
              <RequestLimit />
            </Flex>
            <Flex direction="column" gap="2">
              <Text>Clients at daily request limit</Text>
              <RateLimitTable />
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </div>
  );
};

const EnableChat = () => {
  const { useGetChatEnabledQuery, usePostChatEnabledMutation } = chatApi;

  const [postChatEnabled] = usePostChatEnabledMutation();

  const { data, isSuccess, isFetching, isLoading } = useGetChatEnabledQuery({});

  if (isLoading) {
    return (
      <Flex align="center">
        <Spinner />
      </Flex>
    );
  }

  if (isSuccess) {
    const { enabled } = data;

    const badgeColor = enabled ? "green" : "yellow";
    const badgeText = enabled ? "Chat enabled" : "Chat disabled";

    const buttonText = enabled ? "Turn off chat" : "Turn on chat";

    return (
      <Flex gap="4" align="center">
        <Badge color={badgeColor}>{badgeText}</Badge>
        <Button
          pending={isFetching || isLoading}
          variant="soft"
          onClick={() => {
            postChatEnabled(!enabled);
          }}
        >
          {buttonText}
        </Button>
      </Flex>
    );
  }
};

const RequestLimit = () => {
  const [limit, setLimit] = useState("");

  const { useGetDailyRateLimitQuery, usePostDailyRateLimitMutation } = chatApi;

  const [postDailyRateLimit, { isLoading: postLimitLoading }] =
    usePostDailyRateLimitMutation();

  const { isLoading, isFetching, isSuccess, data } = useGetDailyRateLimitQuery(
    {}
  );

  useEffect(() => {
    if (data) {
      setLimit(data.daily_limit);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isSuccess) {
    return (
      <Flex gap="3">
        <TextField.Input
          className={cn({ isFetching })}
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        />
        <Button
          onClick={() => {
            postDailyRateLimit(limit);
          }}
          pending={postLimitLoading}
          variant="soft"
        >
          Save
        </Button>
      </Flex>
    );
  }
};
