import React, { useEffect } from "react";
import { Blockquote, TextField } from "@radix-ui/themes";
import { SRD } from "srd";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  gotPassword,
  gotUsername,
  login,
  loginStateSelector,
  reset,
} from "../app/loginPageReducer";
import Button from "../components/Button/Button";

import styles from "./LoginPage.module.scss";

export function LoginPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(loginStateSelector);

  useEffect(() => {
    return () => {
      // reset login page when we navigate away
      dispatch(reset());
    };
  }, []);

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginWrap}>
        <h1 className={styles.title}>IDEA</h1>
        <form
          onSubmit={(e) => {
            dispatch(login());
            e.preventDefault();
          }}
        >
          <div className={styles.inputRow}>
            <label htmlFor="email">Email</label>
            <TextField.Input
              id="email"
              tabIndex={1}
              value={state.username}
              onChange={(e) => dispatch(gotUsername(e.target.value))}
              data-testid="email-input"
            />
          </div>
          <label htmlFor="password">Password</label>
          <TextField.Input
            id="password"
            type="password"
            tabIndex={2}
            value={state.password}
            onChange={(e) => dispatch(gotPassword(e.target.value))}
            data-testid="password-input"
          />
          <div className={styles.error}>
            {SRD.match(
              {
                notAsked: () => <></>,
                loading: () => <></>,
                failure: (msg) => <Blockquote color="red">{msg}</Blockquote>,
                success: (response) => {
                  if (response.status !== "Success") {
                    return (
                      <Blockquote color="red">{response.status}</Blockquote>
                    );
                  }

                  return (
                    <Blockquote color="green">Login successful</Blockquote>
                  );
                },
              },
              state.loginResponse
            )}
          </div>
          <div className={styles.buttons}>
            <Button
              tabIndex={3}
              pending={SRD.isLoading(state.loginResponse)}
              type="submit"
              data-testid="submit-button"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
