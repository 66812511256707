import React from "react";
import {
  ClipboardCopyIcon,
  EnvelopeClosedIcon,
  EyeNoneIcon,
  EyeOpenIcon,
  LockClosedIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { Checkbox, Flex, Heading, Text, TextField } from "@radix-ui/themes";
import { SRD } from "srd";
import { useCopyToClipboard } from "usehooks-ts";

import {
  adminUsersPageSelector,
  closeModal,
  deleteUser,
  gotEmail,
  gotPassword,
  refreshGenPassword,
  saveClicked,
  toggleGenPassword,
  toggleShowPassword,
} from "../app/adminUsersPageReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Button from "../components/Button/Button";
import { Error } from "../components/Error";
import { FormError } from "../components/FormError/FormError";
import { Modal } from "../components/Modal/Modal";
import { TextFieldLabel } from "../components/TextFieldLabel/TextFieldLabel";

import styles from "./AddEditModal.module.scss";

export function AddEditModal() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(adminUsersPageSelector).modal;

  const copy = useCopyToClipboard()[1];

  if (state.mode.modeName === "Delete") {
    const id = state.mode.id;

    return (
      <Modal close={() => dispatch(closeModal())} open={true}>
        <Flex direction="column" gap="3">
          <Heading>Confirm delete</Heading>
          <Text>
            Are you sure you want to delete {state.fields.email.value}?
          </Text>
          <Flex gap="2">
            <Button color="red" onClick={() => dispatch(deleteUser(id))}>
              Delete
            </Button>
            <Button variant="outline" onClick={() => dispatch(closeModal())}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    );
  }

  const hasError = (error) => {
    if (error) {
      return {
        color: "red" as any,
      };
    }

    return {};
  };

  return (
    <Modal
      open={state.mode.modeName === "Edit" || state.mode.modeName === "Add"}
      close={() => dispatch(closeModal())}
    >
      <div className={styles.wrap}>
        <div className={styles.heading}>
          <Heading as="h2">
            {state.mode.modeName === "Add"
              ? "Add admin user"
              : "Edit admin user"}
          </Heading>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(saveClicked());
          }}
        >
          <div>
            <TextFieldLabel>Email</TextFieldLabel>
            <TextField.Root>
              <TextField.Slot>
                <EnvelopeClosedIcon height="16" width="16" />
              </TextField.Slot>
              <TextField.Input
                placeholder="Email address"
                value={state.fields.email.value}
                onChange={(e) => dispatch(gotEmail(e.target.value))}
                {...hasError(state.fields.email.error)}
              />
            </TextField.Root>
            <FormError error={state.fields.email.error} />
          </div>
          <div>
            <TextFieldLabel>
              {state.mode.modeName === "Edit"
                ? "Password (Leave blank to retain current password)"
                : "Password"}
            </TextFieldLabel>
            <div className={styles.password}>
              <div className={styles.passwordInput}>
                <TextField.Root>
                  <TextField.Slot>
                    <LockClosedIcon height="16" width="16" />
                  </TextField.Slot>
                  <TextField.Input
                    placeholder="Password"
                    type={state.showPassword ? "text" : "password"}
                    value={state.fields.password.value}
                    onChange={(e) => dispatch(gotPassword(e.target.value))}
                    {...hasError(state.fields.password.error)}
                  />
                </TextField.Root>
                <FormError error={state.fields.password.error} />
              </div>
              <div
                className={styles.actionIcon}
                onClick={() => dispatch(toggleShowPassword())}
              >
                {state.showPassword ? (
                  <EyeNoneIcon height="16" width="16" />
                ) : (
                  <EyeOpenIcon height="16" width="16" />
                )}
              </div>
              <div
                className={styles.actionIcon}
                onClick={() => copy(state.fields.password.value)}
              >
                <ClipboardCopyIcon height="16" width="16" />
              </div>
              {state.generatePassword && (
                <div className={styles.actionIcon}>
                  <ReloadIcon
                    height="16"
                    width="16"
                    onClick={() => dispatch(refreshGenPassword())}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <label htmlFor="genPasswordToggle">
              <Text size="2">
                <Flex gap="2" style={{ userSelect: "none" }}>
                  <Checkbox
                    checked={state.generatePassword}
                    id="genPasswordToggle"
                    onClick={() => dispatch(toggleGenPassword())}
                  />
                  Auto generate
                </Flex>
              </Text>
            </label>
          </div>
          <Flex gap="2" className={styles.buttons}>
            <Button type="submit" pending={SRD.isLoading(state.request)}>
              Save
            </Button>
            <Button onClick={() => dispatch(closeModal())} variant="outline">
              Cancel
            </Button>
          </Flex>
        </form>
        {SRD.match(
          {
            notAsked: () => <></>,
            loading: () => <></>,
            failure: (msg) => (
              <div className={styles.unexpectedError}>
                <Error>{msg}</Error>
              </div>
            ),
            success: () => <></>,
          },
          state.request
        )}
      </div>
    </Modal>
  );
}
