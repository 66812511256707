import React from "react";
import { Link } from "react-router-dom";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import cn from "classnames";

import styles from "./Menu.module.scss";

function Panel(
  props: React.PropsWithChildren<{
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    footer?: React.ReactNode;
    refProp?: React.RefObject<HTMLDivElement>;
  }>
) {
  let footer = <></>;

  if (props.footer) {
    footer = <div className={styles.footer}>{props.footer}</div>;
  }

  return (
    <div
      className={cn(styles.menu, { [styles.open]: props.isOpen })}
      ref={props.refProp}
    >
      {props.children}
      {footer}
      <div className={styles.close} onClick={() => props.setOpen(false)}>
        <Cross2Icon width="24" height="24" className={styles.menuIcon} />
      </div>
    </div>
  );
}

function Item(props: {
  icon: React.ReactNode;
  text: string;
  active: boolean;
  to: string;
}) {
  return (
    <div className={styles.itemWrap}>
      <Link to={props.to}>
        <div className={styles.item}>
          <div className={styles.itemIcon}>{props.icon}</div>
          <div>{props.text}</div>
        </div>
      </Link>
    </div>
  );
}

function ItemCol(props: React.PropsWithChildren) {
  return (
    <Flex gap="2" direction="column">
      {props.children}
    </Flex>
  );
}

function Logo() {
  return <div className={styles.logo}>IDEA</div>;
}

const Divider = () => (
  <div>
    <hr />
  </div>
);

const Menu = {
  Panel,
  Item,
  ItemCol,
  Logo,
  Divider,
};

export { Menu };
