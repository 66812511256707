import React from "react";

import { useAppSelector } from "../../app/hooks";
import {
  featureFlagSelector,
  TFeatureFlagNames,
} from "../../app/featureFlagsReducer";

export function FeatureFlag(
  props: React.PropsWithChildren<{ flagName: TFeatureFlagNames }>
) {
  const { flags } = useAppSelector(featureFlagSelector);

  if (flags[props.flagName]) {
    return props.children;
  }

  return <></>;
}
