import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import axios from "axios";
import { failure, loading, notAsked, RD, success } from "srd";

import { TLoginResponse } from "../../../server/src/auth/types";
import { router } from "../App";

type TState = {
  username: string;
  password: string;
  loginResponse: RD<string, TLoginResponse>;
  lastUrl: string | null;
};

const initialState: TState = {
  username: "",
  password: "",
  loginResponse: notAsked(),
  lastUrl: null,
};

export const reset = createAction("login/reset");
export const gotUsername = createAction<string>("login/gotUsername");
export const gotPassword = createAction<string>("login/gotPassword");

export const login = createAsyncThunk(
  "login/login",
  async (args, { getState, dispatch, rejectWithValue }) => {
    const { username, password, lastUrl } = (getState() as any)
      .loginPageReducer as TState;

    return axios
      .post("/public-api/login", {
        username,
        password,
      })
      .then((res) => {
        if (res.data.status === "Success") {
          if (lastUrl) {
            dispatch(gotLastUrl(null));
            router.navigate(lastUrl);
          } else {
            router.navigate("/");
          }
        }

        return res.data;
      })
      .catch((e) => {
        rejectWithValue(e);
      });
  }
);

export const gotLastUrl = createAction<string | null>("login/gotLastUrl");

export const loginPageReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(gotUsername, (state, action) => {
      state.username = action.payload;
    })
    .addCase(gotPassword, (state, action) => {
      state.password = action.payload;
    })
    .addCase(login.pending, (state) => {
      state.loginResponse = loading();
    })
    .addCase(login.rejected, (state) => {
      // should only happen for an unexpected error, problems with login will return a message in a 200 response
      state.loginResponse = failure("Something went wrong");
    })
    .addCase(login.fulfilled, (state, action) => {
      state.loginResponse = success(action.payload);

      if (action.payload.status === "Failure") {
        state.loginResponse = failure("Wrong username or password");
        state.password = "";
      }

      if (action.payload.status === "Success") {
        state = {
          ...initialState,
        };
      }
    })
    .addCase(reset, (state) => {
      return { ...initialState, lastUrl: state.lastUrl };
    })
    .addCase(gotLastUrl, (state, action) => {
      state.lastUrl = action.payload;
    });
});

export const loginStateSelector = (state: any): TState =>
  state.loginPageReducer;
