import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { checkForAuthError } from "../checkAuth";

const baseQuery = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    // location.origin needed for msw to work
    baseUrl: new URL(baseUrl, location.origin).href,
  });

  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    checkForAuthError(result);

    return result;
  };
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: baseQuery("/api/proxy/reports"),
  endpoints: () => ({}),
});
