import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success } from "srd";

import {
  TInstancesHealthOverviewResponse,
  // TInstancesHealthApiQueryParams,
} from "../../../server/src/healthOverview/types";
// import axios from "../axios";
import { instancesHealthOverviewResponse } from "../mocks/data/instancesHealthOverviewResponse";
import { TRootState } from "./store";

type TState = {
  instancesHealth: RD<string, TInstancesHealthOverviewResponse>;
};

const initialState: TState = {
  instancesHealth: notAsked(),
};

export const getInstancesHealth = createAsyncThunk(
  "instancesHealthOverview/getInstancesHealth",
  async (/* { cluster }: TInstancesHealthApiQueryParams */) => {
    /**
     * Mocking API endpoints till available.
     */

    // const response = await axios.get(
    //   `/api/health-overview/instances?cluster=${cluster}`
    // );

    // return response.data.data;

    const response = await new Promise<{
      status: string;
      data: TInstancesHealthOverviewResponse;
    }>((resolve /* , reject */) => {
      setTimeout(() => {
        resolve(instancesHealthOverviewResponse);
        // reject("test error")
      }, 2100);
    });

    return response.data;
  }
);

export const instancesHealthOverviewPageReducer = createReducer(
  initialState,
  (builder) => {
    return builder
      .addCase(getInstancesHealth.pending, (state) => {
        state.instancesHealth = loading();
      })
      .addCase(getInstancesHealth.rejected, (state) => {
        state.instancesHealth = failure("Something went wrong");
      })
      .addCase(getInstancesHealth.fulfilled, (state, action) => {
        state.instancesHealth = success(action.payload);
      });
  }
);

export const instancesHealthOverviewStateSelector = (state: TRootState) =>
  state.instancesHealthOverviewPageReducer;
