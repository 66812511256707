import React from "react";
import { Badge } from "@radix-ui/themes";
import { match } from "ts-pattern";

import { PipelineStatus } from "../app/api/ide/ideService";

interface TPipelineStatusProps extends React.ComponentProps<typeof Badge> {
  status: PipelineStatus;
}

export const PipelinesStatus = (props: TPipelineStatusProps) => {
  const { status, ...rest } = props;

  return match(status)
    .with("scheduled", () => (
      <Badge {...rest} color="gray">
        Scheduled
      </Badge>
    ))
    .with("running", () => (
      <Badge {...rest} color="purple">
        Running
      </Badge>
    ))
    .with("paused", () => (
      <Badge {...rest} color="orange">
        Paused
      </Badge>
    ))
    .with("completed", () => (
      <Badge {...rest} color="green">
        Completed
      </Badge>
    ))
    .with("failed", () => (
      <Badge {...rest} color="red">
        Failed
      </Badge>
    ))
    .with("canceled", () => (
      <Badge {...rest} color="brown">
        Canceled
      </Badge>
    ))
    .otherwise((status) => <Badge {...rest}>{status}</Badge>);
};
