import React, { useMemo } from "react";
import cn from "classnames";
import { Card } from "@radix-ui/themes";
import { orderBy } from "lodash";

import { chatApi } from "./../chatApi";
import { Timestamp } from "../../components/Timestamp/Timestamp";

import styles from "./Convo.module.scss";

export function Convo(props: { hostname: string }) {
  const { hostname } = props;

  const { useGetMessagesQuery } = chatApi;

  const { isSuccess, data } = useGetMessagesQuery(hostname);

  const sortedMessages = useMemo(() => {
    if (!data) return [];

    return orderBy(data, ["timestamp"], ["asc"]);
  }, [data]);

  if (isSuccess) {
    return (
      <div>
        {sortedMessages.map((msg) => {
          return (
            <div
              key={msg.id}
              className={cn({ [styles.alignRight]: msg.source === "client" })}
            >
              <Card
                className={cn(styles.chatMsg, {
                  [styles.fromChat]: msg.source === "chat",
                  [styles.fromClient]: msg.source === "client",
                })}
              >
                {msg.text}
                <div className={styles.timestamp}>
                  <Timestamp value={msg.timestamp} />
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }
}
