import React from "react";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./app/store";
import { AdminUsersPage } from "./AdminUsersPage/AdminUsersPage";
import { ClustersOverviewPage } from "./ClustersOverviewPage/ClustersOverviewPage";
import { Layout } from "./components/Layout/Layout";
import { HealthPage } from "./HealthPage/HealthPage";
import { InstancePage } from "./InstancePage/InstancePage";
import { InstanceSearchPage } from "./InstanceSearchPage/InstanceSearchPage";
import { LoginPage } from "./loginPage/LoginPage";
import { CapacityPartnersPage } from "./CapacityPartnersPage/CapacityPartnersPage";
import { ApiUsersPage } from "./ApiUsersPage/ApiUsersPage";
import { CountryCodesPage } from "./CountryCodesPage/CountryCodesPage";
import { ClusterDetailsPage } from "./ClusterDetailsPage/ClusterDetailsPage";
import { HealthOverviewPage } from "./HealthOverviewPage/HealthOverviewPage";
import { InstancesHealthOverviewPage } from "./InstancesHealthOverviewPage/InstancesHealthOverviewPage";
import { ChatHistoryPage } from "./chat/ChatHistoryPage/ChatHistoryPage";
import { ChatControlPanelPage } from "./chat/ChatControlPanelPage/ChatControlPanelPage";
import { RecentChatsPage } from "./chat/RecentChatsPage/RecentChatsPage";
import { ReportsPage } from "./reports/ReportsPage";
import { ViewReportPageLoader } from "./reports/ViewReportPage/ViewReportPage";

// eslint-disable-next-line react/prop-types
const RouteComponent = ({ Component, ...rest }) => {
  const params = useParams();

  return <Component {...rest} params={params} />;
};

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <InstanceSearchPage /> },
      { path: "/capacity-partners", element: <CapacityPartnersPage /> },
      { path: "/api-users", element: <ApiUsersPage /> },
      { path: "/admin-users", element: <AdminUsersPage /> },
      {
        path: "instance/:instanceName",
        element: <InstancePage />,
      },
      {
        path: "instance/:instanceName/health",
        element: <HealthPage />,
      },
      { path: "/country-codes", element: <CountryCodesPage /> },
      { path: "/clusters", element: <ClustersOverviewPage /> },
      {
        path: "cluster/:clusterId",
        element: <ClusterDetailsPage />,
      },
      {
        path: "/health-overview",
        element: <HealthOverviewPage />,
      },
      {
        path: "/health-overview/instances",
        element: <InstancesHealthOverviewPage />,
      },
      {
        path: "/chat",
        element: <RecentChatsPage />,
      },
      {
        path: "/chat/:hostname",
        element: <ChatHistoryPage />,
      },
      {
        path: "/chat-control-panel",
        element: <ChatControlPanelPage />,
      },
      {
        path: "/reports",
        element: <ReportsPage />,
      },
      {
        path: "/report/:reportId",
        element: <RouteComponent Component={ViewReportPageLoader} />,
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Theme appearance="dark">
          <RouterProvider router={router} />
        </Theme>
      </PersistGate>
    </Provider>
  );
}

export default App;
