import React, { useEffect } from "react";
import { Flex, Table } from "@radix-ui/themes";
import { SRD } from "srd";

import {
  adminUsersPageSelector,
  fetchUsers,
  openAddModal,
  openDeleteModal,
  openEditModal,
} from "../app/adminUsersPageReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Button from "../components/Button/Button";
import { Error } from "../components/Error";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { Timestamp } from "../components/Timestamp/Timestamp";
import * as layout from "../app/layoutReducer";
import { AddEditModal } from "./AddEditModal";

import styles from "./AdminUsersPage.module.scss";

export function AdminUsersPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(adminUsersPageSelector);

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([layout.plainBreadcrumb("Admin users")]));

    dispatch(fetchUsers());
  }, []);

  return (
    <div className={styles.adminUsersPage}>
      <div className={styles.headingActions}>
        <Flex justify="end" grow="1">
          <Button onClick={() => dispatch(openAddModal())}>
            Add admin user
          </Button>
        </Flex>
      </div>
      {SRD.match(
        {
          notAsked: () => <></>,
          loading: () => <PageLoading />,
          failure: (msg) => <Error>{msg}</Error>,
          success: (results) => (
            <div className={styles.content}>
              <Table.Root variant="surface" data-testid="results-table">
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Created at</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {results.map((adminUser) => (
                    <Table.Row key={adminUser.id}>
                      <Table.RowHeaderCell>
                        {adminUser.email}
                      </Table.RowHeaderCell>
                      <Table.Cell>
                        <Timestamp value={adminUser.created_at} />
                      </Table.Cell>
                      <Table.Cell>
                        <Flex gap="2" justify="end">
                          <Button
                            size="1"
                            onClick={() => dispatch(openEditModal(adminUser))}
                            data-testid={`edit-button-${adminUser.id}`}
                          >
                            Edit
                          </Button>
                          <Button
                            size="1"
                            color="red"
                            onClick={() => dispatch(openDeleteModal(adminUser))}
                            data-testid={`delete-button-${adminUser.id}`}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Root>
            </div>
          ),
        },
        state.adminUsers
      )}
      <AddEditModal />
    </div>
  );
}
