import React from "react";

import { TSpinnerProps } from "./types/TSpinnerProps";

import styles from "./Spinner.module.css";

export default function Spinner({ size }: TSpinnerProps) {
  const spinnerBoxSize = `${size || 22}px`;

  return (
    <div
      className={styles.spinner}
      style={{ width: spinnerBoxSize, height: spinnerBoxSize }}
    ></div>
  );
}
