import React, { TextareaHTMLAttributes } from "react";
import { TextArea } from "@radix-ui/themes";
import * as _ from "lodash";

import { instanceNameRegex } from "../../util";

type ElementProps = React.ComponentProps<typeof TextArea> &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

interface Props extends ElementProps {
  onInstanceInput: (instanceIds: string[]) => void;
}

export const InstancesInput: React.FC<Props> = (props) => {
  const { onInstanceInput, ...rest } = props;

  return (
    <TextArea
      className="test-rlpt"
      placeholder="Instances"
      onChange={(e) =>
        onInstanceInput(seperateInstanceIds(e.currentTarget.value))
      }
      {...rest}
      data-testid="instances-input"
    />
  );
};

function seperateInstanceIds(rawString: string): string[] {
  // split into substrings seperated by whitespace or any non alphanumeric character
  const splitRegex = /[^a-zA-Z0-9]+/;

  const items = rawString.split(splitRegex);

  // filter out anything which does not look like an instance name and dedupe
  return _.uniq(items.filter((str) => instanceNameRegex.test(str)));
}
