import React, { useState } from "react";
import { Modal } from "../components/Modal/Modal";
import { Flex, Heading, TextField, Text, Strong } from "@radix-ui/themes";
import { SRD, RD, notAsked } from "srd";

import Button from "../components/Button/Button";
import { Error } from "../components/Error";

type TProps = {
  heading: string;
  message?: string | React.ReactNode;
  safeConfirmText?: string;
  confirmButtonText?: string;
  confirmButtonRed?: boolean;
  request?: RD<string, string>;
  onSubmit: () => void;
  onCancel: () => void;
};

export function ConfirmModal(props: TProps) {
  const [safeConfirmTextInput, setSafeConfirmTextInput] = useState("");

  const buttonProps = {} as any;

  if (props.confirmButtonRed) {
    buttonProps.color = "red";
  }

  let safeConfirm = <></>;

  if (props.safeConfirmText) {
    safeConfirm = (
      <Flex direction="column" gap="1">
        <Text>
          Please type <Strong>{props.safeConfirmText}</Strong> to confirm.
        </Text>
        <TextField.Input
          value={safeConfirmTextInput}
          onChange={(e) => setSafeConfirmTextInput(e.target.value)}
          data-testid="safeConfirmTextInput"
        />
      </Flex>
    );
  }

  const confirmDisabled =
    props.safeConfirmText &&
    props.safeConfirmText.toLowerCase().trim() !==
      safeConfirmTextInput.toLowerCase().trim();

  const pending = props.request && SRD.isLoading(props.request);

  return (
    <Modal open={true} close={props.onCancel}>
      <Flex direction="column" gap="5">
        <Heading>{props.heading}</Heading>
        {props.message}

        {safeConfirm}

        <Flex gap="2">
          <Button
            {...buttonProps}
            disabled={confirmDisabled}
            onClick={props.onSubmit}
            pending={pending}
            data-testid="confirm"
          >
            {props.confirmButtonText ? props.confirmButtonText : "Confirm"}
          </Button>
          <Button variant="outline" onClick={props.onCancel}>
            Cancel
          </Button>
        </Flex>
        {SRD.match(
          {
            notAsked: () => <></>,
            loading: () => <></>,
            failure: (msg) => <Error>{msg}</Error>,
            success: () => <></>,
          },
          props.request || notAsked()
        )}
      </Flex>
    </Modal>
  );
}
