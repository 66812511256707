import React from "react";

import Spinner from "../Spinner/Spinner";

import PageLoaderPositions from "./enums/PageLoaderPositions";

import { TPageLoadingProps } from "./types/TPageLoadingProps";

import styles from "./PageLoading.module.scss";

export function PageLoading({ position, size, label }: TPageLoadingProps) {
  let positionClass = "";
  switch (position) {
    case PageLoaderPositions.CENTER:
      positionClass = label
        ? styles.loaderWithLabelCenter
        : styles.positionCenter;
      break;
    case PageLoaderPositions.RIGHT:
      positionClass = label
        ? styles.loaderWithLabelRight
        : styles.positionRight;
      break;
    case PageLoaderPositions.LEFT:
    default:
      positionClass = label ? styles.loaderWithLabelLeft : styles.positionLeft;
  }

  return (
    <div
      className={`${styles.pending} ${positionClass} ${
        label && styles.loaderWithLabel
      }`}
    >
      <Spinner size={size} />
      {label}
    </div>
  );
}
