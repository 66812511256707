import React from "react";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Box, Flex, Heading, Separator, Text } from "@radix-ui/themes";

import { healthStateSelector } from "../../../app/healthPageReducer";
import { useAppSelector } from "../../../app/hooks";
import healthPageStyles from "../../HealthPage.module.scss";
import { TIdeApp } from "../../types/types";
import { Highlighted } from "../Highlighted/Highlighted";

import styles from "./AppDetails.module.scss";

export const AppDetails: React.FunctionComponent = (): JSX.Element => {
  const { appsByTimestamp, selectedTimestamp, selectedApp } =
    useAppSelector(healthStateSelector);

  let appDetails: TIdeApp | null = null;

  if (appsByTimestamp && selectedTimestamp && selectedApp)
    appDetails = Object.values(appsByTimestamp[selectedTimestamp]).find(
      (appDetails) => appDetails.displayName === selectedApp
    )!;

  const getNoAppSelectedView = (): JSX.Element => (
    <Flex justify="center" align="center" width="100%" height="100%">
      <Text as="p" align="center">
        No App Selected
      </Text>
    </Flex>
  );

  const getDetailView = (): JSX.Element => {
    const { icon, displayName, isInstalled } = appDetails!;

    const extraKeys: Array<string> = Object.keys(appDetails!).filter(
      (key) => key !== "icon" && key !== "displayName" && key !== "isInstalled"
    );

    return (
      <Flex direction="column" width="100%" height="100%">
        <Flex
          direction="row"
          wrap="wrap"
          justify="center"
          align="center"
          gap="2"
        >
          <img
            src={icon}
            alt={`${displayName} icon`}
            className={healthPageStyles.icon}
          />
          <Heading as="h4">{displayName}</Heading>
        </Flex>

        <Separator orientation="horizontal" my="4" size="4" />

        {isInstalled && extraKeys.length ? (
          <Flex
            wrap="wrap"
            gap="4"
            grow="1"
            mb="4"
            className={styles.extraKeysContainer}
          >
            {extraKeys.map((key) => (
              <Box key={key}>
                <Highlighted
                  label={appDetails![key].displayName}
                  value={
                    typeof appDetails![key].value === "boolean"
                      ? getIcon(appDetails![key].value)
                      : appDetails![key].value
                  }
                />
              </Box>
            ))}
          </Flex>
        ) : (
          <Flex grow="1" justify="center" align="center" mb="4">
            <Text as="p">
              {isInstalled ? "No app details found." : "App is not installed."}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const getIcon = (isCheckIcon: boolean): JSX.Element =>
    isCheckIcon ? (
      <CheckIcon color="rgb(0, 196, 159)" width={24} height={24} />
    ) : (
      <Cross2Icon color="salmon" width={24} height={24} />
    );

  return !appDetails ? getNoAppSelectedView() : getDetailView();
};
