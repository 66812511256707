import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success } from "srd";
import {
  TCluster,
  TClusterEdit,
  TClusterListResponse,
} from "../../../server/src/api/ide/ideApi";
import { ideApi } from "../api";

import { TRootState } from "./store";
import { router } from "../App";

type TModal =
  | { modalType: "none" }
  | { modalType: "editCluster" }
  | { modalType: "deleteCluster" };

export type TState = {
  clusterId: number | null;
  cluster: RD<string, TCluster>;
  clustersOverview: RD<string, TClusterListResponse>;
  modal: TModal;
  editClusterRequest: RD<string, string>;
};

const initialState: TState = {
  clusterId: null,
  cluster: notAsked(),
  clustersOverview: notAsked(),
  modal: { modalType: "none" },
  editClusterRequest: notAsked(),
};

export const gotClusterId = createAction<string>(
  "clusterDetailsPage/gotClusterId"
);

export const fetch = createAsyncThunk(
  "clusterDetailsPage/fetch",
  async (args, { getState }) => {
    const state: TState = (getState() as any).clusterDetailsPageReducer;
    if (state.clusterId) {
      const response =
        await ideApi.get_cluster_info_v1_clusters__cluster_id__get({
          params: {
            cluster_id: state.clusterId,
          },
        });

      return response;
    }
  }
);

export const fetchClusters = createAsyncThunk(
  "clusterDetailsPage/fetchClusters",
  async () => {
    const response = await ideApi.list_clusters_v1_clusters_get({
      queries: {
        page_size: 100,
      },
    });

    return response;
  }
);

export const saveCluster = createAsyncThunk<
  any,
  { id: number; cluster: TClusterEdit }
>(
  "clusterDetailsPage/saveCluster",
  async ({ id, cluster }, { dispatch, rejectWithValue }) => {
    return ideApi
      .edit_cluster_v1_clusters__cluster_id__put(cluster, {
        params: { cluster_id: id },
      })
      .then((res) => {
        dispatch(fetch());
        dispatch(closeModal());

        return res;
      })
      .catch((e) => {
        return rejectWithValue(e.response.statusText);
      });
  }
);

export const deleteCluster = createAsyncThunk<any, { id: number }>(
  "clusterDetailsPage/deleteCluster",
  async ({ id }, { dispatch, rejectWithValue }) => {
    return (
      ideApi
        // undefined as first param looks weird, but typescipt insists on it
        .delete_cluster_v1_clusters__cluster_id__delete(undefined, {
          params: { cluster_id: id },
        })
        .then((res) => {
          dispatch(fetch());
          dispatch(closeModal());

          router.navigate(`/clusters`);

          return res;
        })
        .catch((e) => {
          return rejectWithValue(e.response.statusText);
        })
    );
  }
);

export const showEditModal = createAction("clusterDetailsPage/showEditModal");

export const showDeleteModal = createAction(
  "clusterDetailsPage/showDeleteModal"
);

export const closeModal = createAction("clusterDetailsPage/closeModal");

export const clusterDetailsPageReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(gotClusterId, (state, action) => {
        state.clusterId = parseInt(action.payload, 10);
      })
      .addCase(fetch.pending, (state) => {
        state.cluster = loading();
      })
      .addCase(fetch.rejected, (state) => {
        // TODO log to sentry
        state.cluster = failure("Something went wrong");
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.cluster = success(action.payload as TCluster);
      })
      .addCase(fetchClusters.pending, (state) => {
        state.clustersOverview = loading();
      })
      .addCase(fetchClusters.rejected, (state) => {
        // TODO log to sentry
        state.clustersOverview = failure("Something went wrong");
      })
      .addCase(fetchClusters.fulfilled, (state, action) => {
        state.clustersOverview = success(action.payload);
      })
      .addCase(closeModal, (state) => {
        state.modal = { modalType: "none" };
      })
      .addCase(showEditModal, (state) => {
        state.modal = { modalType: "editCluster" };
      })
      .addCase(showDeleteModal, (state) => {
        state.modal = { modalType: "deleteCluster" };
      })
      .addCase(saveCluster.pending, (state) => {
        state.editClusterRequest = loading();
      })
      .addCase(saveCluster.rejected, (state, action) => {
        state.editClusterRequest = failure(action.payload as any);
      })
      .addCase(saveCluster.fulfilled, (state) => {
        state.editClusterRequest = success("");
      });
  }
);

export const clusterDetailsStateSelector = (state: TRootState): TState =>
  state.clusterDetailsPageReducer;
