export const healthOverviewResponse = {
  status: "success",
  data: [
    {
      clusterName: "bxide-prod-ap-south-1",
      notReporting: 10.0,
      brokenIdesPercentage: 42.5,
      totalIdes: 40,
      brokenIdes: 17,
      avgNoLogs: 4.66,
    },
    {
      clusterName: "bxide-prod-apsoutheast-1",
      notReporting: 1.0,
      brokenIdesPercentage: 20,
      totalIdes: 10,
      brokenIdes: 1,
      avgNoLogs: 4.66,
    },
    {
      clusterName: "bxide-prod-eastus",
      notReporting: 13.4,
      brokenIdesPercentage: 0,
      totalIdes: 40,
      brokenIdes: 0,
      avgNoLogs: 0,
    },
    {
      clusterName: "bxide-prod-germanywestcentral",
      notReporting: 10.0,
      brokenIdesPercentage: 17.5,
      totalIdes: 40,
      brokenIdes: 10,
      avgNoLogs: 23.66,
    },
    {
      clusterName: "bxidecp-prod-ap-south-1",
      notReporting: 10.0,
      brokenIdesPercentage: 42.5,
      totalIdes: 40,
      brokenIdes: 17,
      avgNoLogs: 4.66,
    },
    {
      clusterName: "bxidecp-prod-centralindia",
      notReporting: 0.0,
      brokenIdesPercentage: 0.0,
      totalIdes: 40,
      brokenIdes: 0,
      avgNoLogs: 11.87,
    },
  ],
};
