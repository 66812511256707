import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { store } from "./app/store";
import {
  gotFeatureFlags,
  extractDevModeFeatureFlags,
  featureFlagNames,
  extractPublicVarsFeatureFlags,
} from "./app/featureFlagsReducer";

import "./index.css";
import { windowPublicVars } from "./windowPublicVars";

const isDevMode = import.meta.env.MODE === "development";

if (isDevMode) {
  // load feature flags from vite env if in dev mode
  const flags = extractDevModeFeatureFlags(featureFlagNames, import.meta.env);

  store.dispatch(gotFeatureFlags(flags));
} else {
  // load feature flags from public vars
  const flags = extractPublicVarsFeatureFlags(
    featureFlagNames,
    windowPublicVars
  );

  store.dispatch(gotFeatureFlags(flags));
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

// If in dev mode all network requests will
// be mocked if matching handler is found in mocks/handlers.ts
// to disable this in dev mode set VITE_USE_SERVER=true in your shell
if (isDevMode && !import.meta.env.VITE_USE_SERVER) {
  import("./mocks/browser")
    .then(({ worker }) => {
      worker.start();
    })
    .then(() => {
      root.render(<App />);
    });
} else {
  // Render the application in production without the MSW.
  root.render(<App />);
}
