import React, { useState } from "react";
import {
  Checkbox,
  Flex,
  Heading,
  Select,
  TextField,
  Text,
  Callout,
  RadioGroup,
} from "@radix-ui/themes";
import { useForm, Controller } from "react-hook-form";
import { Cross2Icon, InfoCircledIcon } from "@radix-ui/react-icons";
import { SRD, RD } from "srd";
import * as R from "remeda";

import Button from "../components/Button/Button";
import { TextFieldLabel } from "../components/TextFieldLabel/TextFieldLabel";
import styles from "./AddEditClusterForm.module.scss";
import { FormError } from "../components/FormError/FormError";
import { TAddEditClusterForm } from "./types";
import CloudProvider from "../components/CloudProvider";
import { AWS, AZ } from "../consts";
import { Error } from "../components/Error";
import { ClusterListModel } from "../app/api/ide/ideService";

type TProps = {
  clustersList: ClusterListModel[];
  submitText: string;
  heading: string;
  request: RD<string, string>;
  initialValues?: Partial<TAddEditClusterForm>;
  onClose: () => void;
  onSubmit: (data: TAddEditClusterForm) => void;
};

type TState = {
  newRegion: boolean;
  newRegionCode: boolean;
};

export const NEW_REGION = "new_region";
const NEW_REGION_CODE = "new_region_code";

type TFormStatus = {
  showNewRegion: boolean;
  showNewRegionCode: boolean;
  regionCodeDisabled: boolean;
  allowNewRegionCodeCancel: boolean;
  regionSuggestions: string[];
  regionCodeSuggestions: string[];
  existingNames: string[];
};

export function formStatus(
  clusters: ClusterListModel[],
  state: TState,
  selectedRegion: string
): TFormStatus {
  const status: TFormStatus = {
    showNewRegion: false,
    showNewRegionCode: false,
    regionCodeDisabled: true,
    allowNewRegionCodeCancel: true,
    regionSuggestions: [],
    regionCodeSuggestions: [],
    existingNames: [],
  };

  if (state.newRegion) {
    // when adding new region, force a new region code
    status.showNewRegion = true;
    status.showNewRegionCode = true;
    status.allowNewRegionCodeCancel = false;
  } else if (state.newRegionCode) {
    status.showNewRegionCode = true;
  }

  if (selectedRegion) {
    status.regionCodeDisabled = false;
  }

  status.regionSuggestions = R.uniq(clusters.map((item) => item.region));

  status.regionCodeSuggestions = R.uniq(
    clusters
      .filter((cluster) => cluster.region === selectedRegion)
      .map((cluster) => cluster.region_code)
  );

  status.existingNames = R.uniq(
    clusters
      .filter((cluster) => cluster.region === selectedRegion)
      .map((cluster) => cluster.name as string)
  );

  return status;
}

export function AddEditClusterForm(props: TProps) {
  const [state, setState] = useState<TState>({
    newRegion: false,
    newRegionCode: false,
  });

  const { register, control, handleSubmit, formState, watch, setValue } =
    useForm<TAddEditClusterForm>({
      defaultValues: props.initialValues,
    });

  const { errors } = formState;

  const selectedRegion = watch("region");
  const selectedRegionCode = watch("region_code");

  const status = formStatus(props.clustersList, state, selectedRegion);

  const inputWidth = "180px";

  return (
    <Flex direction="column" gap="3" className={styles.wrap}>
      <Heading>{props.heading}</Heading>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Flex direction="column" gap="3">
          <Controller
            name="region"
            control={control}
            rules={{ required: "Region required" }}
            render={({ field }) => {
              let input = (
                <Select.Root
                  value={field.value}
                  onValueChange={(value) => {
                    if (value === NEW_REGION) {
                      setState({
                        ...state,
                        newRegion: true,
                      });

                      // clear previous region when creating a new region
                      setValue("region", "");
                    } else {
                      // exisiting region selected
                      field.onChange(value);
                      // clear region_code incase its invalid due to region change
                      setValue("region_code", "");
                    }
                  }}
                >
                  <Select.Trigger
                    style={{ width: inputWidth }}
                    data-testid="regionSelect"
                  />
                  <Select.Content>
                    <Select.Group>
                      {status.regionSuggestions.map((region) => (
                        <Select.Item key={region} value={region}>
                          {region}
                        </Select.Item>
                      ))}
                    </Select.Group>
                    <Select.Separator />
                    <Select.Group>
                      <Select.Item value={NEW_REGION}>New region</Select.Item>
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              );

              if (status.showNewRegion) {
                input = (
                  <Flex>
                    <TextField.Input
                      placeholder="New region"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      style={{ width: inputWidth }}
                    />
                    <div
                      className={styles.cancelIcon}
                      onClick={() =>
                        setState({
                          ...state,
                          newRegion: false,
                        })
                      }
                    >
                      <Cross2Icon width={20} height={20} />
                    </div>
                  </Flex>
                );
              }

              return (
                <div>
                  <TextFieldLabel>Region</TextFieldLabel>
                  {input}
                  <FormError error={errors.region?.message} />
                </div>
              );
            }}
          />
          <Controller
            name="region_code"
            control={control}
            rules={{ required: "Region code required" }}
            render={({ field }) => {
              let input = (
                <Select.Root
                  value={field.value}
                  onValueChange={(value) => {
                    if (value === NEW_REGION_CODE) {
                      setState({
                        ...state,
                        newRegionCode: true,
                      });

                      setValue("region_code", "");
                    } else {
                      field.onChange(value);
                    }
                  }}
                  disabled={status.regionCodeDisabled}
                >
                  <Select.Trigger
                    style={{ width: inputWidth }}
                    data-testid="regionCodeSelect"
                  />
                  <Select.Content>
                    <Select.Group>
                      {status.regionCodeSuggestions.map((regionCode) => (
                        <Select.Item key={regionCode} value={regionCode}>
                          {regionCode}
                        </Select.Item>
                      ))}
                    </Select.Group>
                    <Select.Separator />
                    <Select.Group>
                      <Select.Item value={NEW_REGION_CODE}>
                        New region code
                      </Select.Item>
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              );

              if (status.showNewRegionCode) {
                input = (
                  <Flex>
                    <TextField.Input
                      placeholder="New region code"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      style={{ width: inputWidth }}
                    />
                    <div
                      className={styles.cancelIcon}
                      onClick={() =>
                        setState({
                          ...state,
                          newRegionCode: false,
                        })
                      }
                    >
                      {status.allowNewRegionCodeCancel && (
                        <Cross2Icon width={20} height={20} />
                      )}
                    </div>
                  </Flex>
                );
              }

              return (
                <div>
                  <TextFieldLabel>Region code</TextFieldLabel>
                  {input}
                  <FormError error={errors.region_code?.message} />
                </div>
              );
            }}
          />

          <Flex direction="column" gap="1">
            <TextFieldLabel>Name</TextFieldLabel>
            <TextField.Root className={styles.input}>
              <TextField.Input
                placeholder="Name"
                {...register("name", { required: "Name required" })}
                disabled={!selectedRegionCode}
                data-testid="name"
              />
            </TextField.Root>
            <FormError error={errors.name?.message} />
            {status.existingNames.length > 0 && (
              <Callout.Root>
                <Callout.Icon>
                  <InfoCircledIcon />
                </Callout.Icon>
                <Callout.Text>
                  Existing names: {status.existingNames.join(", ")}
                </Callout.Text>
              </Callout.Root>
            )}
          </Flex>

          <div>
            <Controller
              name="cloud_provider"
              control={control}
              rules={{ required: "Cloud provider required" }}
              render={({ field }) => (
                <div>
                  <Flex direction="column">
                    <TextFieldLabel>Cloud provider</TextFieldLabel>
                    <RadioGroup.Root onValueChange={field.onChange}>
                      <Flex gap="4">
                        <Text as="label" size="2">
                          <Flex gap="2">
                            <RadioGroup.Item
                              value={AWS}
                              checked={field.value === AWS}
                              data-testid={AWS}
                            />
                            <CloudProvider value={AWS} />
                          </Flex>
                        </Text>
                        <Text as="label" size="2">
                          <Flex gap="2">
                            <RadioGroup.Item
                              value={AZ}
                              checked={field.value === AZ}
                              data-testid={AZ}
                            />
                            <CloudProvider value={AZ} />
                          </Flex>
                        </Text>
                      </Flex>
                    </RadioGroup.Root>
                  </Flex>
                  <FormError error={errors.cloud_provider?.message} />
                </div>
              )}
            />
          </div>

          <Controller
            name="enable_cloudflare_dns"
            control={control}
            render={({ field }) => (
              <label htmlFor="cloudflare" style={{ paddingTop: "8px" }}>
                <Text size="2">
                  <Flex gap="2">
                    <Checkbox
                      id="cloudflare"
                      onCheckedChange={field.onChange}
                      checked={field.value}
                      data-testid="enable_cloudflare_dns"
                    />
                    Enable Cloudflare DNS zones
                  </Flex>
                </Text>
              </label>
            )}
          />

          {SRD.match(
            {
              notAsked: () => <></>,
              loading: () => <></>,
              failure: (msg) => <Error>{msg}</Error>,
              success: () => <></>,
            },
            props.request
          )}
        </Flex>

        <Flex gap="2" className={styles.buttons}>
          <Button
            type="submit"
            pending={SRD.isLoading(props.request)}
            data-testid="submit"
          >
            {props.submitText}
          </Button>

          <Button variant="outline" onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}
