import { groupBy, orderBy } from "lodash";
import { ChatMessage } from "../../../server/src/chat/types";

export type PairedMsgs = {
  hostname: string;
  timestamp: string;
  requestId: string;
  fromClient: ChatMessage;
  fromChat: ChatMessage;
};

// pair messages using request id. filter out any pairs missing either
// client/chat message
export function sortMessages(data): PairedMsgs[] {
  if (!data) return [];

  const groupedByRequestId = groupBy(data, (msg) => msg.request_id);

  const paired = Object.values(groupedByRequestId)
    .map((grouped) => {
      return {
        fromClient: grouped.filter((msg) => msg.source === "client")[0],
        fromChat: grouped.filter((msg) => msg.source === "chat")[0],
      };
    })
    // filter out any with missing client or chat message
    .filter((paired) => paired.fromClient != null && paired.fromChat != null)
    .map(({ fromClient, fromChat }) => {
      return {
        hostname: fromClient.hostname,
        timestamp: fromClient.timestamp,
        requestId: fromClient.request_id,
        fromClient,
        fromChat,
      };
    });

  return orderBy(paired, ["timestamp"], ["desc"]);
}
