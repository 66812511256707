import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { failure, loading, notAsked, RD, success } from "srd";

import { TRootState } from "./store";

import { ideApi } from "../api";
import { TGetCountryCodesResponse } from "../../../server/src/api/ide/ideApi";

type TState = {
  filterQuery: string;
  pageNumber: number;
  pageSize: number;
  countryCodes: RD<string, TGetCountryCodesResponse>;
};

const initialState: TState = {
  filterQuery: "",
  pageNumber: 1,
  pageSize: 50,
  countryCodes: notAsked(),
};

export const gotFilter = createAction<string>("countryCodes/gotFilter");
export const clear = createAction("countryCodes/clear");
export const gotPageNumber = createAction<number>("countryCode/gotPageNumber");

export const filterPaginateCountryCodes = (
  state: TState,
  countryCodesRes: TGetCountryCodesResponse
) => {
  const filterQuery = state.filterQuery.toLowerCase();

  let countryCodes = countryCodesRes.data || [];

  if (filterQuery !== "") {
    countryCodes = countryCodes.filter((row) => {
      return (
        row.country_code?.toLowerCase().includes(filterQuery) ||
        row.region?.toLowerCase().includes(filterQuery) ||
        row.country?.toLowerCase().includes(filterQuery)
      );
    });
  }

  const total = countryCodes.length;
  const start = (state.pageNumber - 1) * state.pageSize;
  const end = state.pageNumber * state.pageSize;

  return { rows: countryCodes.slice(start, end), total: total };
};

export const fetchCountryCodes = createAsyncThunk(
  "fetchCountryCodes",
  async () => {
    const res = await ideApi.get_country_codes_v1_country_codes_get({
      queries: {
        // fetch all in one go so we can filter/paginate using local state
        page: 1,
        page_size: 500,
      },
    });

    return res;
  }
);

export function paginateToPage(pageNumber: number) {
  return (dispatch) => {
    dispatch(gotPageNumber(pageNumber));
  };
}

export const countryCodesPageReducer = createReducer(
  initialState,
  (builder) => {
    return builder
      .addCase(fetchCountryCodes.pending, (state) => {
        state.countryCodes = loading();
      })
      .addCase(fetchCountryCodes.rejected, (state) => {
        state.countryCodes = failure("Something went wrong");
      })
      .addCase(fetchCountryCodes.fulfilled, (state, action) => {
        state.countryCodes = success(action.payload);
      })
      .addCase(clear, (state) => {
        state.filterQuery = "";
        state.pageNumber = initialState.pageNumber;
      })
      .addCase(gotFilter, (state, action) => {
        state.filterQuery = action.payload;
        state.pageNumber = 1;
      })
      .addCase(gotPageNumber, (state, action) => {
        state.pageNumber = action.payload;
      });
  }
);

export const stateSelector = (state: TRootState): TState =>
  state.countryCodesPageReducer;
