interface IPaginationInputs {
  pageNumber: number;
  pageSize: number;
}

export type TPaginationStatus = {
  next: number | null;
  prev: number | null;
  total: number;
  from: number;
  to: number;
  pageNumber: number;
  pageSize: number;
};

// First page = 1
export function paginationStatus(
  state: IPaginationInputs,
  total: number
): TPaginationStatus {
  const pageSize = state.pageSize;
  const pageNumber = state.pageNumber;

  const status: TPaginationStatus = {
    next: null,
    prev: null,
    total: total,
    from: 0,
    to: 0,
    pageNumber,
    pageSize,
  };

  if (pageNumber > 1) {
    status.prev = pageNumber - 1;
  }

  const leftToShow = status.total - pageSize * pageNumber;

  if (leftToShow > 0) {
    status.next = pageNumber + 1;
  }

  status.from = pageSize * (pageNumber - 1) + 1;
  status.to =
    pageSize * pageNumber > status.total ? status.total : pageSize * pageNumber;

  return status;
}

// Page number is 0 based e.g first page = 0, used for tanstack table
export function paginationStatusZeroBased(
  state: IPaginationInputs,
  total: number
): TPaginationStatus {
  const pageSize = state.pageSize;
  const pageNumber = state.pageNumber;

  const status: TPaginationStatus = {
    next: null,
    prev: null,
    total: total,
    from: 0,
    to: 0,
    pageNumber,
    pageSize,
  };

  if (pageNumber > 0) {
    status.prev = pageNumber - 1;
  }

  const leftToShow = status.total - pageSize * (pageNumber + 1);

  if (leftToShow > 0) {
    status.next = pageNumber + 1;
  }

  status.from = pageSize * pageNumber + 1;
  status.to =
    pageSize * (pageNumber + 1) > status.total
      ? status.total
      : pageSize * (pageNumber + 1);

  return status;
}
