import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import cn from "classnames";

import styles from "./Pagination.module.scss";
import { TPaginationStatus } from "../../app/pagination";

export function Pagination(props: {
  status: Pick<TPaginationStatus, "to" | "from" | "total" | "prev" | "next">;
  paginate: (number) => void;
  testIdPrefix?: string;
}) {
  const status = props.status;

  const paginate = (nextPage: number | null) => {
    return () => {
      if (nextPage !== null) {
        props.paginate(nextPage);
      }
    };
  };

  const testIdPrefix = props.testIdPrefix ? `${props.testIdPrefix}-` : "";

  return (
    <div className={styles.pagination}>
      <div className={styles.numbers}>
        <span className={styles.fromTo}>
          {status.from}-{status.to}
        </span>
        &nbsp;of {status.total}
      </div>
      <div className={styles.spacer}>
        <div className={styles.bar}></div>
      </div>
      <div
        className={cn(styles.button, styles.prevButton, {
          [styles.buttonDisabled]: status.prev === null,
        })}
        onClick={paginate(status.prev)}
        data-testid={`${testIdPrefix}paginate-prev`}
      >
        <ChevronLeftIcon />
      </div>
      <div
        className={cn(styles.button, styles.nextButton, {
          [styles.buttonDisabled]: status.next === null,
        })}
        onClick={paginate(status.next)}
        data-testid={`${testIdPrefix}paginate-next`}
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
}
