import axios from "axios";

import { router } from "./App";
import { store } from "./app/store";
import { gotLastUrl } from "./app/loginPageReducer";

export function saveLocationAndForceLogin() {
  const currentPath = window.location.pathname;

  const currentURL =
    currentPath + window.location.search + window.location.hash;

  // Some pages make multiple requests, so it's possible to get more than one auth error at a time.
  // The first auth error will redirect to the login page, so ignore any immediately subsequent errors
  // if we are already on the login page.
  if (currentPath !== "/login") {
    store.dispatch(gotLastUrl(currentURL));
    router.navigate("/login");
  }
}

export function handleAuthError(error) {
  if (error.response.status === 401) {
    saveLocationAndForceLogin();
  }
  return Promise.reject(error);
}

const axiosInstance = axios.create({
  baseURL: "/",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  handleAuthError
);

export default axiosInstance;
