import { createAction, createReducer } from "@reduxjs/toolkit";

import { TimestampFormats } from "../components/Timestamp/Timestamp";
import { TRootState } from "./store";
import { ColumnId } from "../components/InstanceTable/InstanceTable";

type TState = {
  timestampFormat: TimestampFormats;
  instanceSearchCols: ColumnId[];
};

const initialState: TState = {
  timestampFormat: "distance",
  instanceSearchCols: [
    "instance_name",
    "ide_url",
    "ide_direct_url",
    "pipeline",
    "provision_status",
    "created_at",
    "cluster_name",
    "region",
    "platform",
    "api_user",
    "is_deallocated",
    "mobile_emulator_app_ios",
    "mobile_emulator_app_android",
  ],
};

export const setTimestampFormat = createAction<TimestampFormats>(
  "userPreferences/setTimestampFormat"
);

export const toggleInstanceColumn = createAction<ColumnId>(
  "userPreferences/toggleInstanceColumn"
);

export const userPreferencesReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(setTimestampFormat, (state, action) => {
      state.timestampFormat = action.payload;
    })
    .addCase(toggleInstanceColumn, (state, action) => {
      const col = action.payload;

      if (state.instanceSearchCols.includes(col)) {
        state.instanceSearchCols = state.instanceSearchCols.filter(
          (item) => item !== col
        );
      } else {
        state.instanceSearchCols = [...state.instanceSearchCols, col];
      }
    });
});

export const timestampFormatSelector = (state: TRootState): TimestampFormats =>
  (state.userPreferencesReducer as TState).timestampFormat;

export const instanceColsSelector = (state: TRootState): ColumnId[] =>
  (state.userPreferencesReducer as TState).instanceSearchCols;
