import axios from "axios";
import {
  apiProxyActivityPath,
  apiProxyIdePath,
} from "../../server/src/api/consts";

import * as activity from "../../server/src/api/activity/activityApi";
import * as ide from "../../server/src/api/ide/ideApi";
import { handleAuthError } from "./axios";

const axiosActivity = axios.create();

axiosActivity.interceptors.response.use(
  (response) => response,
  handleAuthError
);

export const activityApi = activity.createApiClient(apiProxyActivityPath, {
  axiosInstance: axiosActivity,
});

const axiosIde = axios.create();

axiosIde.interceptors.response.use((response) => response, handleAuthError);

export const ideApi = ide.createApiClient(apiProxyIdePath, {
  axiosInstance: axiosIde,
});
