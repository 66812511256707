import React from "react";
import { Badge } from "@radix-ui/themes";

export const ReportStatus = (props: { value: string }) => {
  const { value } = props;

  let color = "";

  if (value === "completed") color = "green";
  if (value === "processing") color = "orange";
  if (value === "pending") color = "yellow";
  if (value === "failed") color = "red";

  return (
    <Badge color={color as any} radius="large">
      {value}
    </Badge>
  );
};
