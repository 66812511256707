/* eslint-disable react/prop-types */
import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { pink } from "@radix-ui/colors";
import { format, formatISO } from "date-fns";

const CustomXTick: any = ({ x, y, payload }) => {
  const date = new Date(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} fontSize="14" textAnchor="end" fill="#666">
        {format(date, "HH:mm dd MMM'")}
      </text>
    </g>
  );
};

const CustomYTick: any = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={2} fontSize="14" textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

export function TimeChart(props: {
  data: { created_at: string; execution_time: number }[];
  width: number;
  height: number;
}) {
  return (
    <div data-testid="timechat-chart">
      <LineChart
        width={props.width}
        height={props.height}
        margin={{ top: 5, right: 0, left: -15, bottom: 0 }}
        data={props.data}
      >
        <CartesianGrid stroke="none" />
        <Line
          type="monotone"
          dataKey="execution_time"
          stroke={pink.pink10}
          dot={false}
        />
        <CartesianGrid stroke="#ccc" />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              let value = "";

              if (typeof payload[0].value === "number") {
                value = `${payload[0].value.toFixed(0)} seconds`;
              }

              const timestamp = new Date(label);

              return (
                <div>
                  <div>{formatISO(timestamp)}</div>
                  Time: {value}
                </div>
              );
            }
          }}
        />
        <XAxis dataKey="created_at" tick={CustomXTick} />
        <YAxis dataKey="execution_time" tick={CustomYTick} />
      </LineChart>
    </div>
  );
}
