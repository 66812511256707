import React, { useMemo } from "react";
import { useElementSize } from "usehooks-ts";

import { TimeChart } from "./TimeChart";
import { organise } from "./data";
import { Flex, Text } from "@radix-ui/themes";

export const PipelineReportsView = (props: { data: any }) => {
  const data = useMemo(() => {
    return organise(props.data);
  }, [props.data]);

  const [widthRef, dim] = useElementSize();

  return (
    <div ref={widthRef}>
      <Flex direction="column" gap="7">
        {data.pipelines.map((pipeline) => {
          return (
            <Flex key={pipeline} direction="column" gap="4">
              <Flex gap="1">
                <Text weight="bold">{pipeline}</Text>
                <Text>(avg: {data.execution_time_average[pipeline]})</Text>
              </Flex>
              <TimeChart
                data={data.timeseries[pipeline]}
                width={dim.width as number}
                height={200}
              />
            </Flex>
          );
        })}
      </Flex>
    </div>
  );
};
